import * as React from "react"
import {InlinedState} from "../../../Types/InlinedState";
import TextFormRow from "../../../Components/Form/TextFormRow";
import {ApplicationContext} from "../../../ApplicationContext";

interface PaymentDetailsCaptureProps {

    /**
     * Promotion code that has been applied to the order.
     */
    readonly promoCode: InlinedState<string | undefined>

    /**
     * Validation error for the promo code field.
     */
    readonly promoCodeError: string | undefined

    /**
     * Name of the customers bank account.
     */
    readonly bankName: InlinedState<string | undefined>

    /**
     * Validation error for the bank name field.
     */
    readonly bankNameError: string | undefined

    /**
     * Customers names as found on the customers bank account.
     */
    readonly accountHolderName: InlinedState<string | undefined>

    /**
     * Validation error for the account holder name field.
     */
    readonly accountHolderNameError: string | undefined

    /**
     * Sort code of the customers bank account.
     */
    readonly sortCode: InlinedState<string | undefined>

    /**
     * Validation error for the sort code field.
     */
    readonly sortCodeError: string | undefined

    /**
     * Account number of the customers bank account.
     */
    readonly accountNumber: InlinedState<string | undefined>

    /**
     * Validation error for the account number field.
     */
    readonly accountNumberError: string | undefined

    /**
     * Callback function will be called when applying a promotion to the order.
     */
    readonly applyPromotion: () => void

}

/**
 * Function will render the payment details section of the SIM-only plan signup form.
 */
const PaymentDetailsCapture = (props: PaymentDetailsCaptureProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const paymentDetailsConfig = applicationContext.appConfig.signupConfiguration.paymentsConfig

    return (
        <div className="d-flex flex-column gap-2">
            <span className="sp-subtitle sp-text-primary text-start m-0">
                Payment Details
            </span>

            {paymentDetailsConfig.usesPromotions &&
                <div className="d-flex flex-grid-row align-items-center gap-4">
                    <div className="flex-fill">
                        <TextFormRow
                            label="Promotion Code"
                            type="text"
                            value={props.promoCode.value}
                            error={props.promoCodeError}
                            setValue={props.promoCode.setValue}
                        />
                    </div>

                    <button className="sp-button" type="button" onClick={props.applyPromotion}>
                        Apply Promotion
                    </button>
                </div>
            }

            {paymentDetailsConfig.paymentProvider === "lifecycle" && <div>
                <TextFormRow
                    label="Bank Name"
                    type="text"
                    maxLength={30}
                    value={props.bankName.value}
                    error={props.bankNameError}
                    setValue={props.bankName.setValue}
                />
                <TextFormRow
                    label="Account Holder Name"
                    type="text"
                    maxLength={30}
                    value={props.accountHolderName.value}
                    error={props.accountHolderNameError}
                    setValue={props.accountHolderName.setValue}
                />
                <TextFormRow
                    label="Sort Code"
                    type="text"
                    maxLength={6}
                    value={props.sortCode.value}
                    error={props.sortCodeError}
                    setValue={props.sortCode.setValue}
                />
                <TextFormRow
                    label="Account Number"
                    type="text"
                    maxLength={8}
                    value={props.accountNumber.value}
                    error={props.accountNumberError}
                    setValue={props.accountNumber.setValue}
                />
            </div>}

            {paymentDetailsConfig.paymentProvider === "braintree" &&
                <div id="braintree-drop-in-div"/>
            }
        </div>
    )
}

export default PaymentDetailsCapture
