import * as React from "react"
import {ApplicationContext} from "../../ApplicationContext";
import SuperdrugLoginPage from "./SuperdrugLoginPage";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

interface LoginPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the login page which will let the user login to a configured authorization server.
 */
const LoginPage = (props: LoginPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugLoginPage cmsContent={props.cmsContent}/>
    } else {
        return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
    }

}

export default LoginPage
