import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate, useParams} from "react-router-dom";
import {searchCmsContent} from "../../Api/Cms/CmsContentApi";
import {isRequestError} from "../../Model/RequestError";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

interface BlogViewPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render a blog page for the given reference.
 */
const BlogViewPage = (props: BlogViewPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const {reference} = useParams<keyof { readonly reference: string }>()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [blogPostContent, setBlogPostContent] = React.useState<CmsContent>()

    const optBlogPostSimOnlyPlans = optHtmlContent(props.cmsContent, "sp-pane-blog-post-sim-only-plans")

    React.useEffect(() => {
        window.scrollTo(0, 0)

        searchCmsContent(applicationContext.cmsApiDomain, applicationContext.reseller, {
            reference: reference,
            pageNumber: 0
        }).then((maybeContent) => {
            if (isRequestError(maybeContent) || maybeContent.length === 0 || maybeContent[0].htmlContent === null) {
                navigate(SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext))
            } else {
                setBlogPostContent(maybeContent[0])
            }
            setLoading(false)
        })
    }, [])

    if (loading) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {blogPostContent && blogPostContent.htmlContent && renderDynamicContent(blogPostContent.htmlContent)}

                {optBlogPostSimOnlyPlans && renderDynamicContent(optBlogPostSimOnlyPlans)}
            </HeaderFooterWrapper>
        )
    }

}

export default BlogViewPage