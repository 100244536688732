import * as React from "react";
import {InlinedState} from "../../../Types/InlinedState";
import {ApplicationContext} from "../../../ApplicationContext";
import CheckboxFormRow from "../../../Components/Form/CheckboxFormRow";

interface MarketingPreferencesCaptureProps {

    /**
     * The current state of the email marketing flag.
     */
    readonly emailMarketing: InlinedState<boolean>

    /**
     * The current state of the SMS marketing flag.
     */
    readonly smsMarketing: InlinedState<boolean>

    /**
     * The current state of the Post marketing flag.
     */
    readonly postMarketing: InlinedState<boolean>

    /**
     * The current state of the telemarketing flag.
     */
    readonly voiceMarketing: InlinedState<boolean>

    /**
     * The current state of the automated telemarketing flag.
     */
    readonly automatedVoiceMarketing: InlinedState<boolean>

    /**
     * The current state of the online marketing flag.
     */
    readonly onlineMarketing: InlinedState<boolean>

    /**
     * The current state of the in-app marketing flag.
     */
    readonly inAppMarketing: InlinedState<boolean>

    /**
     * The current state of the profiledMarketing marketing flag.
     */
    readonly profiledMarketing: InlinedState<boolean>

    /**
     * The current state of the third-party marketing flag.
     */
    readonly thirdPartyMarketing: InlinedState<boolean>

}

/**
 * Function will render the marketing preferences section of the SIM-only plan signup form.
 */
const MarketingPreferencesCapture = (props: MarketingPreferencesCaptureProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const marketingPrefsConfig = applicationContext.appConfig.signupConfiguration.marketingPrefsConfig

    return (
        <div className="d-flex flex-column gap-2">
            <span className="sp-subtitle sp-text-primary text-start m-0">
                Marketing Preferences
            </span>

            {marketingPrefsConfig.marketingPreferencesDescription &&
                <span className="sp-paragraph sp-text-secondary text-start m-0">
                    {marketingPrefsConfig.marketingPreferencesDescription}
                </span>
            }

            {marketingPrefsConfig.emailMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.emailMarketingText}
                value={props.emailMarketing.value}
                setValue={props.emailMarketing.setValue}
            />}
            {marketingPrefsConfig.smsMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.smsMarketingText}
                value={props.smsMarketing.value}
                setValue={props.smsMarketing.setValue}
            />}
            {marketingPrefsConfig.postMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.postMarketingText}
                value={props.postMarketing.value}
                setValue={props.postMarketing.setValue}
            />}
            {marketingPrefsConfig.voiceMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.voiceMarketingText}
                value={props.voiceMarketing.value}
                setValue={props.voiceMarketing.setValue}
            />}
            {marketingPrefsConfig.automatedVoiceMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.automatedVoiceMarketingText}
                value={props.automatedVoiceMarketing.value}
                setValue={props.automatedVoiceMarketing.setValue}
            />}
            {marketingPrefsConfig.onlineMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.onlineMarketingText}
                value={props.onlineMarketing.value}
                setValue={props.onlineMarketing.setValue}
            />}
            {marketingPrefsConfig.inAppMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.inAppMarketingText}
                value={props.inAppMarketing.value}
                setValue={props.inAppMarketing.setValue}
            />}
            {marketingPrefsConfig.profiledMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.profiledMarketingText}
                value={props.profiledMarketing.value}
                setValue={props.profiledMarketing.setValue}
            />}
            {marketingPrefsConfig.thirdPartyMarketingText && <CheckboxFormRow
                label={marketingPrefsConfig.thirdPartyMarketingText}
                value={props.thirdPartyMarketing.value}
                setValue={props.thirdPartyMarketing.setValue}
            />}
        </div>
    )
}

export default MarketingPreferencesCapture