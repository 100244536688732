import {CmsIframeSchema} from "../../Model/CmsContent/CmsIframeSchema";

interface CmsIframeProps {

    /**
     * Determines how and what will be displayed in the iframe.
     */
    readonly cmsIframeSchema: CmsIframeSchema

}

/**
 * Function will render an iframe with information sourced from the CMS.
 */
const CmsIframe = (props: CmsIframeProps): JSX.Element => <iframe
    src={props.cmsIframeSchema.src}
    height={props.cmsIframeSchema.height ?? undefined}
    width={props.cmsIframeSchema.width ?? undefined}
>

</iframe>

export default CmsIframe