import axios from "axios";
import {Address} from "../../Model/Sales/Address";
import {isRequestError, RequestError} from "../../Model/RequestError";
import {acceptHeader, jsonContentTypeHeader, executeWithErrorResponseHandling} from "../ApiUtilities";
import {Reseller} from "../../Types/Reseller";
import {joinUrl} from "../../Utilities/UriUtilities";

/**
 * Function is responsible for finding addresses with the given postcode.
 */
export const searchPostcode = async (
    domain: string,
    reseller: Reseller,
    postcode: string
): Promise<Address[] | RequestError> => {
    const endpoint = joinUrl(domain, `/${reseller}/address/search`)
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    const response = await executeWithErrorResponseHandling<Address[]>(() =>
        axios.post<Address[]>(endpoint, {postcode: postcode}, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}
