import * as React from "react"
import {revokeAccessToken, revokeRefreshToken} from "../../Api/Hybris/HybrisAuthenticationApi";
import {ApplicationContext} from "../../ApplicationContext";
import {readLocalStorageItem, removeLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {useNavigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

/**
 * Function will log out the authenticated user.
 */
const SueprdrugLogoutPage = (): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    /**
     * Function will revoke the access/refresh tokens in Hybris and remove anything that might be stored in the
     * local storage.
     */
    const revokeTokensAndRedirect = async () => {
        const optAccessToken = readLocalStorageItem(
            "access_token", (a: any): a is string => typeof a === "string")
        if (optAccessToken) {
            await revokeAccessToken(applicationContext.salesPortalApiDomain, {
                accessToken: optAccessToken,
                refreshToken: "",
                expiresAt: 0
            })
        }

        const optRefreshToken = readLocalStorageItem(
            "refresh_token", (a: any): a is string => typeof a === "string")
        if (optRefreshToken) {
            await revokeRefreshToken(applicationContext.salesPortalApiDomain, {
                accessToken: "",
                refreshToken: optRefreshToken,
                expiresAt: 0
            })
        }

        removeLocalStorageItem("access_token")
        removeLocalStorageItem("refresh_token")
        removeLocalStorageItem("expires_at")
        removeLocalStorageItem("id_token")
        removeLocalStorageItem("id_token_expires_at")

        navigate(SALES_PORTAL_ROUTES.Home(applicationContext.urlContext))
    }

    React.useEffect(() => {
        revokeTokensAndRedirect()
    }, [])

    return null
}

export default SueprdrugLogoutPage
