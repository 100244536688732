import * as React from "react"
import dayjs, {Dayjs} from "dayjs";
import DatePicker from "react-datepicker"
import {v4 as uuidv4} from "uuid";

import "react-datepicker/dist/react-datepicker.css"

const KNOWN_YEARS = Array.from({
    length: (new Date(Date.now()).getFullYear() - 1900) + 1
}, (_, i) => i + 1900)

const KNOWN_MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

interface DateFormRowProps {

    /**
     * Label to explain what this form field represents.
     */
    readonly label: string

    /**
     * Current value of the date form field.
     */
    readonly date: Dayjs | undefined

    /**
     * Validation error associated with the date form field.
     */
    readonly error: string | undefined

    /**
     * Callback function to change the state related to this form field.
     */
    readonly setDate: (d: Dayjs | undefined) => void

}

/**
 * Function will render a form row that will capture a datetime.
 */
const DateFormRow = (props: DateFormRowProps): JSX.Element => {

    const id = uuidv4()

    return (
        <div className="d-flex flex-column">
            <label htmlFor={id}>{props.label}</label>

            <DatePicker
                id={id}
                className="form-control form-control-lg"
                dateFormat="dd/MM/yyyy"
                selected={props.date?.toDate()}
                onChange={(date) => props.setDate(dayjs(date))}
                renderCustomHeader={({date, changeYear, changeMonth}) =>
                    <div
                        style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div>
                            <select
                                className="form-select"
                                value={date.getFullYear()}
                                onChange={({target: {value}}) => changeYear(parseInt(value))}
                            >
                                {KNOWN_YEARS.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="flex-fill">
                            <select
                                className="form-select"
                                value={KNOWN_MONTHS[date.getMonth()]}
                                onChange={({target: {value}}) => changeMonth(KNOWN_MONTHS.indexOf(value))}
                            >
                                {KNOWN_MONTHS.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                }
            />

            {props.error && <small className="sp-text-error">
                {props.error}
            </small>}
        </div>
    )

}

export default DateFormRow