import * as React from "react"
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import SuperdrugSimOnlyPlansPage from "./SuperdrugSimOnlyPlansPage";
import StandardSimOnlyPlansPage from "./StandardSimOnlyPlansPage";

interface SimOnlyPlansProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function is responsible for rendering the SIM-only plans page. This page will show the user all the SIM-only plans
 * they can subscribe to.
 */
const SimOnlyPlansPage = (props: SimOnlyPlansProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return (
            <SuperdrugSimOnlyPlansPage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>
        )
    } else {
        return (
            <StandardSimOnlyPlansPage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>
        )
    }

}

export default SimOnlyPlansPage