import * as React from "react"
import {Reseller} from "./Types/Reseller";
import {SalesPortalConfiguration} from "./Model/Configuration/SalesPortalConfiguration";
import {OAuthToken} from "./Model/Authentication/OAuthToken";

/**
 * Interface defines the properties that will be included in the ApplicationContext. Overuse
 * of Reacts Context API reduces component re-usability so only add properties if you
 * absolutely must. Taking a shortcut here may save you 30 minutes now but will cause you hours
 * of suffering in the future!
 */
export interface ApplicationContextProps {

    /**
     * Defines the reseller that is using this instance of the Sales Portal.
     */
    readonly reseller: Reseller

    /**
     * Context that has been applied to each web page url.
     */
    readonly urlContext: string

    /**
     * Base endpoint for the Sales Portal API.
     */
    readonly salesPortalApiDomain: string

    /**
     * Base endpoint for the LCS Content Management System.
     */
    readonly cmsApiDomain: string

    /**
     * The public URL that can be used to access this instance of the Sales Portal Webapp.
     */
    readonly salesPortalWebappPublicUrl: string

    /**
     * The public URL that can be used to access the relevant self care instance associated with this reseller.
     */
    readonly selfcareWebappPublicUrl: string | undefined


    /**
     * The URL that can be used to access the SelfCare API.
     */
    readonly selfcareApiEndpoint: string | undefined


    /**
     * Configuration options used to determine what and how things are displayed in the Sales Portal.
     */
    readonly appConfig: SalesPortalConfiguration

    /**
     * Function will fetch an access token for the authenticated user. If null is returned, the user is no longer
     * authenticated.
     */
    readonly accessToken: () => Promise<string | null>

}

export const ApplicationContext = React.createContext<ApplicationContextProps>({
    reseller: "demo",
    urlContext: "/sales-portal-ui",
    salesPortalApiDomain: "http://localhost:8085",
    cmsApiDomain: "http://localhost:8085",
    salesPortalWebappPublicUrl: "http://localhost:3001/sales-portal-ui",
    selfcareWebappPublicUrl: "http://localhost:8000/self-care",
    selfcareApiEndpoint: "http://localhost:8091/dx",
    appConfig: {
        theme: {
            primaryColour: "",
            secondaryColour: "",
            tertiaryColour: ""
        },
        signupConfiguration: {
            minimumSignupAge: null,
            postcodeCheckerConfig: {
                usesPostcodeChecker: false,
                postcodeCheckerLimit: 2
            },
            marketingPrefsConfig: {
                capturesMarketingPreferences: false,
                defaultMarketingState: false,
                marketingPreferencesDescription: null,
                emailMarketingText: null,
                smsMarketingText: null,
                postMarketingText: null,
                voiceMarketingText: null,
                automatedVoiceMarketingText: null,
                onlineMarketingText: null,
                inAppMarketingText: null,
                profiledMarketingText: null,
                thirdPartyMarketingText: null
            },
            portTriggerConfig: {
                canTriggerPortOnSignup: false,
                triggeringPortsDescription: null
            },
            paymentsConfig: {
                usesPromotions: false,
                paymentProvider: "lifecycle"
            },
            defaultPlan: null,
            staffPromo: null
        },
        pagesConfiguration: {
            blogPageEnabled: false
        },
        authenticationConfiguration: {
            hybrisAuthenticationConfiguration: null
        },
        currencyConfiguration: {
            currencyCode: "GBP",
            decimalPlaces: 2
        }
    },
    accessToken: () => Promise.resolve(null)
})
