import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import NavigationBar from "./NavigationBar";

interface HeaderFooterWrapperProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

    /**
     * When set, this component will look for an alternative navbar. If found, it will be displayed over the standard
     * navbar. When undefined, the primary navbar will be rendered.
     */
    readonly variant?: "primary" | "secondary" | "tertiary"

    /**
     * Standard React prop that allows us to follow the open/close tag syntax will contain the page content.
     */
    readonly children: React.ReactNode

}

/**
 * Higher-order component will render the given content between the navigation bar and footer.
 */
const HeaderFooterWrapper = (props: HeaderFooterWrapperProps): JSX.Element | null => {

    const optFooter = optHtmlContent(props.cmsContent, "sp-footer")

    return (
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <NavigationBar cmsContent={props.cmsContent} variant={props.variant}/>

            {props.children}

            {optFooter && renderDynamicContent(optFooter)}
        </div>
    )
}

export default HeaderFooterWrapper