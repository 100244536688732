import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate, useParams} from "react-router-dom";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import SuperdrugSelectedPlanDetailsCardSimInHand
    from "../../Components/Superdrug/Card/SuperdrugSelectedPlanDetailsCardSimInHand";
import CmsForm from "../../Components/CmsForm/CmsForm";
import {verifyPortDetails} from "../../Api/Sales/SwitchingApi";
import {isNonEmptyString} from "../../Utilities/ValidationUtilities";
import {PATTERN_MOBILE_NUMBER, PATTERN_PAC, PATTERN_STAC} from "../../Constant/ValidationRegex";
import {toggleInputError} from "../../Utilities/CmsFormUtilities";
import {CmsFormSchema} from "../../Model/CmsContent/CmsFormSchema";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";

interface PacStacSelectionCardProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Information on the customers selected plan.
     */
    readonly selectedPlan: SimOnlyPlan

}

/**
 * Function will render the PAC/STAC selection card which will let the customer choose what sort of switching code
 * they want to use.
 */
const PacStacSelectionCard = (props: PacStacSelectionCardProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const [pacSelected, setPacSelected] = React.useState<boolean | null>(null)
    const [stacSelected, setStacSelected] = React.useState<boolean | null>(null)

    const optPacStacSelectionCard = optHtmlContent(props.cmsContent, "sp-card-pac-stac-selection")
    const optPacCaptureCard = optHtmlContent(props.cmsContent, "sp-card-pac-selection")
    const optStacCaptureCard = optHtmlContent(props.cmsContent, "sp-card-stac-selection")
    const optListItemPac = optHtmlContent(props.cmsContent, "sp-list-item-pac")
    const optListItemPacSelected = optHtmlContent(props.cmsContent, "sp-list-item-pac-selected")
    const optListItemStac = optHtmlContent(props.cmsContent, "sp-list-item-stac")
    const optListItemStacSelected = optHtmlContent(props.cmsContent, "sp-list-item-stac-selected")
    const optPacCaptureForm = props.cmsContent.find((content) => content.reference === "form-pac-verification")
    const optStacCaptureForm = props.cmsContent.find((content) => content.reference === "form-stac-verification")

    if (optPacStacSelectionCard && optPacCaptureCard && optStacCaptureCard && optListItemPac && optListItemPacSelected && optListItemStac && optListItemStacSelected && optPacCaptureForm && optStacCaptureForm) {
        const pacCaptureForm = optPacCaptureForm.jsonContent as CmsFormSchema
        const stacCaptureForm = optStacCaptureForm.jsonContent as CmsFormSchema

        /**
         * Function will verify the entered PAC details and then navigate the user to the signup page if successful.
         */
        const validatePac = (formData: FormData) => {
            const msisdn = formData.get("msisdn")
            const msisdnValid = isNonEmptyString(msisdn) && PATTERN_MOBILE_NUMBER.test(msisdn)
            toggleInputError(pacCaptureForm.errorMap, "switching-form-pac", "msisdn", msisdnValid ? undefined : "The provided msisdn was not valid")

            const switchingCode = formData.get("pac")
            const switchingCodeValid = isNonEmptyString(switchingCode) && PATTERN_PAC.test(switchingCode)
            toggleInputError(pacCaptureForm.errorMap, "switching-form-pac", "pac", switchingCodeValid ? undefined : "The provided PAC code was not valid")

            if (msisdnValid && switchingCodeValid) {
                verifyPortDetails(applicationContext.salesPortalApiDomain, {
                    msisdn: msisdn as string,
                    pac: switchingCode as string
                }).then((maybePortVerification) => {
                    if (isRequestError(maybePortVerification)) {
                        maybePortVerification.details.forEach((errorDetail) => {
                            toggleInputError(stacCaptureForm.errorMap, "switching-form-pac", errorDetail.field, errorDetail.message)
                        })
                    } else {
                        navigate(SALES_PORTAL_ROUTES.SimInHand.Purchase(applicationContext.urlContext,
                            props.selectedPlan.packageCode), {state: {
                                msisdn: msisdn,
                                switchingCode: switchingCode
                            }})
                    }
                })
            } else {
                window.scrollTo(0, 0)
            }
        }

        /**
         * Function will verify the entered STAC details and then navigate the user to the signup page if successful.
         */
        const validateStac = (formData: FormData) => {
            const msisdn = formData.get("msisdn")
            const msisdnValid = isNonEmptyString(msisdn) && PATTERN_MOBILE_NUMBER.test(msisdn)
            toggleInputError(stacCaptureForm.errorMap, "switching-form-stac", "msisdn", msisdnValid ? undefined : "The provided msisdn was not valid")

            const switchingCode = formData.get("pac")
            const switchingCodeValid = isNonEmptyString(switchingCode) && PATTERN_STAC.test(switchingCode)
            toggleInputError(stacCaptureForm.errorMap, "switching-form-stac", "pac", switchingCodeValid ? undefined : "The provided STAC code was not valid")

            if (msisdnValid && switchingCodeValid) {
                verifyPortDetails(applicationContext.salesPortalApiDomain, {
                    msisdn: msisdn as string,
                    pac: switchingCode as string
                }).then((maybePortVerification) => {
                    if (isRequestError(maybePortVerification)) {
                        maybePortVerification.details.forEach((errorDetail) => {
                            toggleInputError(stacCaptureForm.errorMap, "switching-form-stac", errorDetail.field, errorDetail.message)
                        })
                    } else {
                        navigate(SALES_PORTAL_ROUTES.SimInHand.Purchase(applicationContext.urlContext,
                                props.selectedPlan.packageCode), {state: {
                                    msisdn: msisdn,
                                    switchingCode: switchingCode
                                }})
                    }
                })
            } else {
                window.scrollTo(0, 0)
            }
        }

        const flipPacSelected = (state: boolean) => {
            if (state) {
                setPacSelected(state)
                setStacSelected(!state)
            } else {
                setPacSelected(!state)
                setStacSelected(state)
            }
        }

        const flipStacSelected = (state: boolean) => {
            if (state) {
                setStacSelected(state)
                setPacSelected(!state)
            } else {
                setStacSelected(!state)
                setPacSelected(state)
            }
        }

        const PacStacSelection = (): JSX.Element => <div className="d-flex flex-column gap-2">
            {!pacSelected && <div style={{cursor: "pointer"}} onClick={() => flipPacSelected(true)}>
                {renderDynamicContent(optListItemPac, [])}
            </div>}

            {pacSelected && <div style={{cursor: "pointer"}} onClick={() => flipPacSelected(false)}>
                {renderDynamicContent(optListItemPacSelected, [])}
            </div>}

            {!stacSelected && <div style={{cursor: "pointer"}} onClick={() => flipStacSelected(true)}>
                {renderDynamicContent(optListItemStac, [])}
            </div>}

            {stacSelected && <div style={{cursor: "pointer"}} onClick={() => flipStacSelected(false)}>
                {renderDynamicContent(optListItemStacSelected, [])}
            </div>}
        </div>

        if (!pacSelected && !stacSelected) {
            return renderDynamicContent(optPacStacSelectionCard, [
                {
                    key: "SIGNUP_LINK",
                    value: SALES_PORTAL_ROUTES.SimInHand.Purchase(applicationContext.urlContext, props.selectedPlan.packageCode)
                },
                {key: "PAC_OR_STAC_SELECTION", value: <PacStacSelection/>}
            ])
        } else if (pacSelected) {
            return renderDynamicContent(optPacCaptureCard, [
                {key: "PAC_OR_STAC_SELECTION", value: <PacStacSelection/>},
                {
                    key: "PAC_CAPTURE_FORM",
                    value: <CmsForm
                        id="switching-form-pac"
                        form={pacCaptureForm}
                        submit={validatePac}
                    />
                },
                {
                    key: "SIGNUP_LINK",
                    value: SALES_PORTAL_ROUTES.SimInHand.Purchase(applicationContext.urlContext, props.selectedPlan.packageCode)
                }
            ])
        } else { // stacSelected === true
            return renderDynamicContent(optStacCaptureCard, [
                {key: "PAC_OR_STAC_SELECTION", value: <PacStacSelection/>},
                {
                    key: "PAC_CAPTURE_FORM",
                    value: <CmsForm
                        id="switching-form-stac"
                        form={stacCaptureForm}
                        submit={validateStac}
                    />
                },
                {
                    key: "SIGNUP_LINK",
                    value: SALES_PORTAL_ROUTES.SimInHand.Purchase(applicationContext.urlContext, props.selectedPlan.packageCode)
                }
            ])
        }
    } else {
        return null
    }
}

interface SuperdrugPortCapturePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific port capture page which will allow a user to enter and verify a
 * switching code.
 */
const SuperdrugPortCapturePage = (props: SuperdrugPortCapturePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const {planCode} = useParams<keyof { readonly planCode: string }>()

    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan
        readonly inStorePlan: SimOnlyPlan
        readonly planPrice: PlanPrice | null
        readonly inStorePlanPrice: PlanPrice | null
    }>()

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const selectedPlanCode = planCode ?? applicationContext.appConfig.signupConfiguration.defaultPlan!!

        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const maybePlan = await fetchSimOnlyPlanByCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, selectedPlanCode)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        let inStorePlan: SimOnlyPlan
        if (applicationContext.appConfig.signupConfiguration.defaultPlan === selectedPlanCode) {
            inStorePlan = maybePlan
        } else {
            const maybeInStorePlan = await fetchSimOnlyPlanByCode(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!)
            if (isRequestError(maybeInStorePlan)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            inStorePlan = maybeInStorePlan
        }

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        let inStorePriceWithPromo: PlanPrice | null
        if (optPromoCode) {
            if (planPriceWithPromo && applicationContext.appConfig.signupConfiguration.defaultPlan === selectedPlanCode) {
                inStorePriceWithPromo = planPriceWithPromo
            } else {
                const maybeInStorePlanPrice = await fetchPriceForPlanWithPromo(
                    applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!, optPromoCode)
                if (isRequestError(maybeInStorePlanPrice)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                    return
                }
                inStorePriceWithPromo = maybeInStorePlanPrice
            }
        } else {
            inStorePriceWithPromo = null
        }

        setComponentData({
            selectedPlan: maybePlan,
            inStorePlan: inStorePlan,
            planPrice: planPriceWithPromo,
            inStorePlanPrice: inStorePriceWithPromo
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [planCode])

    const optGenericCardWithSelectedPlanPage = optHtmlContent(props.cmsContent, "sp-page-generic-card-with-selected-plan")

    if (!optGenericCardWithSelectedPlanPage || !componentData) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="tertiary">
                {renderDynamicContent(optGenericCardWithSelectedPlanPage, [
                    {
                        key: "SELECTED_PLAN_INFO_CARD",
                        value: <SuperdrugSelectedPlanDetailsCardSimInHand
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            paidInStorePlan={componentData.inStorePlan}
                            priceWithPromo={componentData.planPrice}
                            paidInStorePriceWithPromo={componentData.inStorePlanPrice}
                        />
                    },
                    {
                        key: "CARD_CONTENT",
                        value: <PacStacSelectionCard
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                        />
                    }
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugPortCapturePage