import {isArrayOfT, isOptString, isOptType, isString} from "../../Utilities/TypeGuardUtilities";

export interface CmsContent {

    /**
     * Defines a human-friendly, unique reference that this content can be identified by.
     */
    readonly reference: string

    /**
     * List of tags that have been applied to this content.
     */
    readonly tags: string[]

    /**
     * JSON properties that make up this content. When null, this content is HTML content.
     */
    readonly jsonContent: any | null

    /**
     * Raw HTML content that can be rendered in a web page. When null, this is JSON content.
     */
    readonly htmlContent: string | null

}

/**
 * Type-guard function will check if the given instance of any is of type CmsContent.
 */
export const isCmsContent = (a: any): a is CmsContent =>
    isString(a, "reference") &&
    isArrayOfT(a, "tags", (a: any): a is string => typeof a === "string") &&
    isOptType(a, "jsonContent", (a: any): a is object => typeof a === "object") &&
    isOptString(a, "htmlContent")
