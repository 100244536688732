import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import StandardSimInHandCapturePage from "../SimInHandCapturePage/StandardSimInHandCapturePage";
import SuperdrugSimInHandPlanSelectionPage from "./SuperdrugSimInHandPlanSelectionPage";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";

interface SimInHandPlanSelectionPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the SIM-in-hand plan selection page which will let the customer choose a different
 * plan before activating.
 */
const SimInHandPlanSelectionPage = (props: SimInHandPlanSelectionPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugSimInHandPlanSelectionPage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>
    } else {
        return <StandardSimInHandCapturePage cmsContent={props.cmsContent}/>
    }

}

export default SimInHandPlanSelectionPage