import * as React from "react"
import {useParams} from "react-router-dom";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import StandardSimOnlyPlanDetailsPage from "./StandardSimOnlyPlanDetailsPage";
import SuperdrugSimOnlyPlanDetailsPage from "./SuperdrugSimOnlyPlanDetailsPage";

interface SimOnlyPlanDetailsPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function is responsible for rendering the SIM-only plan details page. This page will
 * show additional information on the selected plan and allow a user to move onto the
 * purchase page.
 */
const SimOnlyPlanDetailsPage = (props: SimOnlyPlanDetailsPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    const {planCode} = useParams<keyof { readonly planCode: string }>()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [simOnlyPlan, setSimOnlyPlan] = React.useState<SimOnlyPlan>()
    const [otherSimOnlyPlans, setOtherSimOnlyPlans] = React.useState<SimOnlyPlan[]>([])

    React.useEffect(() => {
        window.scrollTo(0, 0)

        const selectedSimOnlyPlan = props.simOnlyPlans.find((simOnlyPlan) => simOnlyPlan.fauxPackageCode === planCode)
        const otherSimOnlyPlans = props.simOnlyPlans.filter((simOnlyPlan) => simOnlyPlan.fauxPackageCode !== planCode)

        setSimOnlyPlan(selectedSimOnlyPlan)
        setOtherSimOnlyPlans(otherSimOnlyPlans)
        setLoading(false)
    }, [props.simOnlyPlans])

    if (simOnlyPlan === undefined || otherSimOnlyPlans.length === 0) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else if (applicationContext.reseller === "superdrug") {
        return (
            <SuperdrugSimOnlyPlanDetailsPage
                cmsContent={props.cmsContent}
                simOnlyPlan={simOnlyPlan}
                otherSimOnlyPlans={otherSimOnlyPlans}
            />
        )
    } else {
        return (
            <StandardSimOnlyPlanDetailsPage
                cmsContent={props.cmsContent}
                simOnlyPlan={simOnlyPlan}
                otherSimOnlyPlans={otherSimOnlyPlans}
            />
        )
    }

}

export default SimOnlyPlanDetailsPage