import * as React from "react"
import {ApplicationContext} from "../../ApplicationContext";
import SuperdrugUserDetailsUpdatePage from "./SuperdrugUserDetailsUpdatePage";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

interface UserDetailsUpdatePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * When true, the SIM-in-hand journey will be triggered after a successful login.
     */
    readonly simInHand: boolean

}

/**
 * Function will render the user details update page which will let a user view and modify their details.
 */
const UserDetailsUpdatePage = (props: UserDetailsUpdatePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return (
            <SuperdrugUserDetailsUpdatePage
                cmsContent={props.cmsContent}
                simInHand={props.simInHand}
            />
        )
    } else {
        return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
    }

}

export default UserDetailsUpdatePage