import {isNumber, isString} from "../Utilities/TypeGuardUtilities";

export interface RequestError {

    /**
     * HTTP status code related to this error.
     */
    readonly status: number

    /**
     * URL that this error was returned by.
     */
    readonly instance: string

    /**
     * Generic description of what has caused this error.
     */
    readonly title: string

    /**
     * Array of validation errors that have caused this request to fail.
     */
    readonly details: ErrorDetail[]
}

export interface ErrorDetail {

    /**
     * Field name this error is related to.
     */
    readonly field: string

    /**
     * Error that has caused this field to fail.
     */
    readonly message: string

}

/**
 * Type-guard function will assert the given instance of any is likely to be a RequestError. This can't be a strict
 * check due to older versions of the sales portal.
 */
export const isRequestError = (a: any): a is RequestError =>
    isNumber(a, "status") &&
    isString(a, "title")

/**
 * Const represents a request error that has been caused because the request was invalid.
 */
export const ValidationRequestError: RequestError = {
    status: 400,
    instance: "/",
    title: "The request has failed due to unmet validation constraints.",
    details: []
}

/**
 * Const represents a request error that has been caused because the request was unauthorized.
 */
export const UnauthorizedRequestError: RequestError = {
    status: 401,
    instance: "/",
    title: "You must sign in before accessing this resource.",
    details: []
}

/**
 * Const represents a request error that has been caused because the request was forbidden.
 */
export const ForbiddenRequestError: RequestError = {
    status: 403,
    instance: "/",
    title: "You do not have the necessary permissions to access this resource.",
    details: []
}

/**
 * Const represents a request error that has been caused because the requested resource
 * was not found.
 */
export const NotFoundRequestError: RequestError = {
    status: 404,
    instance: "/",
    title: "The requested resource does not exist.",
    details: []
}

/**
 * Const represents a request error that has been caused because the resource
 * the user tried to create already exists.
 */
export const ConflictError: RequestError = {
    status: 409,
    instance: "/",
    title: "That resource already exists",
    details: []
}

/**
 * Const represents a request error that has been caused by an unrecognised error.
 */
export const UnknownRequestError: RequestError = {
    status: 500,
    instance: "/",
    title: "Sorry, an unexpected error has occurred. We are working on a fix!",
    details: []
}
