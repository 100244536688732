/**
 * Function will generate a random alphabetical string of a given length.
 */
export const randomAlphaString = (length: number): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;

    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }

    return result;
}

/**
 * Truncate a string to a desired length, only if it exceeds it
 * @param inputString the string to truncate
 * @param maxLength the length to truncate thr string too
 */
export const truncateString = (inputString: string | null | undefined, maxLength: number): string | null | undefined => {
    if (inputString === null || inputString === undefined) {
        return inputString;
    }

    if (inputString.length > maxLength) {
        return inputString.substring(0, maxLength);
    }
    return inputString;
}