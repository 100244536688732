import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ApplicationContext} from "../../ApplicationContext";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import CmsForm from "../../Components/CmsForm/CmsForm";
import {CmsFormFieldErrorMap, CmsFormSchema} from "../../Model/CmsContent/CmsFormSchema";
import {createBraintreeClientToken} from "../../Api/Sales/BraintreeApi";
import dropin, {cardPaymentMethodPayload, Dropin} from "braintree-web-drop-in";
import SuperdrugOrderSummaryCard from "../../Components/Superdrug/Card/SuperdrugOrderSummaryCard";
import {createOrder} from "../../Api/Sales/OrderApi";
import dayjs from "dayjs";
import {UserDetails} from "../../Model/User/UserDetails";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import LoadingBackdrop from "../../Components/LoadingBackdrop/LoadingBackdrop";
import {saveLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {OrderConfirmation} from "../../Model/Sales/OrderConfirmation";
import {toggleInputError} from "../../Utilities/CmsFormUtilities";
import CmsStaticHtmlContent from "../../Components/CmsStaticHtmlContent/CmsStaticHtmlContent";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";
import {HostedFieldsEvent} from "braintree-web/hosted-fields";
import {truncateString} from "../../Utilities/StringUtilities";

interface SuperdrugSimOnlyPlanPurchasePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific customer signup page. This page will create an order
 * based on information provided by Hybris and the plan selected earlier in the signup journey.
 */
const SuperdrugSimOnlyPlanPurchasePage = (props: SuperdrugSimOnlyPlanPurchasePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const location = useLocation()
    const portCapturePageState = location.state
    const {planCode} = useParams<keyof { readonly planCode: string }>()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan
        readonly selectedPlanPriceWithPromo: PlanPrice | null
        readonly userDetails: UserDetails
    }>()

    const submitOrderCreationRequest = (paymentNonce: string | null,
                                        paymentDeviceData: string | null,
                                        paymentThreeDSecureAuthenticationId: string | null) => {
        setLoading(true)

        let optPromoCode;
        const defaultStaffPromo = componentData!!.userDetails.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null

        if (defaultStaffPromo === null && componentData?.selectedPlanPriceWithPromo !== null){
            optPromoCode = componentData?.selectedPlanPriceWithPromo.promoCode ?? null
        }else {
            optPromoCode = defaultStaffPromo
        }
        const splitPostcodeArray = componentData!!.userDetails.address.postCode!!
            .replace(/\s/g, "")
            .match(/^([A-Za-z]{1,2}\d{1,2}[A-Za-z]?)\s*(\d[A-Za-z]{2})$/);
        splitPostcodeArray!!.shift()

        createOrder(applicationContext.reseller, applicationContext.salesPortalApiDomain, {
            chosenPackageCode: componentData!!.selectedPlan.packageCode,
            packageBoltonCodes: componentData!!.selectedPlan.planBolton ? [componentData!!.selectedPlan.planBolton.code] : [],
            customer: {
                title: "Mr",
                firstName: componentData!!.userDetails.forename,
                lastName: componentData!!.userDetails.surname,
                dateOfBirth: dayjs(componentData!!.userDetails.dateOfBirth).format("YYYY-MM-DD"),
                email: componentData!!.userDetails.email,
                externalCustomerRef: componentData!!.userDetails.hybris!!.customerId
            },
            homeAddress: {
                houseNumber: componentData!!.userDetails.address.streetNumber,
                houseName: componentData!!.userDetails.address.building,
                line1: componentData!!.userDetails.address.line1,
                line2: componentData!!.userDetails.address.line2,
                line3: null,
                city: componentData!!.userDetails.address.town,
                county: null,
                postcode1: splitPostcodeArray!![0],
                postcode2: splitPostcodeArray!![1]
            },
            directDebitDetails: null,
            braintreePaymentNonce: paymentNonce,
            braintreePaymentThreeDSecureAuthenticationId: paymentThreeDSecureAuthenticationId,
            braintreePaymentDeviceData: paymentDeviceData,
            marketingPreferences: {
                emailMarketing: true,
                smsMarketing: true,
                postMarketing: true,
                phoneMarketing: true,
                automatedVoiceMarketing: true,
                onlineMarketing: true,
                inAppMarketing: true,
                profiledMarketing: true,
                thirdPartyMarketing: true
            },
            iccid: null,
            promoCode: optPromoCode,
            loyaltyCard: componentData!!.userDetails.hybris!!.cardNumber,
            portInNumber: portCapturePageState?.msisdn ?? null,
            switchingCode: portCapturePageState?.switchingCode ?? null
        }).then((maybeOrderSummary) => {
            if (isRequestError(maybeOrderSummary)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            } else {
                const orderConfirmation: OrderConfirmation = {
                    packageCode: componentData!!.selectedPlan.packageCode,
                    email: componentData!!.userDetails.email,
                    houseNumber: componentData!!.userDetails.address.streetNumber,
                    building: componentData!!.userDetails.address.building,
                    line1: componentData!!.userDetails.address.line1,
                    line2: componentData!!.userDetails.address.line2,
                    city: componentData!!.userDetails.address.town,
                    postcode: componentData!!.userDetails.address.postCode
                }
                saveLocalStorageItem("order-confirmation", orderConfirmation)

                navigate(SALES_PORTAL_ROUTES.SimOnly.Confirmation(applicationContext.urlContext))
            }
            setLoading(false)
        })
    }

    /**
     * Function will render the payment capture form.
     */
    const PaymentCaptureForm = (): JSX.Element | null => {

        const [braintreeInstance, setBraintreeInstance] = React.useState<Dropin>()
        const [braintreeFormValid, setBraintreeFormValid] = React.useState<boolean>(false)

        /**
         * Function will submit a new order request effectively signing them up to the MVNO.
         */
        const submitOrder = (formData: FormData, errorMap: CmsFormFieldErrorMap[]) => {
            if (braintreeInstance) {
                const termsAndConditions = formData.get("termsAndConditions")
                const termsAndConditionsValid = termsAndConditions !== null && termsAndConditions === "on"
                toggleInputError(errorMap, "braintree-payment-capture", "termsAndConditions", termsAndConditionsValid ? undefined : "You must agree to our terms and conditions")
                toggleInputError(errorMap, "braintree-payment-capture", "termsAndConditions", braintreeFormValid ? undefined : "Some payment card details are missing or invalid")
                if (termsAndConditionsValid && braintreeFormValid) {
                    let price = componentData?.selectedPlanPriceWithPromo?.priceToPay || componentData?.selectedPlan.monthlyPrice;
                    let userDetails = componentData!!.userDetails!;
                    braintreeInstance.requestPaymentMethod({
                        threeDSecure: {
                            amount: price!!.toString(),
                            email: userDetails.email,
                            billingAddress : {
                                givenName: truncateString(userDetails.forename, 50),
                                surname: truncateString(userDetails.surname, 50),
                                streetAddress: truncateString(`${userDetails.address?.streetNumber} ${userDetails.address?.building} ${userDetails.address?.line1}`, 50),
                                extendedAddress: truncateString(`${userDetails.address?.line2}`, 50),
                                locality: userDetails.address?.town,
                                postalCode: userDetails.address?.postCode
                            },
                            challengeRequested: true,
                        }
                    }, (error, payload) => {
                        if (error) {
                            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                        } else {
                            submitOrderCreationRequest(
                                payload.nonce,
                                payload.deviceData || null,
                                (payload as cardPaymentMethodPayload)?.threeDSecureInfo?.threeDSecureAuthenticationId || '')
                        }
                    })
                }
            } else {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            }
        }

        React.useEffect(() => {
            createBraintreeClientToken(applicationContext.salesPortalApiDomain).then((maybeClientToken) => {
                if (isRequestError(maybeClientToken)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                } else {
                    /**
                     * Function will setup and configure the Braintree dropin plugin for this session.
                     */
                    const createBraintreeDropin = () => {
                        const braintreeConfig = {
                            authorization: maybeClientToken.clientToken,
                            container: "#braintree-drop-in-div",
                            threeDSecure: true
                        }
                        dropin.create(braintreeConfig, (error, instance) => {
                            if (error) {
                                handleDropinError(error);
                            } else {
                                setInstanceAndFormValidity(instance);
                            }
                        });

                        function handleDropinError(error: any) {
                            console.info("Error creating Braintree drop-in instance", {
                                message: error.message,
                                recoveryStrategy: "Clearing Braintree DOM element",
                                retryStrategy: "Only-Once"
                            });
                            clearDropinDiv();
                            // Retry creation
                            dropin.create(braintreeConfig, (error, instance) => {
                                if (error) {
                                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext));
                                } else {
                                    setInstanceAndFormValidity(instance);
                                }
                            });
                        }

                        function clearDropinDiv() {
                            const dropInDiv = document.querySelector("#braintree-drop-in-div");
                            if (dropInDiv) {
                                dropInDiv.innerHTML = "";
                                console.info("Braintree DOM element cleared successfully");
                            } else {
                                console.log("Braintree DOM element not found");
                            }
                        }

                        function setInstanceAndFormValidity(instance: any) {
                            instance!.on("card:validityChange", (event: HostedFieldsEvent) => {
                                setBraintreeFormValid(
                                    event.fields.number.isValid &&
                                    event.fields.cvv.isValid &&
                                    event.fields.expirationDate.isValid &&
                                    event.fields.postalCode.isValid
                                );
                            });
                            setBraintreeInstance(instance);
                            hidePlaceHolderAndShowDropin();
                        }

                        function hidePlaceHolderAndShowDropin() {
                            document.getElementById("braintree-payment-capture-placeholder")?.classList.add("d-none");
                            document.getElementById("braintree-drop-in-div")?.classList.remove("d-none");
                        }
                    }

                    if (braintreeInstance) {
                        braintreeInstance.teardown().then(() => createBraintreeDropin())
                    } else {
                        createBraintreeDropin()
                    }
                }
            })
        }, [])

        const optPaymentCaptureCard = optHtmlContent(props.cmsContent, "sp-card-payment-capture")
        const paymentCaptureForm = props.cmsContent.find((content) => content.reference === "form-payment-capture")

        if (optPaymentCaptureCard && paymentCaptureForm) {
            const form = paymentCaptureForm.jsonContent as CmsFormSchema

            return renderDynamicContent(optPaymentCaptureCard, [
                {key: "ACTIVATE_URL", value: SALES_PORTAL_ROUTES.SimInHand.activate(applicationContext.urlContext)},
                {
                    key: "PAYMENT_CAPTURE",
                    value: <CmsForm
                        id="braintree-payment-capture"
                        form={form}
                        submit={(formData) => submitOrder(formData, form.errorMap)}
                        widget={{
                            content: <div>
                                <div
                                    id="braintree-drop-in-div"
                                    className="d-none"
                                    style={{marginTop: "-1rem", marginBottom: "-1rem"}}
                                />

                                <div id="braintree-payment-capture-placeholder">
                                    <CmsStaticHtmlContent
                                        cmsContent={props.cmsContent}
                                        reference="sp-card-braintree-placeholder"
                                    />
                                </div>
                            </div>,
                            position: "ABOVE"
                        }}
                    />
                }
            ])
        } else {
            return null
        }
    }

    /**
     * Function will render a card with a link to the SIM activation page.
     */
    const ActivateSimCard = (): JSX.Element | null => {
        const optSimActivationCard = optHtmlContent(props.cmsContent, "sp-card-actvate-sim")

        if (optSimActivationCard) {
            return renderDynamicContent(optSimActivationCard, [
                {key: "ACTIVATE_URL", value: SALES_PORTAL_ROUTES.SimInHand.activate(applicationContext.urlContext)}
            ])
        } else {
            return null
        }
    }

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const maybePlan = await fetchSimOnlyPlanByCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        setComponentData({
            selectedPlan: maybePlan,
            selectedPlanPriceWithPromo: planPriceWithPromo,
            userDetails: maybeUser
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [])

    const optOrderSummaryPage = optHtmlContent(props.cmsContent, "sp-page-websale-signup")

    const setSelectedPlanPriceWithPromo = (planPrice: PlanPrice | null) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlanPriceWithPromo: planPrice
        }))
    }

    const setSelectedPlan = (simOnlyPlan: SimOnlyPlan) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlan: simOnlyPlan
        }))
    }

    if (!componentData || !optOrderSummaryPage) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="secondary">
                {renderDynamicContent(optOrderSummaryPage, [
                    {
                        key: "ORDER_SUMMARY",
                        value: <SuperdrugOrderSummaryCard
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            selectedPlanPriceWithPromo={componentData.selectedPlanPriceWithPromo}
                            paidInStorePlan={undefined}
                            paidInStorePriceWithPromo={null}
                            allowEdit={true}
                            simInHand={false}
                            setSelectedPlanPriceWithPromo={setSelectedPlanPriceWithPromo}
                            setSelectedPlan={setSelectedPlan}
                            componentData={componentData}
                        />
                    },
                    {key: "PAYMENT_CAPTURE", value: <PaymentCaptureForm/>},
                    {key: "ACTIVATE_SIM_CARD", value: <ActivateSimCard/>}
                ])}

                <LoadingBackdrop isLoading={loading}/>
            </HeaderFooterWrapper>
        )
    }
}

export default SuperdrugSimOnlyPlanPurchasePage
