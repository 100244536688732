import * as React from "react"
import {ApplicationContext} from "../../ApplicationContext";
import SuperdrugPortSelectionPage from "./SuperdrugPortSelectionPage";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

interface PortSelectionPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the port selection page which will ask the user if they want to port onto the network as
 * part of the signup journey.
 */
const PortSelectionPage = (props: PortSelectionPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugPortSelectionPage cmsContent={props.cmsContent}/>
    } else {
        return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
    }

}

export default PortSelectionPage