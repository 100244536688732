import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {SimOnlyPlan, simOnlyPlanDataLimit} from "../../Model/Sales/SimOnlyPlan";
import {KeyValue} from "../../Types/KeyValue";
import {currencyPrettyPrint} from "../../Model/Configuration/CurrencyConfiguration";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate, useParams} from "react-router-dom";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import SuperdrugSelectedPlanDetailsCardSimInHand
    from "../../Components/Superdrug/Card/SuperdrugSelectedPlanDetailsCardSimInHand";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";
import {UserDetails} from "../../Model/User/UserDetails";

interface SimInHandPlanSelectionCardProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

    /**
     * The customers currently selected plan.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Information on the authenticated user.
     */
    readonly userDetails: UserDetails

    readonly percentageDiscount: number

}

/**
 * Function will render the SIM-in-hand plan selection card which will allow the customer to choose a
 * different package before signing up.
 */
const SimInHandPlanSelectionCard = (props: SimInHandPlanSelectionCardProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const [componentData, setComponentData] = React.useState<{
        plan: SimOnlyPlan
        paidInStorePlan: SimOnlyPlan
        price: PlanPrice | null
        paidInStorePrice: PlanPrice | null
    }[]>()

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const simOnlyPlanPrices: {
            plan: SimOnlyPlan
            paidInStorePlan: SimOnlyPlan
            price: PlanPrice | null
            paidInStorePrice: PlanPrice | null
        }[] = []

        const optPromoCode = props.userDetails.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null

        const paidInStorePlan = await fetchSimOnlyPlanByCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!)

        let paidInStorePrice: PlanPrice | null
        if (optPromoCode) {
            const defaultPlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!, optPromoCode)
            paidInStorePrice = isRequestError(defaultPlanPrice) ? null : defaultPlanPrice
        } else {
            paidInStorePrice = null
        }

        if (!isRequestError(paidInStorePlan)) {
            for (let i = 0; i < props.simOnlyPlans.length; i++) {
                if (optPromoCode) {
                    const maybePlanPrice = await fetchPriceForPlanWithPromo(
                        applicationContext.reseller, applicationContext.salesPortalApiDomain, props.simOnlyPlans[i].packageCode, optPromoCode)
                    if (!isRequestError(maybePlanPrice)) {
                        simOnlyPlanPrices.push({
                            plan: props.simOnlyPlans[i],
                            paidInStorePlan: paidInStorePlan,
                            price: maybePlanPrice,
                            paidInStorePrice: paidInStorePrice
                        })
                    }
                } else {
                    simOnlyPlanPrices.push({
                        plan: props.simOnlyPlans[i],
                        paidInStorePlan: paidInStorePlan,
                        price: null,
                        paidInStorePrice: null
                    })
                }
            }
        }

        setComponentData(simOnlyPlanPrices)
    }

    React.useEffect(() => {
        setupComponent()
    }, [props.selectedPlan])

    const optSimInHandPlanSelectionCard = optHtmlContent(props.cmsContent, "sp-card-sim-in-hand-plan-selection")
    const optOtherPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other")
    const optSelectedOtherPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other-selected")
    const optPlanColourMap = props.cmsContent.find((content) => content.reference === "plan-colour-map")

    // Plan with all you need label
    const packageCodeWithAllYouNeedLabel = "SD_Unlimited"
    const optOtherPlanCardAllYouNeed = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other-all-you-need")
    const optSelectedOtherPlanCardAllYouNeed = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other-selected-all-you-need")

    if (componentData && optSimInHandPlanSelectionCard && optOtherPlanCard && optSelectedOtherPlanCard &&
        optPlanColourMap && optOtherPlanCardAllYouNeed && optSelectedOtherPlanCardAllYouNeed) {
        const simOnlyPlanCards = componentData.map((componentData) => {
            const planColour = Object.hasOwn(optPlanColourMap.jsonContent, componentData.plan.fauxPackageCode) ?
                optPlanColourMap.jsonContent[componentData.plan.fauxPackageCode] : undefined
            const discountAmount = ( props.percentageDiscount / 100) * componentData.plan.monthlyPrice;
            const monthlyPrice = componentData.plan.monthlyPrice - discountAmount
            const templateTags: KeyValue<string | React.ReactNode | React.ReactNode[]>[] = [
                {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(componentData.plan)},
                {key: "PLAN_DESCRIPTION", value: componentData.plan.description},
                {
                    key: "PLAN_PRICE",
                    value: currencyPrettyPrint(monthlyPrice, applicationContext.appConfig.currencyConfiguration)
                },
                {key: "PLAN_COLOUR", value: planColour}
            ]

            const isCurrentPlanSelected = componentData.plan.packageCode === props.selectedPlan.packageCode;
            const isAllYouNeedLabel = componentData.plan.packageCode === packageCodeWithAllYouNeedLabel;

            let template;
            if (isCurrentPlanSelected) {
                template = isAllYouNeedLabel ? optSelectedOtherPlanCardAllYouNeed : optSelectedOtherPlanCard;
            } else {
                template = isAllYouNeedLabel ? optOtherPlanCardAllYouNeed : optOtherPlanCard;
            }

            const cardContent = renderDynamicContent(template, templateTags);

            return (
                <div
                    onClick={() => navigate(SALES_PORTAL_ROUTES.SimInHand.PlanSelection(applicationContext.urlContext, componentData.plan.packageCode))}
                    style={{cursor: "pointer"}}
                >
                    {cardContent}
                </div>
            )
        })

        return renderDynamicContent(optSimInHandPlanSelectionCard, [
            {key: "OTHER_PLANS", value: simOnlyPlanCards},
            {
                key: "PORT_URL",
                value: SALES_PORTAL_ROUTES.Port.PortSelection(applicationContext.urlContext, props.selectedPlan.packageCode)
            }
        ])
    } else {
        return null
    }
}

interface SuperdrugSimInHandPlanSelectionPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the Superdrug-specific plan selection page for the SIM-in-hand journey.
 */
const SuperdrugSimInHandPlanSelectionPage = (props: SuperdrugSimInHandPlanSelectionPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const {planCode} = useParams<keyof { readonly planCode: string }>()

    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan
        readonly inStorePlan: SimOnlyPlan
        readonly planPrice: PlanPrice | null
        readonly inStorePlanPrice: PlanPrice | null
        readonly userDetails: UserDetails
    }>()

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const selectedPlanCode = planCode ?? applicationContext.appConfig.signupConfiguration.defaultPlan!!

        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const maybePlan = await fetchSimOnlyPlanByCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, selectedPlanCode)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        let inStorePlan: SimOnlyPlan
        if (applicationContext.appConfig.signupConfiguration.defaultPlan === selectedPlanCode) {
            inStorePlan = maybePlan
        } else {
            const maybeInStorePlan = await fetchSimOnlyPlanByCode(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!)
            if (isRequestError(maybeInStorePlan)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            inStorePlan = maybeInStorePlan
        }

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        let inStorePriceWithPromo: PlanPrice | null
        if (optPromoCode) {
            if (planPriceWithPromo && applicationContext.appConfig.signupConfiguration.defaultPlan === selectedPlanCode) {
                inStorePriceWithPromo = planPriceWithPromo
            } else {
                const maybeInStorePlanPrice = await fetchPriceForPlanWithPromo(
                    applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!, optPromoCode)
                if (isRequestError(maybeInStorePlanPrice)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                    return
                }

                inStorePriceWithPromo = maybeInStorePlanPrice
            }
        } else {
            inStorePriceWithPromo = null
        }

        setComponentData({
            selectedPlan: maybePlan,
            inStorePlan: inStorePlan,
            planPrice: planPriceWithPromo,
            inStorePlanPrice: inStorePriceWithPromo,
            userDetails: maybeUser
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [planCode])

    const optGenericCardWithSelectedPlan = optHtmlContent(props.cmsContent, "sp-page-generic-card-with-selected-plan")

    if (!optGenericCardWithSelectedPlan || !componentData) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="tertiary">
                {renderDynamicContent(optGenericCardWithSelectedPlan, [
                    {
                        key: "SELECTED_PLAN_INFO_CARD",
                        value: <SuperdrugSelectedPlanDetailsCardSimInHand
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            paidInStorePlan={componentData.inStorePlan}
                            priceWithPromo={componentData.planPrice}
                            paidInStorePriceWithPromo={componentData.inStorePlanPrice}
                        />
                    },
                    {
                        key: "CARD_CONTENT",
                        value: <SimInHandPlanSelectionCard
                            cmsContent={props.cmsContent}
                            simOnlyPlans={props.simOnlyPlans}
                            selectedPlan={componentData.selectedPlan}
                            userDetails={componentData.userDetails}
                            percentageDiscount={componentData.planPrice?.promoPercentageDiscount ?? 0}
                        />
                    }
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimInHandPlanSelectionPage