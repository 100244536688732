import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {searchCmsContent} from "../../Api/Cms/CmsContentApi";
import {isRequestError} from "../../Model/RequestError";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {useNavigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

interface BlogListPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the blog list page. This page will show a series of links to articles that have been
 * written by the reseller.
 */
const BlogListPage = (props: BlogListPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const [loading, setLoading] = React.useState<boolean>(true)
    const [blogPostCardContent, setBlogPostCardContent] = React.useState<CmsContent[]>([])
    const [latestBlogPostCardContent, setLatestBlogPostCardContent] = React.useState<CmsContent>()

    const optPageTitle = optHtmlContent(props.cmsContent, "sp-title-blog-page")
    const optBlogPostSimOnlyPlans = optHtmlContent(props.cmsContent, "sp-pane-blog-post-sim-only-plans")

    /**
     * Component will dynamically render the other blog posts pane from the "sp-pane-other-blog-posts" templated content.
     */
    const OtherBlogPostsPane = (): JSX.Element | null => {
        const optOtherBlogPostsPane = optHtmlContent(props.cmsContent, "sp-pane-other-blog-posts")

        if (optOtherBlogPostsPane) {
            const otherBlogPosts = blogPostCardContent
                .filter((content) => {
                    const htmlContentExists = content.htmlContent !== null && content.htmlContent.length > 0
                    const isLatestBlogPost = content.reference === latestBlogPostCardContent?.reference

                    return htmlContentExists && !isLatestBlogPost
                })
                .map((content) => renderDynamicContent(content.htmlContent!!))

            return renderDynamicContent(optOtherBlogPostsPane, [
                {key: "OTHER_BLOG_POSTS", value: otherBlogPosts}
            ])
        } else {
            return null
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)

        searchCmsContent(applicationContext.cmsApiDomain, applicationContext.reseller, {
            tags: ["blog-card"],
            pageNumber: 0
        }).then((blogCardContent) => {
            if (isRequestError(blogCardContent)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            } else {
                setBlogPostCardContent(blogCardContent)

                if (blogCardContent.length > 0) {
                    setLatestBlogPostCardContent(blogCardContent[0])
                }
            }
            setLoading(false)
        })
    }, [])

    if (loading) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {optPageTitle && renderDynamicContent(optPageTitle)}

                <LatestBlogPostPane cmsContent={props.cmsContent} />

                <OtherBlogPostsPane/>

                {optBlogPostSimOnlyPlans && renderDynamicContent(optBlogPostSimOnlyPlans)}
            </HeaderFooterWrapper>
        )
    }
}

/**
 * Component will figure out which is the latest blog post and dynamically render it within the
 * "sp-pane-latest-blog-post" template.
 */
export const LatestBlogPostPane = (props: BlogListPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const [latestBlogPostCardContent, setLatestBlogPostCardContent] = React.useState<CmsContent>()

    React.useEffect(() => {
        window.scrollTo(0, 0)

        searchCmsContent(applicationContext.cmsApiDomain, applicationContext.reseller, {
            tags: ["blog-card"],
            pageNumber: 0
        }).then((blogCardContent) => {
            if (!isRequestError(blogCardContent)) {
                if (blogCardContent.length > 0) {
                    setLatestBlogPostCardContent(blogCardContent[0])
                }
            }
        })
    }, [])

    const recursivelyFindNodeByClassName = (className: string, root: any): any | undefined => {
        const traverseTree = (accum: any[], parentObject: any) => {
            if (Object.hasOwn(parentObject, "props")) {
                if (Object.hasOwn(parentObject["props"], "className") && parentObject["props"]["className"].includes(className)) {
                    accum.push(parentObject["props"])
                } else if (Object.hasOwn(parentObject["props"], "children") && Array.isArray(parentObject["props"]["children"])) {
                    for (let i = 0; i < parentObject["props"]["children"].length; i++) {
                        const node = parentObject["props"]["children"][i]
                        if (typeof node === "object") {
                            traverseTree(accum, node)
                        }
                    }
                } else if (Object.hasOwn(parentObject["props"], "children")) { // children only has one element
                    const node = parentObject["props"]["children"]
                    if (node !== null) {
                        traverseTree(accum, node)
                    }
                }
            }
        }

        let nodes: any[] = []
        traverseTree(nodes, root)
        return nodes.find(Boolean)
    }

    const optLatestBlogPostPane = optHtmlContent(props.cmsContent, "sp-pane-latest-blog-post")

    if (optLatestBlogPostPane && latestBlogPostCardContent && latestBlogPostCardContent.htmlContent) {
        const latestBlogPostContent = renderDynamicContent(latestBlogPostCardContent.htmlContent)

        const optBlogPostCardImage = recursivelyFindNodeByClassName(
            "blog-post-card-image", latestBlogPostContent)
        const optBlogPostImageUrl = "style" in optBlogPostCardImage && "backgroundImage" in optBlogPostCardImage["style"] ?
            optBlogPostCardImage["style"]["backgroundImage"] : undefined
        const optFormattedBlogPostImageUrl = optBlogPostImageUrl ?
            optBlogPostImageUrl.replace("url('", "").replace("')", "") : undefined

        const optBlogLink = recursivelyFindNodeByClassName(
            "sp-card-blog-link", latestBlogPostContent)
        const optBlogHref = "href" in optBlogLink ? optBlogLink["href"] : undefined

        const optBlogTitle = recursivelyFindNodeByClassName(
            "sp-card-blog-link-title", latestBlogPostContent)
        const optBlogTitleText = "children" in optBlogTitle && typeof optBlogTitle["children"] === "string" ?
            optBlogTitle["children"].trim() : undefined

        if (optFormattedBlogPostImageUrl && optBlogHref && optBlogTitleText) {
            return renderDynamicContent(optLatestBlogPostPane, [
                {key: "BLOG_IMAGE_URL", value: optFormattedBlogPostImageUrl},
                {key: "BLOG_PAGE_URL", value: optBlogHref},
                {key: "BLOG_TITLE", value: optBlogTitleText}
            ])
        } else {
            return null
        }
    } else {
        return null
    }
}

export default BlogListPage