import {LocalStorageKey} from "../Types/LocalStorageKey";

// Utility.ts
const DB_NAME = 'lifecycle';
const STORE_NAME = 'cms';

// Open a connection to IndexedDB
function openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, 1);
        request.onupgradeneeded = () => {
            const db = request.result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME);
            }
        };
        request.onerror = () => reject(request.error);
        request.onsuccess = () => resolve(request.result);
    });
}

// Save an item to IndexedDB or localStorage
export async function saveIndexDB(key: LocalStorageKey, value: any): Promise<void> {
    if ('indexedDB' in window) {
        try {
            const db = await openDB();
            const tx = db.transaction(STORE_NAME, 'readwrite');
            tx.objectStore(STORE_NAME).put(value, key);
            tx.commit();
        } catch (error) {
            console.error('Falling back to localStorage', error);
            localStorage.setItem(key, JSON.stringify(value));
        }
    } else {
        localStorage.setItem(key, JSON.stringify(value));
    }
}

// Read an item from IndexedDB or localStorage
export async function readIndexDB<A, >(key: LocalStorageKey, isA: (a: any) => a is A): Promise<any | null> {
    if ('indexedDB' in window) {
        try {
            const db = await openDB();
            const tx = db.transaction(STORE_NAME, 'readonly');
            const store = tx.objectStore(STORE_NAME);
            const value = store.get(key);
            return value && isA(value) ? value : null
        } catch (error) {
            const localValue = localStorage.getItem(key)
            const maybeItemObject = localValue && JSON.parse(localValue)
            return maybeItemObject && isA(maybeItemObject) ? maybeItemObject : null
        }
    } else {
        const localValue = localStorage.getItem(key)
        const maybeItemObject = localValue && JSON.parse(localValue)
        return maybeItemObject && isA(maybeItemObject) ? maybeItemObject : null
    }
}

// Remove an item from IndexedDB or localStorage
export async function removeIndexDB(key: LocalStorageKey): Promise<void> {
    if ('indexedDB' in window) {
        try {
            const db = await openDB();
            const tx = db.transaction(STORE_NAME, 'readwrite');
            tx.objectStore(STORE_NAME).delete(key);
            tx.commit();
        } catch (error) {
            console.error('Falling back to localStorage', error);
            localStorage.removeItem(key);
        }
    } else {
        localStorage.removeItem(key);
    }
}

/**
 * Function will store the given item against the given key in the users local storage.
 */
export const saveLocalStorageItem = <A, >(localStorageKey: LocalStorageKey, item: A): void => {
    if (typeof item === "string") {
        localStorage.setItem(localStorageKey, item)
    } else {
        localStorage.setItem(localStorageKey, JSON.stringify(item))
    }
}

/**
 * Function will remove the given item from the users local storage if it exists.
 */
export const removeLocalStorageItem = (localStorageKey: LocalStorageKey): void =>
    localStorage.removeItem(localStorageKey)

/**
 * Utility function will attempt to read an item from the users local storage with the given local storage key.
 */
export const readLocalStorageItem = <A, >(localStorageKey: LocalStorageKey, isA: (a: any) => a is A): A | null => {
    const maybeItem = localStorage.getItem(localStorageKey)
    try {
        const maybeItemObject = maybeItem ? JSON.parse(maybeItem) : null
        return maybeItemObject && isA(maybeItemObject) ? maybeItemObject : null
    } catch (e) { // Storage item was not JSON
        return isA(maybeItem) ? maybeItem : null
    }
}
