import * as React from "react";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate, useParams} from "react-router-dom";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {
  SimOnlyPlan,
  simOnlyPlanDataLimit,
  simOnlyPlanMonthlyCost,
  simOnlyPlanVoiceLimit,
  simOnlySmsLimit
} from "../../Model/Sales/SimOnlyPlan";
import {downloadPlanAgreementPreview} from "../../Api/SelfCare/PlanAgreement";
import {AgreementPlanPreviewPageProps} from "./AgreementPlanPreviewPage";

/**
 * Function to render the Superdrug-specific plan agreement
 */
const SuperdrugAgreementPlanPreviewPage = (props: AgreementPlanPreviewPageProps): JSX.Element | null => {
  const [componentData, setComponentData] = React.useState<{
    readonly plan: SimOnlyPlan,
    readonly payload: any,
    readonly token: string
  }>()
  const applicationContext = React.useContext(ApplicationContext)
  const selfCareUri = applicationContext.selfcareApiEndpoint!
  const navigate = useNavigate()
  const {planCode} = useParams<keyof { readonly planCode: string }>()
  const setupComponent = async () => {
    const maybeToken = await applicationContext.accessToken()
    if (!maybeToken) {
      navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
      return
    }
    const maybePlan = await fetchSimOnlyPlanByCode(
      applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!)

    if (isRequestError(maybePlan)) {
      navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
      return
    }
    setComponentData({
      plan: maybePlan,
      payload: {
        contractType: props.contractType,
        packageDescription: maybePlan.description,
        dataAllowance: simOnlyPlanDataLimit(maybePlan),
        voiceAllowance: simOnlyPlanVoiceLimit(maybePlan),
        smsAllowance: simOnlySmsLimit(maybePlan),
        packagePrice: simOnlyPlanMonthlyCost(maybePlan),
        promoPrice: simOnlyPlanMonthlyCost(maybePlan)
      },
      token: maybeToken
    })
  }
  React.useEffect(() => {
    setupComponent()
  }, [planCode])
  if (componentData) {
    downloadPlanAgreementPreview(
      selfCareUri,
      componentData.payload,
      componentData?.token,
    )
    .then(value => {
      // close the window
      // console.log("Closing agreement plan preview PDF download window...")
      // setTimeout(() => {
      //   window.close()
      // }, 0)
    })
  }
  return null;
}
export default SuperdrugAgreementPlanPreviewPage
