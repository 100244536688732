import {isRequestError, RequestError} from "../../Model/RequestError";
import {joinUrl} from "../../Utilities/UriUtilities";
import {authenticationHeader, executeWithErrorResponseHandling, jsonContentTypeHeader} from "../ApiUtilities";
import axios from "axios";

/**
 * Function to download the agreement preview PDF document for the plan configured in the payload
 */
export const downloadPlanAgreementPreview = async (
  apiDomain: string,
  payload: any,
  token: string
): Promise<RequestError | void> => {
  const endpoint = joinUrl(apiDomain, `/subscriber/plan/agreement/preview`)
  const headers = {...{"Accept": "application/pdf"},
    ...jsonContentTypeHeader(),
    ...authenticationHeader(token)}
  const response = await executeWithErrorResponseHandling<{}>(() =>
    axios.post(endpoint, payload, {
      headers: headers,
      responseType: 'blob'
    }))
  if (isRequestError(response)) {
    return response
  } else {
    if (response?.data) {
      const data = response.data as Blob;
      const link = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      const fileName = `${payload["packageDescription"]}-plan-information.pdf`;
      a.href = link;
      a.download = fileName;
      a.click();
    }
  }
}