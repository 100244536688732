import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import StandardSimOnlyPlanPurchasePage from "./StandardSimOnlyPlanPurchasePage";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import SuperdrugSimOnlyPlanPurchasePage from "./SuperdrugSimOnlyPlanPurchasePage";

interface SimOnlyPlanPurchasePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the SIM-only plan purchase page. This page will let a customer sign up to the MVNO with
 * details entered by the customer.
 */
const SimOnlyPlanPurchasePage = (props: SimOnlyPlanPurchasePageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugSimOnlyPlanPurchasePage cmsContent={props.cmsContent}/>
    } else {
        return <StandardSimOnlyPlanPurchasePage cmsContent={props.cmsContent} simOnlyPlans={props.simOnlyPlans}/>
    }
}

export default SimOnlyPlanPurchasePage