import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";

/**
 * Function will render a generic not found error page. This will typically be displayed if the CMS-controlled
 * error page cannot be retrieved for whatever reason.
 */
const GenericErrorPage = (): JSX.Element =>
    <div style={{textAlign: "center"}}>
        <h3 style={{fontSize: "2em", marginTop: "2rem"}}>Not Found Error</h3>

        <h1 style={{fontSize: "5em", marginTop: "2rem", marginBottom: "2rem"}}>
            404
        </h1>

        <p style={{fontSize: "1.5em", lineHeight: ".5em"}}>
            The requested resource could not be found.
        </p>
    </div>

interface NotFoundErrorPageProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the not found page which indicates the user has navigated to a page that doesn't exist.
 */
const NotFoundErrorPage = (props: NotFoundErrorPageProps): JSX.Element => {

    const optSimOnlyPlansPane = optHtmlContent(props.cmsContent, "sp-page-not-found")

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (optSimOnlyPlansPane) {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="secondary">
                {renderDynamicContent(optSimOnlyPlansPane, [])}
            </HeaderFooterWrapper>
        )
    } else { // Fallback to generic error page.
        return <GenericErrorPage/>
    }

}

export default NotFoundErrorPage