import {isNumber, isOptString, isString} from "../../Utilities/TypeGuardUtilities";

export interface OrderConfirmation {

    readonly packageCode: string
    readonly email: string
    readonly houseNumber: string | null
    readonly building: string | null
    readonly line1: string
    readonly line2: string | null
    readonly city: string
    readonly postcode: string

}

/**
 * Type-guard function will check if the given instance of any is of type OrderConfirmation.
 */
export const isOrderConfirmation = (a: any): a is OrderConfirmation =>
    isString(a, "packageCode") &&
    isString(a, "email") &&
    isOptString(a, "houseNumber") &&
    isOptString(a, "building") &&
    isString(a, "line1") &&
    isOptString(a, "line2") &&
    isString(a, "city") &&
    isString(a, "postcode")
