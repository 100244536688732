import * as React from "react"
import {useNavigate, useParams} from "react-router-dom";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import CmsStaticHtmlContent from "../../Components/CmsStaticHtmlContent/CmsStaticHtmlContent";
import CmsForm from "../../Components/CmsForm/CmsForm";
import {ApplicationContext} from "../../ApplicationContext";
import {isNonEmptyString} from "../../Utilities/ValidationUtilities";
import {PATTERN_NUMERIC} from "../../Constant/ValidationRegex";
import {toggleInputError} from "../../Utilities/CmsFormUtilities";
import {CmsFormFieldErrorMap, CmsFormSchema} from "../../Model/CmsContent/CmsFormSchema";
import {fetchSimDetails} from "../../Api/Sales/SimApi";
import {isRequestError} from "../../Model/RequestError";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

interface SuperdrugSimInHandCapturePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific SIM-in-hand capture page which will capture the customers SIM data
 * before triggering the SIM-in-hand journey.
 */
const SuperdrugSimInHandCapturePage = (props: SuperdrugSimInHandCapturePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const {planCode} = useParams<keyof { readonly planCode: string }>()

    /**
     * Function will validate the submitted activation code is correct.
     */
    const validateActivationCode = (formData: FormData, errorMap: CmsFormFieldErrorMap[]) => {
        const activationCode = formData.get("iccid")
        const activationCodeValid = isNonEmptyString(activationCode) && PATTERN_NUMERIC.test(activationCode) && activationCode.length === 12
        toggleInputError(errorMap, "sim-in-hand-capture", "iccid", activationCodeValid ? undefined : "The provided activation code was not valid")

        if (activationCodeValid) {
            fetchSimDetails(applicationContext.salesPortalApiDomain, applicationContext.reseller, {
                iccid: activationCode as string
            }).then((maybeVerification) => {
                if (isRequestError(maybeVerification)) {
                    maybeVerification.details.forEach((errorDetail) => {
                        toggleInputError(errorMap, "sim-in-hand-capture", errorDetail.field, errorDetail.message)
                    })
                } else if (!maybeVerification.usable) {
                    toggleInputError(errorMap, "sim-in-hand-capture", "iccid", "The provided activation code was not valid")
                } else {
                    const baseEndpoint = SALES_PORTAL_ROUTES.SimInHand.PlanSelection(
                        applicationContext.urlContext, applicationContext.appConfig.signupConfiguration.defaultPlan!!)
                    navigate(`${baseEndpoint}?iccid=${activationCode}`)
                }
            })
        } else {
            window.scrollTo(0, 0)
        }
    }

    /**
     * Function will render a form that will capture an activation code (ICCID) from the customers SIM.
     */
    const SimInHandCaptureForm = (): JSX.Element | null => {
        const simInHandCaptureCard = optHtmlContent(props.cmsContent, "sp-card-activation-code-capture")
        const simInHandCaptureForm = props.cmsContent.find((content) => content.reference === "form-sim-in-hand-capture")
        if (simInHandCaptureCard && simInHandCaptureForm) {
            const formDetails = simInHandCaptureForm.jsonContent as CmsFormSchema

            return renderDynamicContent(simInHandCaptureCard, [
                {
                    key: "SIM_IN_HAND_CAPTURE_FORM",
                    value: <CmsForm
                        id="sim-in-hand-capture"
                        form={formDetails}
                        submit={(formData) => validateActivationCode(formData, formDetails.errorMap)}
                    />
                }
            ])
        } else {
            return null
        }
    }

    const optOrderConfirmationPage = optHtmlContent(props.cmsContent, "sp-page-sim-activation-capture")
    const selectedPlanCode = planCode ?? applicationContext.appConfig.signupConfiguration.defaultPlan!!
    const optBuyASimPage = optHtmlContent(props.cmsContent, "sp-card-need-an-activation-code")

    if (!optOrderConfirmationPage) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="tertiary">
                {renderDynamicContent(optOrderConfirmationPage, [
                    {
                        key: "FANCY_AN_UPGRADE_CARD",
                        value: <CmsStaticHtmlContent
                            cmsContent={props.cmsContent}
                            reference="sp-card-fancy-an-upgrade"
                        />
                    },
                    {key: "ACTIVATION_CODE_CAPTURE_FORM", value: <SimInHandCaptureForm/>},
                    {
                        key: "BEFORE_YOU_ACTIVATE_CARD",
                        value: <CmsStaticHtmlContent
                            cmsContent={props.cmsContent}
                            reference="sp-card-before-you-activate"
                        />
                    },
                    {
                        key: "BUY_A_SIM_CARD",
                        value: optBuyASimPage ? renderDynamicContent(optBuyASimPage, [
                            {key: "PLAN_CODE", value: selectedPlanCode}
                        ]) : null
                    }
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimInHandCapturePage
