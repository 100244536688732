import axios from "axios"
import {isRequestError, RequestError} from "../../Model/RequestError";
import {PortVerificationResult, VerifyPort} from "../../Model/Sales/VerifyPort";
import {joinUrl} from "../../Utilities/UriUtilities";
import {acceptHeader, executeWithErrorResponseHandling, jsonContentTypeHeader} from "../ApiUtilities";

/**
 * Function will verify that the given switching details are valid.
 */
export const verifyPortDetails = async (
    apiDomain: string,
    verifyPort: VerifyPort
): Promise<RequestError | PortVerificationResult> => {
    const endpoint = joinUrl(apiDomain, `/switching/verify-port`)
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    const response = await executeWithErrorResponseHandling<PortVerificationResult>(() =>
        axios.post(endpoint, verifyPort, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}
