import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {SimOnlyPlan, simOnlyPlanDataLimit} from "../../Model/Sales/SimOnlyPlan";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate, useParams} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import SuperdrugSelectedPlanDetailsCard from "../../Components/Superdrug/Card/SuperdrugSelectedPlanDetailsCard";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import {KeyValue} from "../../Types/KeyValue";
import CmsStaticHtmlContent from "../../Components/CmsStaticHtmlContent/CmsStaticHtmlContent";
import {UserDetails} from "../../Model/User/UserDetails";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";

interface SelectableOtherPlansProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

    /**
     * Information on the customers selected plan.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Optional promotion to be applied to the price that is displayed.
     */
    readonly promoCode: string | null

}

/**
 * Function will render the other plans pane and will let the customer select a different plan if they want to.
 */
const SelectableOtherPlans = (props: SelectableOtherPlansProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const [componentData, setComponentData] = React.useState<{
        plan: SimOnlyPlan
        price: PlanPrice | null
    }[]>()

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const simOnlyPlanPrices: { plan: SimOnlyPlan; price: PlanPrice | null }[] = []

        for (let i = 0; i < props.simOnlyPlans.length; i++) {
            if (props.promoCode) {
                const maybePlanPrice = await fetchPriceForPlanWithPromo(
                    applicationContext.reseller,
                    applicationContext.salesPortalApiDomain,
                    props.simOnlyPlans[i].packageCode,
                    props.promoCode
                )

                if (!isRequestError(maybePlanPrice)) {
                    simOnlyPlanPrices.push({plan: props.simOnlyPlans[i], price: maybePlanPrice})
                }
            } else {
                simOnlyPlanPrices.push({plan: props.simOnlyPlans[i], price: null})
            }
        }

        setComponentData(simOnlyPlanPrices)
    }

    React.useEffect(() => {
        setupComponent()
    }, [])

    const optOtherPlansPane = optHtmlContent(props.cmsContent, "sp-pane-sim-only-plans-other-horizontal")
    const optOtherPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other")
    const optSelectedOtherPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-other-selected")
    const optPlanColourMap = props.cmsContent.find((content) => content.reference === "plan-colour-map")

    if (componentData && optOtherPlansPane && optOtherPlanCard && optSelectedOtherPlanCard && optPlanColourMap) {
        const simOnlyPlanCards = componentData!!.map((simPriceMapping) => {
            const planColour = Object.hasOwn(optPlanColourMap.jsonContent, simPriceMapping.plan.fauxPackageCode) ?
                optPlanColourMap.jsonContent[simPriceMapping.plan.fauxPackageCode] : undefined

            let planPrice
            if (simPriceMapping.price) {
                planPrice = `£${simPriceMapping.price.priceToPay.toFixed(2)}`
            } else {
                planPrice = `£${simPriceMapping.plan.monthlyPrice.toFixed(2)}`
            }

            const templateTags: KeyValue<string | React.ReactNode | React.ReactNode[]>[] = [
                {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simPriceMapping.plan)},
                {key: "PLAN_DESCRIPTION", value: simPriceMapping.plan.description},
                // Hacky way to show the price without decimal when there is no decimal.
                {key: "PLAN_PRICE", value: planPrice.replace(".00", "")},
                {key: "PLAN_COLOUR", value: planColour}
            ]

            let cardContent
            if (simPriceMapping.plan.packageCode === props.selectedPlan.packageCode) {
                cardContent = renderDynamicContent(optSelectedOtherPlanCard, templateTags)
            } else {
                cardContent = renderDynamicContent(optOtherPlanCard, templateTags)
            }

            return (
                <div
                    onClick={() => navigate(SALES_PORTAL_ROUTES.SimOnly.Alternative(applicationContext.urlContext, simPriceMapping.plan.packageCode))}
                    style={{cursor: "pointer"}}
                >
                    {cardContent}
                </div>
            )
        })

        return renderDynamicContent(optOtherPlansPane, [
            {key: "OTHER_PLANS", value: simOnlyPlanCards},
            {
                key: "SIGNUP_LINK",
                value: SALES_PORTAL_ROUTES.SimOnly.Purchase(applicationContext.urlContext, props.selectedPlan.fauxPackageCode)
            }
        ])
    } else {
        return null
    }
}

interface SuperdrugSimOnlyOtherPlansSelectionPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the Superdrug-specific other plan selection page. This page will allow a customer to select a
 * different plan to the one that has already been selected before proceeding to the signup page.
 */
const SuperdrugSimOnlyOtherPlansSelectionPage = (
    props: SuperdrugSimOnlyOtherPlansSelectionPageProps
): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const {planCode} = useParams<keyof { readonly planCode: string }>()

    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan
        readonly userDetails: UserDetails
        readonly planPrice: PlanPrice | null
    }>()

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const accessToken = await applicationContext.accessToken()
        if (!accessToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUserDetails = await fetchHybrisUser(applicationContext.salesPortalApiDomain, accessToken)
        if (isRequestError(maybeUserDetails)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const maybePlan = await fetchSimOnlyPlanByCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const optPromoCode = maybeUserDetails.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        setComponentData({
            selectedPlan: maybePlan,
            userDetails: maybeUserDetails,
            planPrice: planPriceWithPromo
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [planCode])

    const optOtherSimOnlyPlansPage = optHtmlContent(props.cmsContent, "sp-page-other-sim-only-plans")

    if (!optOtherSimOnlyPlansPage || !componentData) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        const optPromoCode = componentData.userDetails?.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null

        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="secondary">
                {renderDynamicContent(optOtherSimOnlyPlansPage, [
                    {
                        key: "SELECTED_PLAN_INFO_CARD",
                        value: <SuperdrugSelectedPlanDetailsCard
                            cmsContent={props.cmsContent}
                            plan={componentData.selectedPlan}
                            priceWithPromo={componentData.planPrice}
                        />
                    },
                    {
                        key: "HAVE_YOU_SEEN_OUR_PLANS_CARD",
                        value: <CmsStaticHtmlContent
                            cmsContent={props.cmsContent}
                            reference="sp-card-other-sim-only-plans"
                        />
                    },
                    {
                        key: "OTHER_PLANS",
                        value: <SelectableOtherPlans
                            cmsContent={props.cmsContent}
                            simOnlyPlans={props.simOnlyPlans}
                            selectedPlan={componentData.selectedPlan}
                            promoCode={optPromoCode}
                        />
                    }
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimOnlyOtherPlansSelectionPage