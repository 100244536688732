import axios from "axios"
import {Promotion} from "../../Model/Sales/Promotion";
import {isRequestError, RequestError} from "../../Model/RequestError";
import {acceptHeader, executeWithErrorResponseHandling} from "../ApiUtilities";
import {joinUrl} from "../../Utilities/UriUtilities";
import {Reseller} from "../../Types/Reseller";
import {ContentType} from "../../Model/Sales/PromotionCardContent";

/**
 * Function is responsible for fetching a single valid promotion
 * with the given promotion code.
 */

export const fetchPromo = async (
    reseller: Reseller,
    domain: string,
    promoCode: string
): Promise<Promotion | RequestError> => {
    const endpoint = joinUrl(domain, `/${reseller}/promo/${promoCode}/view`)
    const headers = acceptHeader()

    const response = await executeWithErrorResponseHandling<Promotion>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}

export const fetchAvailablePromotionCardContent = async (
    domain: string,
    reseller: Reseller,
    contentType: ContentType,
): Promise<any> => {
    const endPoint = joinUrl(domain, `/${reseller}/promo/cardContent/${contentType}`)
    const headers = acceptHeader()

    const response = await executeWithErrorResponseHandling<any>(() =>
        axios.get(endPoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}