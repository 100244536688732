import axios from "axios"
import {isRequestError, RequestError} from "../../Model/RequestError";
import {isSimDetails, SimDetails} from "../../Model/Sim/SimDetails";
import {acceptHeader, executeWithErrorResponseHandling, jsonContentTypeHeader} from "../ApiUtilities";
import {joinUrl} from "../../Utilities/UriUtilities";
import {SimDetailsSearch} from "../../Model/Sim/SimDetailsSearch";
import {Reseller} from "../../Types/Reseller";
import {readSessionItem, saveSessionItem} from "../../Utilities/SessionUtilities";

/**
 * Function will attempt to find a SIM that matches the given search criteria.
 *
 * @param domain Base endpoint for the sales portal backend.
 * @param reseller Currently configured reseller for this instance of the Sales Portal.
 * @param simSearch Information on the SIM we are trying to find.
 */
export const fetchSimDetails = async (
    domain: string,
    reseller: Reseller,
    simSearch: SimDetailsSearch
): Promise<SimDetails | RequestError> => {
    const endpoint = joinUrl(domain, `/${reseller}/sim/verify`)
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    const response = await executeWithErrorResponseHandling<SimDetails>(() =>
        axios.post(endpoint, simSearch, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        saveSessionItem("sim-details", response.data)
        return response.data
    }
}
