/**
 * Validation function will assert the given optional FormDataEntryValue is a non-empty string.
 */
export const isNonEmptyString = (formValue: FormDataEntryValue | null): formValue is string =>
    formValue !== null && typeof formValue === "string" && formValue.trim().length > 0

/**
 * Checks if the given form value does not contain any special characters.
 *
 * @param {FormDataEntryValue|null} formValue - The form value to check.
 * @returns {boolean} - True if the form value does not contain any special characters, false otherwise.
 */
export const doesNotContainSpecialCharacters = (formValue: FormDataEntryValue | null): formValue is string =>
    formValue !== null && typeof formValue === "string" && /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/.test(formValue.trim())


/**
 * Checks if the given form value is a string with a length less than or equal to the specified maximum length.
 *
 * @param {FormDataEntryValue | null} formValue - The form value to check.
 * @param {number} maxLength - The maximum length allowed for the form value.
 * @return {boolean} Whether the form value is a string and its length is less than or equal to maxLength.
 */
export const isMaxLength = (formValue: FormDataEntryValue | null, maxLength: number): formValue is string =>
    formValue !== null && typeof formValue === "string" && formValue.trim().length <= maxLength