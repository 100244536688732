import * as React from "react"
import {useNavigate, useSearchParams} from "react-router-dom";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {CmsFormFieldErrorMap, CmsFormSchema} from "../../Model/CmsContent/CmsFormSchema";
import CmsForm from "../../Components/CmsForm/CmsForm";
import {ApplicationContext} from "../../ApplicationContext";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import {UserDetails} from "../../Model/User/UserDetails";
import {fetchHybrisUser, updateHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {Address} from "../../Model/Sales/Address";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {removeLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {removeSessionItem} from "../../Utilities/SessionUtilities";
import {setInputValue} from "../../Utilities/FormUtilities";
import {doesNotContainSpecialCharacters, isMaxLength, isNonEmptyString} from "../../Utilities/ValidationUtilities";
import {applyInputError, toggleInputError} from "../../Utilities/CmsFormUtilities";
import {PATTERN_ALPHA_NUMERIC_W_SPACE, PATTERN_POSTCODE} from "../../Constant/ValidationRegex";
import {searchPostcode} from "../../Api/Sales/PostcodeSearchApi";
import SuperdrugSelectedPlanDetailsCard from "../../Components/Superdrug/Card/SuperdrugSelectedPlanDetailsCard";
import dayjs from "dayjs";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";

interface AddressFinderFormProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of addresses that the customer can pick.
     */
    readonly foundAddresses: Address[]

    /**
     * Callback function will be called when the user selects an address.
     */
    readonly setChosenAddressIndex: (index: number) => void

    /**
     * Callback function will setup the address picker form with details sourced from Hybris
     */
    readonly setupAddressPickerForm: () => void

    /**
     * Callback function will submit the address finder form.
     */
    readonly submitForm: (formData: FormData, errorMap: CmsFormFieldErrorMap[]) => void

}

/**
 * Function will render the address picker form which will let the user find a delivery address by their post code.
 */
const AddressFinderForm = (props: AddressFinderFormProps): JSX.Element | null => {

    React.useEffect(() => {
        props.setupAddressPickerForm()

        if (props.foundAddresses.length > 0) {
            const optFormFieldWrapperElement =
                document.getElementById("hybris-user-update-form-date-picker:postcode")?.parentNode?.parentNode
            const optAddressDropdown =
                document.getElementById("hybris-user-update-form-date-picker:addresses")
            if (optFormFieldWrapperElement && !optAddressDropdown) {
                const formFieldWrapper = document.createElement("div")
                formFieldWrapper.classList.add("sp-form-field-wrapper")

                const addressDropdown = document.createElement("select")
                addressDropdown.id = "hybris-user-update-form-date-picker:addresses"

                props.foundAddresses.forEach((address, index) => {
                    const option = document.createElement("option")
                    option.value = index.toString()
                    option.textContent = address.addressSummary
                    option.onclick = () => {
                        props.setChosenAddressIndex(index)
                    }
                    addressDropdown.appendChild(option)
                })

                formFieldWrapper.appendChild(addressDropdown)

                optFormFieldWrapperElement.insertBefore(formFieldWrapper, document.getElementById("button-manual-address"))
            }
        }
    }, [props.foundAddresses])

    const optSelectedPlanPane = optHtmlContent(props.cmsContent, "sp-pane-selected-plan")
    const addressPickerForm = props.cmsContent.find((content) => content.reference === "form-hybris-user-update-date-picker")

    if (optSelectedPlanPane && addressPickerForm) {
        const form = addressPickerForm.jsonContent as CmsFormSchema

        return renderDynamicContent(optSelectedPlanPane, [
            {key: "PLAN_DETAILS_CARD", value: ""},
            {
                key: "CONTENT_CARD",
                value: <CmsForm
                    id="hybris-user-update-form-date-picker"
                    form={form}
                    submit={(formData) => props.submitForm(formData, form.errorMap)}
                />
            }
        ])
    } else {
        return null
    }
}

interface WebSaleUserUpdateFormProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Information on the customers selected plan.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Details on the authenticated user sources from Hybris.
     */
    readonly userDetails: UserDetails

    /**
     * Information on how much the selected plan costs.
     */
    readonly priceWithPromo: PlanPrice | null

    /**
     * Callback function will setup the user update form with details source from Hybris
     */
    readonly setupHybrisForm: (hybrisUserDetails: UserDetails) => void

    /**
     * Callback function will submit the user update form.
     */
    readonly submitForm: (formData: FormData, errorMap: CmsFormFieldErrorMap[]) => void

}

/**
 * Function will render the dynamically constructed hybris user update form for the web sale journey.
 */
const WebSaleUserUpdateForm = (props: WebSaleUserUpdateFormProps): JSX.Element | null => {

    React.useEffect(() => {
        props.setupHybrisForm(props.userDetails)
    }, [])

    const optSelectedPlanPane = optHtmlContent(props.cmsContent, "sp-pane-selected-plan")
    const hybrisUserUpdateForm = props.cmsContent.find((content) => content.reference === "form-hybris-user-update")

    if (optSelectedPlanPane && hybrisUserUpdateForm) {
        const form = hybrisUserUpdateForm.jsonContent as CmsFormSchema

        return renderDynamicContent(optSelectedPlanPane, [
            {
                key: "PLAN_DETAILS_CARD",
                value: <SuperdrugSelectedPlanDetailsCard
                    cmsContent={props.cmsContent}
                    plan={props.selectedPlan}
                    priceWithPromo={props.priceWithPromo}
                />
            },
            {
                key: "CONTENT_CARD",
                value: <CmsForm
                    id="hybris-user-update-form"
                    form={form}
                    submit={(formData) => props.submitForm(formData, form.errorMap)}
                />
            }
        ])
    } else {
        return null
    }
}

interface SimInHandUserUpdateFormProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Details on the authenticated user sources from Hybris.
     */
    readonly userDetails: UserDetails

    /**
     * Callback function will setup the user update form with details source from Hybris
     */
    readonly setupHybrisForm: (hybrisUserDetails: UserDetails) => void

    /**
     * Callback function will submit the user update form.
     */
    readonly submitForm: (formData: FormData, errorMap: CmsFormFieldErrorMap[]) => void

}

/**
 * Function will render the dynamically constructed hybris user update form for a SIM-in-hand journey.
 */
const SimInHandUserUpdateForm = (props: SimInHandUserUpdateFormProps): JSX.Element | null => {

    React.useEffect(() => {
        props.setupHybrisForm(props.userDetails)
    }, [])

    const optSimActivationUserUpdatePage = optHtmlContent(props.cmsContent, "sp-page-sim-activation-user-update")
    const optFancyAnUpgradePage = optHtmlContent(props.cmsContent, "sp-card-fancy-an-upgrade")
    const hybrisUserUpdateForm = props.cmsContent.find((content) => content.reference === "form-hybris-user-update")

    if (optSimActivationUserUpdatePage && optFancyAnUpgradePage && hybrisUserUpdateForm) {
        const form = hybrisUserUpdateForm.jsonContent as CmsFormSchema
        const fancyAnUpgradeCard = renderDynamicContent(optFancyAnUpgradePage)

        return renderDynamicContent(optSimActivationUserUpdatePage, [
            {key: "FANCY_AN_UPGRADE_CARD", value: fancyAnUpgradeCard},
            {
                key: "HYBRIS_USER_UPDATE_FORM",
                value: <CmsForm
                    id="hybris-user-update-form"
                    form={form}
                    submit={(formData) => props.submitForm(formData, form.errorMap)}
                />
            },
        ])
    } else {
        return null
    }
}

interface SuperdrugUserDetailsUpdatePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * When true, the SIM-in-hand journey will be triggered after a successful login.
     */
    readonly simInHand: boolean

}

/**
 * Function will render the Superdrug-specific user details update page that will let the user
 * update their details in Hybris.
 */
const SuperdrugUserDetailsUpdatePage = (props: SuperdrugUserDetailsUpdatePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan | null
        readonly userDetails: UserDetails
        readonly planPrice: PlanPrice | null
    }>()

    const [showAddressPickerForm, setShowAddressPickerForm] = React.useState<boolean>(false)
    const [partialFormEntry, setPartialFormEntry] = React.useState<{
        forename: string | null
        surname: string | null
        dateOfBirth: string | null
    }>()
    const [foundAddresses, setFoundAddresses] = React.useState<Address[]>([])
    const [selectedAddress, setSelectedAddress] = React.useState<Address>()

    const planCode = searchParams.get("plan") // TODO Make this a path variable

    /**
     * Function will submit the form and find an address with the entered promotion code.
     */
    const findAddress = (formData: FormData, errorMap: CmsFormFieldErrorMap[]): void => {
        const postCode = formData.get("postcode")
        const postCodeNotBlank = isNonEmptyString(postCode)
        const postCodeValid = isNonEmptyString(postCode) && PATTERN_POSTCODE.test(postCode)
        const postcodeValid = postCodeNotBlank && postCodeValid
        applyInputError(errorMap, "hybris-user-update-form-date-picker", "postcode", postCodeNotBlank ? undefined : "A postCode was expected but was not provided")
        applyInputError(errorMap, "hybris-user-update-form-date-picker", "postcode", postCodeValid ? undefined : "The given postCode was not valid")

        if (!postcodeValid) {
            toggleInputError(errorMap, "hybris-user-update-form-date-picker", "postcode", undefined)
        } else {
            searchPostcode(applicationContext.salesPortalApiDomain, applicationContext.reseller, postCode as string).then((maybeAddresses) => {
                if (isRequestError(maybeAddresses)) {
                    toggleInputError(errorMap, "hybris-user-update-form-date-picker", "postcode", "No addresses with the given post code could be found")
                } else {
                    setFoundAddresses(maybeAddresses)
                }
            })
        }
    }

    /**
     * Function will submit the form and update the users details in Hybris.
     */
    const updateUserDetails = (formData: FormData, errorMap: CmsFormFieldErrorMap[]): void => {
        const forename = formData.get("forename")
        const forenameNotBlank = isNonEmptyString(forename)
        const forenameLengthLessThan30= isMaxLength(forename,30)
        const forenameNotContainsSpecialCharacters = doesNotContainSpecialCharacters(forename)
        const forenameValid = forenameNotBlank && forenameLengthLessThan30 && forenameNotContainsSpecialCharacters
        applyInputError(errorMap, "hybris-user-update-form", "forename", forenameNotBlank ? undefined : "A forename was expected but was not provided")
        applyInputError(errorMap, "hybris-user-update-form", "forename", forenameLengthLessThan30 ? undefined : "The given forename exceeded the maximum length")
        applyInputError(errorMap, "hybris-user-update-form", "forename", forenameNotContainsSpecialCharacters ? undefined : "The given forename included special characters")
        if (forenameValid) {
            toggleInputError(errorMap, "hybris-user-update-form", "forename", undefined)
        }

        const surname = formData.get("surname")
        const surnameNotBlank = isNonEmptyString(surname)
        const surnameLengthLessThan30 = isMaxLength(surname,30)
        const surnameNotContainsSpecialCharacters = doesNotContainSpecialCharacters(surname)
        const surnameValid = surnameNotBlank && surnameLengthLessThan30 && surnameNotContainsSpecialCharacters
        applyInputError(errorMap, "hybris-user-update-form", "surname", surnameNotBlank ? undefined : "A surname was expected but was not provided")
        applyInputError(errorMap, "hybris-user-update-form", "surname", surnameLengthLessThan30 ? undefined : "The given surname exceeded the maximum length")
        applyInputError(errorMap, "hybris-user-update-form", "surname", surnameNotContainsSpecialCharacters ? undefined : "The given surname included special characters")
        if (surnameValid) {
            toggleInputError(errorMap, "hybris-user-update-form", "surname", undefined)
        }

        const dateOfBirth = formData.get("dateOfBirth")
        const dateOfBirthValid = isNonEmptyString(dateOfBirth) && /^\d{4}-\d{2}-\d{2}$/.test(dateOfBirth)
        toggleInputError(errorMap, "hybris-user-update-form", "dateOfBirth", dateOfBirthValid ? undefined : "A valid date of birth was expected but was not provided")

        let ageIsValid = true
        if (dateOfBirthValid && applicationContext.appConfig.signupConfiguration.minimumSignupAge) {
            const age = dayjs().diff(dayjs(dateOfBirth), 'years')
            ageIsValid = age >= applicationContext.appConfig.signupConfiguration.minimumSignupAge
            toggleInputError(errorMap, "hybris-user-update-form", "dateOfBirth", ageIsValid ? undefined : "You are not old enough to sign up")
        }

        const houseNumber = formData.get("houseNumber")
        const houseNumberNotBlank = isNonEmptyString(houseNumber)
        const houseNumberLengthLessThan30 = !isNonEmptyString(houseNumber) || houseNumber.length <= 30
        const houseNumberValid = houseNumberNotBlank && houseNumberLengthLessThan30
        applyInputError(errorMap, "hybris-user-update-form", "houseNumber", houseNumberNotBlank ? undefined : "A houseNumber was expected but was not provided")
        applyInputError(errorMap, "hybris-user-update-form", "houseNumber", houseNumberLengthLessThan30 ? undefined : "The given houseNumber exceeded the maximum length")
        if (houseNumberValid) {
            toggleInputError(errorMap, "hybris-user-update-form", "houseNumber", undefined)
        }

        const line1 = formData.get("line1")
        const line1NotBlank = isNonEmptyString(line1)
        const line1LengthLessThan30 = !isNonEmptyString(line1) || line1.length <= 30
        const line1AlphaNumericCharacters = !isNonEmptyString(line1) || PATTERN_ALPHA_NUMERIC_W_SPACE.test(line1)
        const line1Valid = line1NotBlank && line1LengthLessThan30 && line1AlphaNumericCharacters
        applyInputError(errorMap, "hybris-user-update-form", "line1", line1NotBlank ? undefined : "A line1 was expected but was not provided")
        applyInputError(errorMap, "hybris-user-update-form", "line1", line1LengthLessThan30 ? undefined : "The given line1 exceeded the maximum length")
        applyInputError(errorMap, "hybris-user-update-form", "line1", line1AlphaNumericCharacters ? undefined : "The given line1 included special characters")
        if (line1Valid) {
            toggleInputError(errorMap, "hybris-user-update-form", "line1", undefined)
        }

        const line2 = formData.get("line2")
        const line2LengthLessThan30 = !isNonEmptyString(line2) || line2.length <= 30
        const line2AlphaNumeric = !isNonEmptyString(line2) || PATTERN_ALPHA_NUMERIC_W_SPACE.test(line2)
        const line2Valid = line2LengthLessThan30 && line2AlphaNumeric

        applyInputError(errorMap, "hybris-user-update-form", "line2", line2LengthLessThan30 ? undefined : "The given line2 exceeded the maximum length")
        applyInputError(errorMap, "hybris-user-update-form", "line2", line2AlphaNumeric ? undefined : "The given line2 included special characters")
        if (line2Valid) {
            toggleInputError(errorMap, "hybris-user-update-form", "line2", undefined)
        }

        const town = formData.get("city")
        const townNotBlank = isNonEmptyString(town)
        const townLengthLessThan30 = !isNonEmptyString(town) || town.length <= 30
        const townValid = townNotBlank && townLengthLessThan30
        applyInputError(errorMap, "hybris-user-update-form", "city", townNotBlank ? undefined : "A town was expected but was not provided")
        applyInputError(errorMap, "hybris-user-update-form", "city", townLengthLessThan30 ? undefined : "The given town included special characters")
        if (townValid) {
            toggleInputError(errorMap, "hybris-user-update-form", "city", undefined)
        }

        const postCode = formData.get("postcode")
        const postCodeNotBlank = isNonEmptyString(postCode)
        const postCodeValid = !isNonEmptyString(postCode) || PATTERN_POSTCODE.test(postCode)
        const postcodeValid = postCodeNotBlank && postCodeValid
        applyInputError(errorMap, "hybris-user-update-form", "postcode", postCodeNotBlank ? undefined : "A postCode was expected but was not provided")
        applyInputError(errorMap, "hybris-user-update-form", "postcode", postCodeValid ? undefined : "The given postCode was not valid")
        if (postcodeValid) {
            toggleInputError(errorMap, "hybris-user-update-form", "postcode", undefined)
        }

        const profiledMarketing = formData.get("profiledMarketing")
        const profiledMarketingValid = profiledMarketing !== null && profiledMarketing === "on"
        toggleInputError(errorMap, "hybris-user-update-form", "profiledMarketing", profiledMarketingValid ? undefined : "You must agree to profiled marketing")

        if (forenameValid && surnameValid && dateOfBirthValid && ageIsValid && houseNumberValid && line1Valid && line2Valid && townValid && postcodeValid && profiledMarketingValid) {
            applicationContext.accessToken().then((accessToken) => {
                if (accessToken) {
                    updateHybrisUser(applicationContext.salesPortalApiDomain, accessToken, {
                        forename: typeof forename === "string" && forename.length > 0 ? forename : null,
                        surname: typeof surname === "string" && surname.length > 0 ? surname : null,
                        dateOfBirth: typeof dateOfBirth === "string" && dateOfBirth.length > 0 ? dateOfBirth : null,
                        address: {
                            streetNumber: typeof houseNumber === "string" && houseNumber.length > 0 ? houseNumber : null,
                            building: null,
                            line1: typeof line1 === "string" && line1.length > 0 ? line1 : null,
                            line2: typeof line2 === "string" && line2.length > 0 ? line2 : null,
                            town: typeof town === "string" && town.length > 0 ? town : null,
                            postCode: typeof postCode === "string" && postCode.length > 0 ? postCode : null
                        }
                    }).then((maybeUserDetails) => {
                        if (isRequestError(maybeUserDetails)) {
                            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                        } else {
                            if (props.simInHand) {
                                navigate(SALES_PORTAL_ROUTES.SimInHand.activate(applicationContext.urlContext))
                            } else {
                                const planCode = componentData?.selectedPlan?.packageCode ?? applicationContext.appConfig.signupConfiguration.defaultPlan!!
                                navigate(SALES_PORTAL_ROUTES.SimOnly.Alternative(applicationContext.urlContext, planCode))
                            }
                        }
                    })
                } else {
                    navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
                }
            })
        } else {
            window.scrollTo(0, 0)
        }
    }

    /**
     * Function will find and populate a form with customer details sourced from Hybris.
     */
    const setupHybrisForm = (userDetails: UserDetails) => {
        let houseNumber: string | null
        if (selectedAddress) {
            houseNumber = selectedAddress.street
        } else {
            houseNumber = userDetails.address.streetNumber ? userDetails.address.streetNumber : userDetails.address.building
        }
        const postcode = selectedAddress ?
            `${selectedAddress.postcode1} ${selectedAddress.postcode2}` : userDetails.address.postCode

        setInputValue("hybris-user-update-form:forename", partialFormEntry?.forename ?? userDetails.forename)
        setInputValue("hybris-user-update-form:surname", partialFormEntry?.surname ?? userDetails.surname)
        setInputValue("hybris-user-update-form:dateOfBirth", partialFormEntry?.dateOfBirth ?? userDetails.dateOfBirth)
        setInputValue("hybris-user-update-form:houseNumber", houseNumber)
        setInputValue("hybris-user-update-form:line1", selectedAddress?.line1 ?? userDetails.address.line1)
        setInputValue("hybris-user-update-form:line2", selectedAddress?.line2 ?? userDetails.address.line2)
        setInputValue("hybris-user-update-form:city", selectedAddress?.town ?? userDetails.address.town)
        setInputValue("hybris-user-update-form:postcode", postcode)

        // the search for another address functionality is disabled for the moment (RT#239715)
        /*
        const optFormFieldWrapperElement =
            document.getElementById("hybris-user-update-form:postcode")?.parentNode?.parentNode
        const optFindAddressesButton =
            document.getElementById("button-find-addresses")
        if (optFormFieldWrapperElement && !optFindAddressesButton) {
            const buttonText = optHtmlContent(props.cmsContent, "sp-link-search-for-addresses")
            const button = document.createElement("button")
            button.id = "button-find-addresses"
            button.classList.add("sp-link", "me-auto")
            button.onclick = () => {
                setPartialFormEntry({
                    forename: (document.getElementById("hybris-user-update-form:forename") as HTMLInputElement)?.value,
                    surname: (document.getElementById("hybris-user-update-form:surname") as HTMLInputElement)?.value,
                    dateOfBirth: (document.getElementById("hybris-user-update-form:dateOfBirth") as HTMLInputElement)?.value
                })
                setShowAddressPickerForm(true)

                setTimeout(() => {
                    window.scrollTo(0, 0)
                }, 0)
            }
            button.textContent = buttonText ?? ""
            optFormFieldWrapperElement.append(button)
        }
        */
    }

    /**
     * Function will find and populate the date picker form with customer deatils.
     */
    const setupAddressPickerForm = () => {
        if (partialFormEntry) {
            setInputValue("hybris-user-update-form-date-picker:forename", partialFormEntry.forename ?? "")
            setInputValue("hybris-user-update-form-date-picker:surname", partialFormEntry.surname ?? "")
            setInputValue("hybris-user-update-form-date-picker:dateOfBirth", partialFormEntry.dateOfBirth ?? "")
            setInputValue("hybris-user-update-form-date-picker:postcode", selectedAddress ? `${selectedAddress.postcode1} ${selectedAddress.postcode2}` : "")

            const optFormFieldWrapperElement =
                document.getElementById("hybris-user-update-form-date-picker:postcode")?.parentNode?.parentNode
            const optFindAddressesButton =
                document.getElementById("button-manual-address")
            if (optFormFieldWrapperElement && !optFindAddressesButton) {
                const buttonText = optHtmlContent(props.cmsContent, "sp-link-manually-enter-address")
                const button = document.createElement("button")
                button.id = "button-manual-address"
                button.classList.add("sp-link", "me-auto")
                button.onclick = () => {
                    setPartialFormEntry({
                        forename: (document.getElementById("hybris-user-update-form-date-picker:forename") as HTMLInputElement)?.value,
                        surname: (document.getElementById("hybris-user-update-form-date-picker:surname") as HTMLInputElement)?.value,
                        dateOfBirth: (document.getElementById("hybris-user-update-form-date-picker:dateOfBirth") as HTMLInputElement)?.value
                    })
                    setShowAddressPickerForm(!showAddressPickerForm)

                    setTimeout(() => {
                        window.scrollTo(0, 0)
                    }, 0)
                }
                button.textContent = buttonText ?? ""
                optFormFieldWrapperElement.append(button)
            }
        }
    }

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }
        // A hybris user must have the loyalty flag set to true and must have a loyalty card number
        // that is not null or does not start with 3.
        const hasValidLoyaltyCard = maybeUser.hybris !== null &&
            maybeUser.hybris.loyalty &&
            !maybeUser.hybris.cardNumber.startsWith("3") &&
            !maybeUser.hybris.cardNumber.startsWith("null")

        if (!hasValidLoyaltyCard) { // Invalid loyalty card, kick them out.
            removeLocalStorageItem("access_token")
            removeLocalStorageItem("refresh_token")
            removeLocalStorageItem("expires_at")
            removeSessionItem("hybris-user")
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
        }

        let plan: SimOnlyPlan | null
        if (!props.simInHand) {
            const maybePlan = await fetchSimOnlyPlanByCode(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode ?? applicationContext.appConfig.signupConfiguration.defaultPlan!!
            )
            if (isRequestError(maybePlan)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            plan = maybePlan
        } else {
            plan = null
        }

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (!props.simInHand && optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        setComponentData({
            selectedPlan: plan,
            userDetails: maybeUser,
            planPrice: planPriceWithPromo
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [])

    if (!componentData) {
        return null
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant={planCode ? "secondary" : "tertiary"}>
                {!showAddressPickerForm && componentData.selectedPlan && !props.simInHand && <WebSaleUserUpdateForm
                    cmsContent={props.cmsContent}
                    selectedPlan={componentData.selectedPlan}
                    userDetails={componentData.userDetails}
                    priceWithPromo={componentData.planPrice}
                    setupHybrisForm={setupHybrisForm}
                    submitForm={updateUserDetails}
                />}

                {!showAddressPickerForm && props.simInHand && <SimInHandUserUpdateForm
                    cmsContent={props.cmsContent}
                    userDetails={componentData.userDetails}
                    setupHybrisForm={setupHybrisForm}
                    submitForm={updateUserDetails}
                />}

                {showAddressPickerForm && <AddressFinderForm
                    cmsContent={props.cmsContent}
                    foundAddresses={foundAddresses}
                    setChosenAddressIndex={(index) => {
                        setSelectedAddress(foundAddresses[index])
                        setShowAddressPickerForm(!showAddressPickerForm)
                        window.scrollTo(0, 0)
                    }}
                    setupAddressPickerForm={setupAddressPickerForm}
                    submitForm={findAddress}
                />}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugUserDetailsUpdatePage