import {CmsContent} from "../../Model/CmsContent/CmsContent";
import * as React from "react";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import CmsStaticHtmlContent from "../CmsStaticHtmlContent/CmsStaticHtmlContent";
import {isUserDetails, UserDetails} from "../../Model/User/UserDetails";
import {readSessionItem} from "../../Utilities/SessionUtilities";
import {readLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {ApplicationContext} from "../../ApplicationContext";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {useNavigate} from "react-router-dom";
import {checkCustomerLink} from "../../Api/Hybris/UserManagementApi";
import {isRequestError} from "../../Model/RequestError";

interface OpenNavbarProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

    /**
     * When set, this component will look for an alternative navbar. If found, it will be displayed over the standard
     * navbar. When undefined, the primary navbar will be rendered.
     */
    readonly variant: "primary" | "secondary" | "tertiary"

}

/**
 * Function will render the expanded navbar that is found when clicking on the menu expansion button.
 */
const OpenNavbar = (props: OpenNavbarProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const [showLimited, setShowLimited] = React.useState<boolean>()

    React.useEffect(() => {
        applicationContext.accessToken().then((accessToken) => {
            if (accessToken) {
                checkCustomerLink(applicationContext.salesPortalApiDomain, accessToken).then((maybeCustomerLink) => {
                    // Customer exists and has an order, show the full navbar.
                    setShowLimited(!isRequestError(maybeCustomerLink) && maybeCustomerLink.orderExists === false && maybeCustomerLink.orderActive === false)
                })
            } else {
                navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            }
        })
    }, [])

    if (showLimited === undefined) {
        return null
    } else {
        return (
            <div>
                {props.variant === "secondary" && !showLimited && <CmsStaticHtmlContent
                    cmsContent={props.cmsContent} reference="sp-navbar-secondary-open"
                />}

                {props.variant === "secondary" && showLimited && <CmsStaticHtmlContent
                    cmsContent={props.cmsContent} reference="sp-navbar-secondary-open-limited"
                />}

                {props.variant === "tertiary" && !showLimited && <CmsStaticHtmlContent
                    cmsContent={props.cmsContent} reference="sp-navbar-tertiary-open"
                />}

                {props.variant === "tertiary" && showLimited && <CmsStaticHtmlContent
                    cmsContent={props.cmsContent} reference="sp-navbar-tertiary-open-limited"
                />}
            </div>
        )
    }
}

interface SuperdrugNavigationBarProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

    /**
     * When set, this component will look for an alternative navbar. If found, it will be displayed over the standard
     * navbar. When undefined, the primary navbar will be rendered.
     */
    readonly variant: "primary" | "secondary" | "tertiary"

}

/**
 * Function will determine which navbar needs to be displayed. When a non-primary variant is selected and
 * a relevant navbar does not exist in the CMS, the primary navbar will be rendered instead.
 */
const SuperdrugNavigationBar = (props: SuperdrugNavigationBarProps): JSX.Element | null => {

    const [navabrOpen, setNavbatOpen] = React.useState<boolean>(false)

    const navbarContent = (): string | null => {
        const defaultNavbar = optHtmlContent(props.cmsContent, "sp-navbar")

        switch (props.variant) {
            case "primary":
                return defaultNavbar
            case "secondary":
                return optHtmlContent(props.cmsContent, "sp-navbar-secondary") ?? defaultNavbar
            case "tertiary":
                return optHtmlContent(props.cmsContent, "sp-navbar-tertiary") ?? defaultNavbar
        }
    }

    /**
     * Function will render the open/close button found on the navigation bar.
     */
    const NavbarOpenCloseControl = (): JSX.Element => {
        if (navabrOpen) {
            return (
                <button className="sp-navbar-toggle" onClick={() => setNavbatOpen(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            )
        } else {
            return (
                <button className="sp-navbar-toggle" onClick={() => setNavbatOpen(true)}>
                    <i className="fa-solid fa-bars"></i>
                </button>
            )
        }
    }

    const optNavbar = navbarContent()

    const maybeHybrisUser = readSessionItem("hybris-user", isUserDetails)
    const maybeExpiresAt = readLocalStorageItem(
        "expires_at", (a: any): a is number => typeof a === "number")

    let accessTokenNotExpired = false
    if (maybeExpiresAt) {
        const currentUtcTime = new Date().getUTCDate()
        accessTokenNotExpired = maybeExpiresAt > currentUtcTime
    }

    const showOpenCloseControl = maybeHybrisUser !== null || accessTokenNotExpired

    if (optNavbar) {
        return (
            <div>
                {showOpenCloseControl && renderDynamicContent(optNavbar, [
                    {key: "ACCOUNT_BUTTON_TOGGLE", value: <NavbarOpenCloseControl/>}
                ])}

                {!showOpenCloseControl && renderDynamicContent(optNavbar, [
                    {key: "ACCOUNT_BUTTON_TOGGLE", value: ""}
                ])}

                {navabrOpen && <OpenNavbar cmsContent={props.cmsContent} variant={props.variant}/>}
            </div>
        )
    } else {
        return null
    }
}

export default SuperdrugNavigationBar