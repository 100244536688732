import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {
    SimOnlyPlan,
    simOnlyPlanContractLength,
    simOnlyPlanDataLimit,
    simOnlyPlanMonthlyCost,
    simOnlyPlanVoiceLimit,
    simOnlySmsLimit
} from "../../Model/Sales/SimOnlyPlan";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {useParams} from "react-router-dom";
import {ApplicationContext} from "../../ApplicationContext";
import SimOnlyPlanPurchaseForm from "./Components/SimOnlyPlanPurchaseForm";

interface StandardSimOnlyPlanPurchasePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function is responsible for rendering the SIM-only purchase plan page. This page will
 * allow a user to sign up to the reseller via Lifecycle systems.
 */
const StandardSimOnlyPlanPurchasePage = (props: StandardSimOnlyPlanPurchasePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    const {planCode, iccid} = useParams<keyof { readonly planCode: string, readonly iccid?: string }>()

    const [simOnlyPlan, setSimOnlyPlan] = React.useState<SimOnlyPlan>()
    const [otherSimOnlyPlans, setOtherSimOnlyPlans] = React.useState<SimOnlyPlan[]>([])
    const [planPriceOverride, setPlanPriceOverride] = React.useState<number>()

    const optHowSimOnlyPlansWorkPane = optHtmlContent(props.cmsContent, "sp-pane-how-sim-only-plans-work")
    const optSwitchingToUsPane = optHtmlContent(props.cmsContent, "sp-pane-switching-to-us")
    const optFaqsPane = optHtmlContent(props.cmsContent, "sp-pane-faq")

    /**
     * Component will dynamically render the sim only signup pane by converting the selected SimOnlyPlan into a card
     * that follows the "sp-card-sim-only-plan-signup" template. Once converted, the card will be joined with the
     * signup form, creating the SIM-only signup pane.
     */
    const SimOnlySignupPane = (): JSX.Element | null => {
        const optSimOnlySignupPane = optHtmlContent(props.cmsContent, "sp-pane-sim-only-signup")
        const optSimOnlyPlanSignupCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-signup")

        if (simOnlyPlan && optSimOnlySignupPane && optSimOnlyPlanSignupCard) {
            const simOnlyPlanSignupCard = renderDynamicContent(optSimOnlyPlanSignupCard, [
                {
                    key: "MONTHLY_PRICE",
                    value: planPriceOverride?.toString() ?? simOnlyPlanMonthlyCost(simOnlyPlan).toString()
                },
                {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                {key: "SMS_ALLOWANCE", value: simOnlySmsLimit(simOnlyPlan)},
                {key: "VOICE_ALLOWANCE", value: simOnlyPlanVoiceLimit(simOnlyPlan)},
                {key: "CONTRACT_DURATION", value: simOnlyPlanContractLength(simOnlyPlan)}
            ])

            return renderDynamicContent(optSimOnlySignupPane, [
                {
                    key: "SIM_ONLY_SIGNUP_FORM",
                    value: <SimOnlyPlanPurchaseForm
                        cmsContent={props.cmsContent}
                        selectedPlan={simOnlyPlan}
                        planPriceOverride={planPriceOverride ?? simOnlyPlan.monthlyPrice}
                        iccid={iccid}
                        setPlanPriceOverride={setPlanPriceOverride}
                    />
                },
                {key: "SIM_ONLY_PLAN_DETAILS_CARD", value: simOnlyPlanSignupCard}
            ])
        } else {
            return null
        }
    }

    /**
     * Component will dynamically render the other SIM-only plans pane by first converting the given list of
     * SimOnlyPlans into HTML that follows the "sp-card-sim-only-plan-limited" template. Once converted, this HTML
     * will be injected into the "sp-pane-other-sim-only-plans" dynamic component to create the pane.
     */
    const OtherSimOnlyPlans = (): JSX.Element | null => {
        const optOtherSimOnlyPlansPane = optHtmlContent(props.cmsContent, "sp-pane-other-sim-only-plans")
        const optLimitedSimOnlyPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-limited")

        if (optOtherSimOnlyPlansPane && optLimitedSimOnlyPlanCard) {
            const limitedSimOnlyPlanCards = otherSimOnlyPlans.map((simOnlyPlan) =>
                renderDynamicContent(optLimitedSimOnlyPlanCard, [
                    {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                    {key: "MONTHLY_PRICE", value: simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                    {
                        key: "VIEW_PLAN_LINK",
                        value: SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.fauxPackageCode)
                    }
                ])
            )

            return renderDynamicContent(optOtherSimOnlyPlansPane, [
                {key: "SIM_ONLY_PLANS", value: limitedSimOnlyPlanCards}
            ])
        } else {
            return null
        }
    }

    React.useEffect(() => {
        const selectedSimOnlyPlan = props.simOnlyPlans.find((simOnlyPlan) => simOnlyPlan.fauxPackageCode === planCode)
        const otherSimOnlyPlans = props.simOnlyPlans.filter((simOnlyPlan) => simOnlyPlan.fauxPackageCode !== planCode)

        setSimOnlyPlan(selectedSimOnlyPlan)
        setOtherSimOnlyPlans(otherSimOnlyPlans)
    }, [props.simOnlyPlans])

    if (simOnlyPlan === undefined || otherSimOnlyPlans.length === 0) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                <SimOnlySignupPane/>

                <OtherSimOnlyPlans/>

                {optHowSimOnlyPlansWorkPane && renderDynamicContent(optHowSimOnlyPlansWorkPane)}

                {optSwitchingToUsPane && renderDynamicContent(optSwitchingToUsPane)}

                {optFaqsPane && renderDynamicContent(optFaqsPane)}
            </HeaderFooterWrapper>
        )
    }
}

export default StandardSimOnlyPlanPurchasePage