import * as React from "react";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import SimInHandCaptureForm from "./Components/SimInHandCaptureForm";

interface StandardSimInHandCapturePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function is responsible for rendering the SIM-in-hand capture page. This page will capture
 * information on the customers SIM card that we will append to a new order.
 */
const StandardSimInHandCapturePage = (props: StandardSimInHandCapturePageProps): JSX.Element => {

    const optChoosingUsPane = optHtmlContent(props.cmsContent, "sp-pane-choosing-us")
    const optFaqsPane = optHtmlContent(props.cmsContent, "sp-pane-faq")

    /**
     * Component will dynamically render the SIM-in-hand capture pane by first converting the SimInHandCaptureForm
     * component into a raw HTML string. Once converted, this HTML will be injected into the
     * "sp-card-sim-in-hand-capture" dynamic component to create the card. Finally, the dynamically created card will
     * be injected into the "sp-pane-sim-in-hand-capture" dynamic component to render the pane.
     */
    const SimInHandCapturePane = (): JSX.Element | null => {
        const optSimInHandCaptureCard = optHtmlContent(props.cmsContent, "sp-card-sim-in-hand-capture")
        const optSimInHandCapturePane = optHtmlContent(props.cmsContent, "sp-pane-sim-in-hand-capture")

        if (optSimInHandCaptureCard && optSimInHandCapturePane) {
            const simInHandCaptureCard = renderDynamicContent(optSimInHandCaptureCard, [
                {key: "SIM_IN_HAND_CAPTURE_FORM", value: <SimInHandCaptureForm/>}
            ])

            return renderDynamicContent(optSimInHandCapturePane, [
                {key: "SIM_IN_HAND_CAPTURE_CARD", value: simInHandCaptureCard}
            ])
        } else {
            return null
        }
    }

    return (
        <div>
            <SimInHandCapturePane/>

            {optChoosingUsPane && renderDynamicContent(optChoosingUsPane)}

            {optFaqsPane && renderDynamicContent(optFaqsPane)}
        </div>
    )
}

export default StandardSimInHandCapturePage