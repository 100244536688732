import * as React from "react";
import {InlinedState} from "../../../Types/InlinedState";
import {Dayjs} from "dayjs";
import TextFormRow from "../../../Components/Form/TextFormRow";
import DateFormRow from "../../../Components/Form/DateFormRow";
import {ApplicationContext} from "../../../ApplicationContext";

interface PortInformationCaptureProps {

    /**
     * Number to be used when triggering a port on signup.
     */
    readonly portInMsisdn: InlinedState<string | undefined>

    /**
     * Validation error for the port in number form field.
     */
    readonly portInMsisdnError: string | undefined

    /**
     * Switching code to be used when triggering a port on signup.
     */
    readonly switchingCode: InlinedState<string | undefined>

    /**
     * Validation error for the switching code form field.
     */
    readonly switchingCodeError: string | undefined

    /**
     * Date the triggered port should complete on.
     */
    readonly switchingDate: InlinedState<Dayjs | undefined>

    /**
     * Validation error for the switching date form field.
     */
    readonly switchingDateError: string | undefined

}

/**
 * Function will render the port information section of the SIM-only plan signup form.
 */
const PortInformationCapture = (props: PortInformationCaptureProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const portTriggerConfig = applicationContext.appConfig.signupConfiguration.portTriggerConfig

    return (
        <div className="d-flex flex-column gap-2">
            <span className="sp-subtitle sp-text-primary text-start m-0">
                Keeping Your Number
            </span>

            {portTriggerConfig.triggeringPortsDescription &&
                <span className="sp-paragraph sp-text-secondary text-start m-0">
                    {portTriggerConfig.triggeringPortsDescription}
                </span>
            }

            <TextFormRow
                label="Mobile Number"
                type="text"
                maxLength={props.portInMsisdn.value?.startsWith("4") ? 12 : 11}
                value={props.portInMsisdn.value}
                error={props.portInMsisdnError}
                setValue={props.portInMsisdn.setValue}
            />

            <div className="d-flex flex-row gap-4">
                <div className="flex-fill">
                    <TextFormRow
                        label="Switching Code"
                        type="text"
                        maxLength={9}
                        value={props.switchingCode.value}
                        error={props.switchingCodeError}
                        setValue={props.switchingCode.setValue}
                    />
                </div>
                <div className="flex-fill">
                    <DateFormRow
                        label="Switching Date"
                        date={props.switchingDate.value}
                        error={props.switchingDateError}
                        setDate={props.switchingDate.setValue}
                    />
                </div>
            </div>
        </div>
    )
}

export default PortInformationCapture
