import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {CmsContent} from "../../Model/CmsContent/CmsContent";

interface CmsStaticHtmlContentProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Reference to the static content we are trying to render.
     */
    readonly reference: String

}

/**
 * Function will render dynamic HTML content from the CMS with the given reference if it exists.
 */
const CmsStaticHtmlContent = (props: CmsStaticHtmlContentProps): JSX.Element | null => {
    const optContent = optHtmlContent(props.cmsContent, props.reference)

    if (optContent) {
        return renderDynamicContent(optContent, [])
    } else {
        return null
    }
}

export default CmsStaticHtmlContent
