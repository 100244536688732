import * as React from "react"
import {
    SimOnlyPlan,
    simOnlyPlanContractLength,
    simOnlyPlanDataLimit,
    simOnlyPlanVoiceLimit,
    simOnlySmsLimit
} from "../../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../../Utilities/CmsUtilities";

interface SimOnlyOrderConfirmationProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Monthly price of the customers selected plan after any discounts.
     */
    readonly monthlyPrice: number

    /**
     * SIM-only plan that has been selected by the customer.
     */
    readonly simOnlyPlan: SimOnlyPlan

    /**
     * First line of the delivery address
     */
    readonly line1: string

    /**
     * Second line of the delivery address
     */
    readonly line2: string | null

    /**
     * Third line of the delivery address
     */
    readonly line3: string | null

    /**
     * City of the delivery address
     */
    readonly city: string

    /**
     * Postcode of the delivery address
     */
    readonly postcode: string

}

/**
 * Function will render the SIM-only order confirmation page when the customers order has been submitted.
 */
const SimOnlyOrderConfirmation = (props: SimOnlyOrderConfirmationProps): JSX.Element | null => {

    const optOrderConfirmationPane = optHtmlContent(props.cmsContent, "sp-pane-order-confirmation")

    if (optOrderConfirmationPane) {
        return renderDynamicContent(optOrderConfirmationPane, [
            {key: "MONTHLY_PRICE", value: props.monthlyPrice.toString()},
            {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(props.simOnlyPlan)},
            {key: "SMS_ALLOWANCE", value: simOnlySmsLimit(props.simOnlyPlan)},
            {key: "VOICE_ALLOWANCE", value: simOnlyPlanVoiceLimit(props.simOnlyPlan)},
            {key: "CONTRACT_DURATION", value: simOnlyPlanContractLength(props.simOnlyPlan)},

            {key: "ADDRESS_LINE_1", value: props.line1},
            {key: "ADDRESS_LINE_2", value: props.line2},
            {key: "ADDRESS_LINE_3", value: props.line3},
            {key: "ADDRESS_CITY", value: props.city},
            {key: "ADDRESS_POSTCODE", value: props.postcode}
        ])
    } else {
        return null
    }
}

export default SimOnlyOrderConfirmation