import * as React from "react"
import {v4 as uuidv4} from 'uuid';

interface SelectFormRowProps<A, > {

    /**
     * Label for the form field.
     */
    readonly label: string

    /**
     * Array of key/value pairs that can be selected.
     */
    readonly options: A[]

    /**
     * Transformation function will convert an instance of A into a displayable string.
     */
    readonly optionToString: (a: A) => string

    /**
     * Callback function will be called whenever the user changes their selection.
     */
    readonly onSelect: (selected: A) => void

}

/**
 * Function will render a form row that will capture a pre-defined value.
 */
const SelectFormRow = <A, >(props: SelectFormRowProps<A>): JSX.Element => {

    const id = uuidv4()

    /**
     * Function will find the selected element in the given array of options and call the onSelect callback function.
     */
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = parseInt(e.target.value)

        props.onSelect(props.options[selectedIndex])
    }

    return (
        <div className="form-group">
            <label htmlFor={id}>{props.label}</label>

            <select id={id} className="form-control form-control-lg" onChange={onChange}>
                {props.options.map((entity, index) =>
                    <option value={index}>
                        {props.optionToString(entity)}
                    </option>
                )}
            </select>
        </div>
    )
}

export default SelectFormRow