import * as React from "react";
import {CmsContent} from "../../../Model/CmsContent/CmsContent";
import {SimOnlyPlan} from "../../../Model/Sales/SimOnlyPlan";
import {ApplicationContext} from "../../../ApplicationContext";
import {KeyValue} from "../../../Types/KeyValue";
import {optHtmlContent, renderDynamicContent} from "../../../Utilities/CmsUtilities";
import {currencyPrettyPrint} from "../../../Model/Configuration/CurrencyConfiguration";
import {PlanPrice} from "../../../Model/Sales/PlanPrice";

interface SuperdrugSelectedPlanDetailsCardSimInHandProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * The customers currently selected plan.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Information on the plan the customer bought in store.
     */
    readonly paidInStorePlan: SimOnlyPlan

    /**
     * Information on how much the selected plan costs.
     */
    readonly priceWithPromo: PlanPrice | null

    /**
     * Information on how much the paid in store plan costs.
     */
    readonly paidInStorePriceWithPromo: PlanPrice | null

}

/**
 * Function will render a card with information on the customers currently selected plan.
 */
const SuperdrugSelectedPlanDetailsCardSimInHand = (props: SuperdrugSelectedPlanDetailsCardSimInHandProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    let discountedPrice
    if (props.priceWithPromo && props.paidInStorePriceWithPromo) {
        discountedPrice = props.priceWithPromo.priceToPay - props.paidInStorePriceWithPromo.priceToPay
    } else {
        discountedPrice = props.selectedPlan.monthlyPrice - props.paidInStorePlan.monthlyPrice
    }
    const paidInStorePrice = props.paidInStorePriceWithPromo?.priceToPay ?? props.paidInStorePlan?.monthlyPrice

    let optPlanDetailsCard: string | null
    let templateTags: KeyValue<string | React.ReactNode | React.ReactNode[]>[]
    if (props.priceWithPromo) {
        let discountString
        if (props.priceWithPromo.promoPercentageDiscount) {
            discountString = `-${props.priceWithPromo.promoPercentageDiscount}%`
        } else if (props.priceWithPromo.promoFixedDiscount) {
            discountString = `-£${props.priceWithPromo.promoFixedDiscount.toFixed(2)}`
        } else if (props.priceWithPromo.promoPriceOverride) {
            discountString = `-£${(props.selectedPlan.monthlyPrice - props.priceWithPromo.promoPriceOverride).toFixed(2)}`
        } else {
            discountString = "" // Shouldn't be possible to get here
        }

        templateTags = [
            {key: "PLAN_DESCRIPTION", value: props.selectedPlan.description},
            {
                key: "DISCOUNTED_PRICE",
                value: currencyPrettyPrint(discountedPrice, applicationContext.appConfig.currencyConfiguration)
            },
            {
                key: "PLAN_PRICE",
                value: currencyPrettyPrint(props.priceWithPromo?.priceToPay ?? props.selectedPlan?.monthlyPrice, applicationContext.appConfig.currencyConfiguration)
            },
            {
                key: "PAID_IN_STORE",
                value: currencyPrettyPrint(paidInStorePrice, applicationContext.appConfig.currencyConfiguration)
            },
            {key: "PROMO_DESCRIPTION", value: props.priceWithPromo.promoDescription},
            {key: "PROMO_DISCOUNT", value: discountString}
        ]
        optPlanDetailsCard = optHtmlContent(props.cmsContent, "sp-card-sim-in-hand-selected-plan-details-promo")
    } else {
        templateTags = [
            {key: "PLAN_DESCRIPTION", value: props.selectedPlan.description},
            {
                key: "PAID_IN_STORE",
                value: currencyPrettyPrint(paidInStorePrice, applicationContext.appConfig.currencyConfiguration)
            },
            {
                key: "PLAN_PRICE",
                value: currencyPrettyPrint(discountedPrice, applicationContext.appConfig.currencyConfiguration)
            }
        ]
        optPlanDetailsCard = optHtmlContent(props.cmsContent, "sp-card-sim-in-hand-selected-plan-details")
    }

    if (!optPlanDetailsCard) {
        return null
    } else {
        return renderDynamicContent(optPlanDetailsCard, templateTags)
    }
}

export default SuperdrugSelectedPlanDetailsCardSimInHand