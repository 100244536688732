import * as React from "react"
import {PacmanLoader, SyncLoader} from "react-spinners";
import {ApplicationContext} from "../../ApplicationContext";

interface LoadingBackdropProps {

    /**
     * When true, the modal will be rendered.
     */
    readonly isLoading: boolean

}

/**
 * Function is responsible for rendering the loading backdrop. This backdrop will
 * display a loading gif and prevent the user from clicking on other components
 * until loading has finished.
 */
const LoadingBackdrop = (props: LoadingBackdropProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const theme = applicationContext.appConfig.theme

    if (props.isLoading) {
        return (
            <div>
                <div style={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    minHeight: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    zIndex: 10
                }}>
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        {applicationContext.reseller === "pingu" && <PacmanLoader
                            color={theme?.tertiaryColour}
                            size="10rem"
                        />}

                        {applicationContext.reseller !== "pingu" && <SyncLoader
                            color={theme?.tertiaryColour}
                            size={50}
                        />}

                        <span className="sp-title sp-text-tertiary mt-5">
                            loading
                        </span>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }

}

export default LoadingBackdrop