import axios from "axios"
import {isUserDetails, UserDetails} from "../../Model/User/UserDetails";
import {isRequestError, RequestError, UnknownRequestError} from "../../Model/RequestError";
import {
    acceptHeader,
    authenticationHeader,
    executeWithErrorResponseHandling,
    jsonContentTypeHeader
} from "../ApiUtilities";
import {readSessionItem, saveSessionItem} from "../../Utilities/SessionUtilities";
import {joinUrl} from "../../Utilities/UriUtilities";
import {UpsertUserDetails} from "../../Model/User/UpsertUserDetails";
import {CustomerLink} from "../../Model/User/CustomerLink";

/**
 * Wait an amount of milliseconds
 * @param ms the amount of milliseconds to wait
 */
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Function will fetch information on the currently authenticated user. This is configured to retry when it detects an
 * invalid card number that starts with a 3. Elab API has a race condition where the API doesn't return the newly updated
 * information for a second.
 *
 * Returns the user or a request error. If a user has a card number starting with 3, it is retried. If the retry count
 * is exhausted it will return the last user details.
 *
 * @param apiDomain Base endpoint for the sales portal
 * @param accessToken Access token to be included in the request to Hybris.
 */
export const fetchHybrisUser = async (
    apiDomain: string,
    accessToken: string
): Promise<RequestError | UserDetails> => {
    const optHybrisUser = readSessionItem("hybris-user", isUserDetails)

    if (optHybrisUser) {
        return JSON.parse(JSON.stringify(optHybrisUser)) // Force new object reference.
    } else {
        let retryCount: number = 1;
        const endpoint = joinUrl(apiDomain, "/users/info")
        const headers = {...acceptHeader(), ...authenticationHeader(accessToken)}
        let lastUser = null

        while (retryCount <= 5) {
            const response = await executeWithErrorResponseHandling<UserDetails>(() =>
                axios.get(endpoint, {headers: headers}))

            if (isRequestError(response)) {
                console.error("Failed to communicate with external system", response as RequestError)
                return response
            } else {
                lastUser = response.data
                // Trigger retry if the card is not updated yet at hybris. If it starts with a 3 it is invalid
                if(response.data?.hybris?.cardNumber?.startsWith("3")) {
                    await wait(250 * retryCount)
                } else {
                    saveSessionItem("hybris-user", response.data)
                    return response.data
                }
            }
            retryCount = retryCount + 1
        }

        return lastUser || UnknownRequestError;
    }
}

/**
 * Function will update a Hybris-owned user.
 *
 * @param apiDomain Base endpoint for the sales portal
 * @param accessToken Access token to be included in the request to Hybris.
 * @param upsert Updated details on the user.
 */
export const updateHybrisUser = async (
    apiDomain: string,
    accessToken: string,
    upsert: UpsertUserDetails
): Promise<RequestError | UserDetails> => {
    const endpoint = joinUrl(apiDomain, "/users/update")
    const headers = {...acceptHeader(), ...jsonContentTypeHeader(), ...authenticationHeader(accessToken)}

    const response = await executeWithErrorResponseHandling<UserDetails>(() =>
        axios.patch(endpoint, upsert, {headers: headers}))

    if (isRequestError(response)) {
        console.error("Failed to communicate with external system", response as RequestError)
        return response
    } else {
        saveSessionItem("hybris-user", response.data)
        return response.data
    }

}

/**
 * Function will determine if the authenticated customer has an account and the status of that account.
 *
 * @param apiDomain Base endpoint for the sales portal
 * @param accessToken Access token to be included in the request to DX.
 */
export const checkCustomerLink = async (
    apiDomain: string,
    accessToken: string
): Promise<RequestError | CustomerLink> => {
    const endpoint = joinUrl(apiDomain, "/users/check-link")
    const headers = {...acceptHeader(), ...authenticationHeader(accessToken)}

    const response = await executeWithErrorResponseHandling<CustomerLink>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}
