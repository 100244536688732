import {SessionKey} from "../Types/SessionKey";

/**
 * Function will store the given item against the given session key in the users' browser session.
 */
export const saveSessionItem = <A, >(sessionKey: SessionKey, item: A): void => {
    if (typeof item === "string") {
        sessionStorage.setItem(sessionKey, item)
    } else {
        sessionStorage.setItem(sessionKey, JSON.stringify(item))
    }

}

/**
 * Function will remove the given item from the users session data if it exists.
 */
export const removeSessionItem = (sessionKey: SessionKey): void =>
    sessionStorage.removeItem(sessionKey)

/**
 * Utility function will attempt to read an instance of A from the stored session data if it exists and is of the
 * correct format.
 */
export const readSessionItem = <A, >(sessionKey: SessionKey, isA: (a: any) => a is A): A | null => {
    const maybeItem = sessionStorage.getItem(sessionKey)
    try {
        const maybeItemObject = maybeItem ? JSON.parse(maybeItem) : null
        return maybeItemObject && isA(maybeItemObject) ? maybeItemObject : null
    } catch (e) { // Storage item was not JSON
        return isA(maybeItem) ? maybeItem : null
    }
}
