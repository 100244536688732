import {isOptNumber, isString} from "../../Utilities/TypeGuardUtilities";

export interface PlanLimit {

    /**
     * The unit used to determine what this limit is.
     */
    readonly planLimitUnit: string

    /**
     * The maximum value this limit allows. When null, this is unlimited.
     */
    readonly planLimitValue: number | null

}

/**
 * Type-guard function will check if the given instance of any is of type PlanLimit.
 */
export const isPlanLimit = (a: any): a is PlanLimit =>
    isString(a, "planLimitUnit") &&
    isOptNumber(a, "planLimitValue")

/**
 * Utility function is responsible for converting the given PlanLimit into a
 * displayable string.
 */
export const planLimitPrettyPrint = (planLimit: PlanLimit, isData?: boolean): string => {
    const isUnlimited = planLimit.planLimitValue === null

    if (isData === true) {
        const formattedUnitType = planLimit.planLimitUnit.toUpperCase()
        return isUnlimited ? "Unlimited Data" : planLimit.planLimitValue + " " + formattedUnitType + " Data"
    } else {
        const formattedUnitType = planLimit.planLimitUnit[0].toUpperCase() + planLimit.planLimitUnit.substring(1)
        return isUnlimited ? "Unlimited " + formattedUnitType : planLimit.planLimitValue + " " + formattedUnitType
    }
}