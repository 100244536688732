import * as React from "react"
import {SimOnlyPlan, simOnlyPlanDataLimit, simOnlyPlanMonthlyCost} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {Navigate} from "react-router-dom";
import {CmsPlanCardMappingSchema} from "../../Model/CmsContent/CmsPlanCardMappingSchema";

interface SuperdrugSimOnlyPlanDetailsPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * The SIM-only plan that has been selected by the customer.
     */
    readonly simOnlyPlan: SimOnlyPlan

    /**
     * Array of all other SIM-only plans a customer could select.
     */
    readonly otherSimOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the Superdrug SIM-only details page.
 */
const SuperdrugSimOnlyPlanDetailsPage = (props: SuperdrugSimOnlyPlanDetailsPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    /**
     * Component will dynamically render the SIM-only details pane by first converting the given SimOnlyPlan into HTML
     * that follows the "sp-card-sim-only-plan-full-no-data" template. Once converted, this HTML will be injected
     * into the "sp-pane-sim-only-details" dynamic component to create the pane.
     */
    const SelectedPlanDetails = (): JSX.Element | null => {
        const optPlanToCardMapping = props.cmsContent.find((content) => content.reference === "sales-portal-plan-card-mapping")

        if (optPlanToCardMapping) {
            const cardMappingOptions = optPlanToCardMapping.jsonContent as CmsPlanCardMappingSchema

            const optCardMapping = cardMappingOptions.selectedPlanMapping
                .find((planMapping) => planMapping.planCode === props.simOnlyPlan.packageCode || planMapping.default)
            const optCardContent = optCardMapping ? optHtmlContent(props.cmsContent, optCardMapping.cardReference) : undefined

            if (optCardMapping && optCardContent) {
                return renderDynamicContent(optCardContent, [
                    {key: "PLAN_CODE", value: props.simOnlyPlan.packageCode},
                    {key: "MONTHLY_PRICE", value: optCardMapping.overridePlanCost || simOnlyPlanMonthlyCost(props.simOnlyPlan).toString()},
                    {key: "PLAN_COLOUR", value: optCardMapping.planColour},
                    {key: "LINK_SUFFIX", value: optCardMapping.linkSuffix || ""},
                    {key: "CARD_CLASS", value: optCardMapping.cardClass || ""},
                    {key: "TITLE_SUFFIX", value: optCardMapping.cardSubtitle || " DATA "},
                    {key: "FOOTER", value: optCardMapping.cardFooter || ""}
                ])
            } else {
                return null
            }
        } else {
            return null
        }
    }

    /**
     * Component will dynamically render the other SIM-only plans pane by first converting the given list of
     * SimOnlyPlans into HTML that follows the "sp-card-sim-only-plan-limited" template. Once converted, this HTML
     * will be injected into the "sp-pane-other-sim-only-plans" dynamic component to create the pane.
     */
    const OtherSimOnlyPlans = (): JSX.Element | null => {
        const optPlanToCardMapping = props.cmsContent.find((content) => content.reference === "sales-portal-plan-card-mapping")

        if (optPlanToCardMapping) {
            const cardMappingOptions = optPlanToCardMapping.jsonContent as CmsPlanCardMappingSchema

            const limitedSimOnlyPlanCards = props.otherSimOnlyPlans.map((simOnlyPlan, index) => {
                const optCardMapping = cardMappingOptions.otherPlansMapping
                    .find((planMapping) => planMapping.planCode === simOnlyPlan.packageCode || planMapping.default)
                const optCardContent = optCardMapping ? optHtmlContent(props.cmsContent, optCardMapping.cardReference) : undefined

                if (optCardMapping && optCardContent) {
                    return renderDynamicContent(optCardContent, [
                        {key: "DATA_ALLOWANCE", value: optCardMapping.overridePlanDataAllowance || simOnlyPlanDataLimit(simOnlyPlan)},
                        {key: "MONTHLY_PRICE", value: optCardMapping.overridePlanCost || simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                        {key: "PLAN_CODE", value: simOnlyPlan.packageCode},
                        {key: "PLAN_COLOUR", value: optCardMapping.planColour},
                        {key: "LINK_SUFFIX", value: optCardMapping.linkSuffix || ""},
                        {key: "CARD_CLASS", value: optCardMapping.cardClass || ""},
                        {key: "TITLE_SUFFIX", value: optCardMapping.cardSubtitle || "data"},
                        {key: "FOOTER", value: optCardMapping.cardFooter || ""}
                    ])
                } else {
                    return null
                }
            })

            const filteredSimOnlyPlanCards = limitedSimOnlyPlanCards.flatMap((opt) => opt ? [opt] : [])

            return <React.Fragment>{filteredSimOnlyPlanCards}</React.Fragment>
        } else {
            return null
        }
    }

    const optPlanDetailsPage = optHtmlContent(props.cmsContent, "sp-page-plan-details")
    const optPlanToCardMapping = props.cmsContent.find((content) => content.reference === "sales-portal-plan-card-mapping")

    if (!optPlanDetailsPage || !optPlanToCardMapping) {
        return <Navigate to={SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext)}/>
    } else {
        const cardMappingOptions = optPlanToCardMapping.jsonContent as CmsPlanCardMappingSchema
        const optCardMapping = cardMappingOptions.otherPlansMapping
            .find((planMapping) => planMapping.planCode === props.simOnlyPlan.packageCode || planMapping.default)

        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {renderDynamicContent(optPlanDetailsPage, [
                    {key: "DATA_ALLOWANCE", value: optCardMapping?.overridePlanDataAllowance || simOnlyPlanDataLimit(props.simOnlyPlan)},
                    {key: "OTHER_SIM_ONLY_PLANS", value: <OtherSimOnlyPlans/>},
                    {key: "SIM_ONLY_PLAN", value: <SelectedPlanDetails/>},
                    {key: "PLAN_COLOUR", value: optCardMapping?.planColour},
                    {key: "LINK_SUFFIX", value: optCardMapping?.linkSuffix || ""},
                    {key: "CARD_CLASS", value: optCardMapping?.cardClass || ""},
                    {key: "TITLE_SUFFIX", value: optCardMapping?.cardSubtitle || " DATA "},
                    {key: "FOOTER", value: optCardMapping?.cardSubtitle || ""}
                ])}
            </HeaderFooterWrapper>
        )
    }

}


export default SuperdrugSimOnlyPlanDetailsPage