import * as React from "react"
import {ApplicationContext} from "../../ApplicationContext";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import SueprdrugLogoutPage from "./SueprdrugLogoutPage";

/**
 * Function will log out the authenticated user.
 */
const LogoutPage = (): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return <SueprdrugLogoutPage/>
    } else {
        return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
    }

}

export default LogoutPage
