import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import StandardSimInHandCapturePage from "./StandardSimInHandCapturePage";
import SuperdrugSimInHandCapturePage from "./SuperdrugSimInHandCapturePage";

interface SimInHandCapturePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the SIM-in-hand capture page which will capture the customers SIM information before
 * triggering the SIM-in-hand journey.
 */
const SimInHandCapturePage = (props: SimInHandCapturePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugSimInHandCapturePage cmsContent={props.cmsContent}/>
    } else {
        return <StandardSimInHandCapturePage cmsContent={props.cmsContent}/>
    }

}

export default SimInHandCapturePage