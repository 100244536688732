import * as React from "react"
import {v4 as uuidv4} from 'uuid';

interface TextFormRowProps {

    /**
     * Label for the form field.
     */
    readonly label: string

    /**
     * The type of value this form field will accept.
     */
    readonly type: "text" | "email"

    /**
     * Maximum number of characters that can be included in the form field.
     */
    readonly maxLength?: number

    /**
     * Current value of the form field.
     */
    readonly value: string | undefined

    /**
     * When present, this string will be displayed as an error for the form field.
     */
    readonly error: string | undefined

    /**
     * Callback function to change the state related to this form field.
     */
    readonly setValue: (s: string | undefined) => void

}

/**
 * Function will render a form row that will capture text-based values.
 */
const TextFormRow = (props: TextFormRowProps): JSX.Element => {
    const id = uuidv4()
    const errId = uuidv4()

    return (
        <div className="form-group">
            <label htmlFor={id}>{props.label}</label>

            <input id={id} type={props.type} autoComplete={"true"} aria-describedby={props.error && errId} maxLength={props.maxLength}
                   className={"form-control form-control-lg" + (props.error !== undefined ? " form-control-error" : "")}
                   value={props.value} onChange={(e) => props.setValue(e.target.value)}/>

            {props.error && <small id={errId} className="sp-text-error">
                {props.error}
            </small>}
        </div>
    )
}

export default TextFormRow