import * as React from "react"
import {
    CmsFormFieldModalSchema,
    CmsFormFieldSchema,
    CmsFormSchema,
    CmsFormSectionSchema,
    CmsFormStyleSchema
} from "../../Model/CmsContent/CmsFormSchema";
import {randomAlphaString} from "../../Utilities/StringUtilities";
import DOMPurify from "dompurify";

interface CmsFormFieldModalProps {

    /**
     * Unique ID for this modal.
     */
    readonly id: string

    /**
     * Defines what this modal will contain.
     */
    readonly modal: CmsFormFieldModalSchema

}

/**
 * Function will render a dynamic popover next to a form field.
 */
const CmsFormFieldModal = (props: CmsFormFieldModalProps): JSX.Element =>
    <div className="modal fade" id={props.id}>
        <div className="modal-dialog">
            <div className="sp-modal sp-modal-white sp-modal-elevated">
                <div className="modal-header">
                    {props.modal.title && <div className="sp-modal-text-title">
                        {props.modal.title}
                    </div>}

                    <button className="sp-modal-button-close" type="button" data-bs-dismiss="modal">
                        Close
                    </button>
                </div>

                <div className="modal-body">
                    {props.modal.paragraphs.map((paragraph) =>
                        <div className="sp-model-text-paragraph">
                            {paragraph}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>

interface CmsFormFieldProps {

    /**
     * Unique ID for this form.
     */
    readonly id: string

    /**
     * Defines how this form field should be rendered to the user.
     */
    readonly field: CmsFormFieldSchema

    /**
     * Defines how this form should be styled.
     */
    readonly formStyles: CmsFormStyleSchema


    /**
     * Function to handle the boolean value emitted by the field if configured as such
     */
    readonly onValueEmitted: (value: boolean) => void;

}

/**
 * Function will render a dynamic form field inside a form section.
 */
const CmsFormField = (props: CmsFormFieldProps): JSX.Element => {

    const calculateType = (): React.HTMLInputTypeAttribute => {
        switch (props.field.type) {
            case "string":
                return "text"
            case "int":
                return "number"
            case "double":
                return "number"
            case "date":
                return "date"
            case "boolean":
                return "checkbox"
        }
    }

    const fieldType = calculateType()

    const modalId = props.field.formFieldModal != null ? randomAlphaString(25) : null

    // Prevent XSS attacks on dynamic HTML.
    const sanitizedTitle = DOMPurify.sanitize(props.field.title, {
        WHOLE_DOCUMENT: false,
        ADD_ATTR: ['target']})
    const titleIsHtml = /<\/?[a-z][\s\S]*>/i.test(props.field.title)

    const sanitizedCaption = props.field.caption && DOMPurify.sanitize(props.field.caption, {
        WHOLE_DOCUMENT: false})
    const captionIsHtml = sanitizedCaption && /<\/?[a-z][\s\S]*>/i.test(sanitizedCaption)

    const isDropdownField = props.field.values.length > 0
    const isCheckbox = fieldType === "checkbox"

    const handleCheckboxChange = (e: { target: { checked: boolean; }; }) => {
        if (props.field.preventFormSubmissionFlag === true) {
            props.onValueEmitted(e.target.checked);
        }
    };

    return (
        <React.Fragment>
            <div className={isCheckbox ? "sp-form-field-wrapper-checkbox" : "sp-form-field-wrapper"}>
                {!isDropdownField && isCheckbox && <div className="flex-fill">
                    <input
                        id={`${props.id}:${props.field.field}`}
                        name={props.field.field}
                        type={fieldType}
                        onChange={handleCheckboxChange}
                    />
                </div>}
                <div className="sp-form-field-tooltip-wrapper">
                    {titleIsHtml && <div dangerouslySetInnerHTML={{__html: sanitizedTitle}}/>}

                    {!titleIsHtml && <label htmlFor={`${props.id}:${props.field.field}`}>
                        {sanitizedTitle}
                    </label>}

                    {props.field.formFieldModal && <React.Fragment>
                        <i
                            className={props.field.formFieldModal.iconClass ?? "fa fa-info-circle"}
                            data-bs-toggle="modal"
                            data-bs-target={`#${modalId}`}
                        ></i>

                        <CmsFormFieldModal id={modalId!!} modal={props.field.formFieldModal}/>
                    </React.Fragment>}
                </div>

                {!isDropdownField && !isCheckbox && <input
                    id={`${props.id}:${props.field.field}`}
                    name={props.field.field}
                    type={fieldType}
                    {...(props.field.placeholder && { placeholder: props.field.placeholder })}
                    maxLength={props.field.maxLength !== null ? props.field.maxLength : undefined}
                    minLength={props.field.minLength !== null ? props.field.minLength : undefined}
                />}

                {isDropdownField && <select
                    id={`${props.id}:${props.field.field}`}
                    name={props.field.field}
                >
                    {props.field.values.map((possibleValue) =>
                        <option value={possibleValue.value}>{possibleValue.title}</option>
                    )}
                </select>}

                {!isCheckbox && <div
                    id={`${props.id}:${props.field.field}-error-wrapper`}
                    className="sp-form-field-error-wrapper"
                    style={{display: "none"}}
                >
                    {props.formStyles.errorIconClass && <i
                        style={{marginRight: 5}}
                        className={props.formStyles.errorIconClass}
                    ></i>}

                    <div id={`${props.id}:${props.field.field}-error`} className="sp-form-text-error"></div>
                </div>}


                {captionIsHtml && props.field.caption && <div dangerouslySetInnerHTML={{__html: sanitizedCaption}}/>}

                {!captionIsHtml && props.field.caption && <div className="sp-form-text-caption">
                    {sanitizedCaption}
                </div>}
            </div>

            {isCheckbox && <div
                id={`${props.id}:${props.field.field}-error-wrapper`}
                className="sp-form-field-error-wrapper"
                style={{display: "none"}}
            >
                {props.formStyles.errorIconClass && <i
                    style={{marginRight: 5}}
                    className={props.formStyles.errorIconClass}
                ></i>}

                <div id={`${props.id}:${props.field.field}-error`} className="sp-form-text-error"></div>
            </div>}
        </React.Fragment>
    )

}

interface CmsFormSectionProps {

    /**
     * Unique ID for this form.
     */
    readonly id: string

    /**
     * Defines how this section should be rendered to the user.
     */
    readonly section: CmsFormSectionSchema

    /**
     * Defines how this form should be styled.
     */
    readonly formStyles: CmsFormStyleSchema

}

/**
 * Function will render a dynamic section inside a form.
 */

const CmsFormSection = (props: CmsFormSectionProps): JSX.Element => {

    const [submitBtnActive, setSubmitBtnActive] = React.useState(true);

    React.useEffect(() => {
        props.section.fields.forEach((cmsField) => {
            if (cmsField.type === "boolean" && (cmsField.preventFormSubmissionFlag === true)) {
                setSubmitBtnActive(false);
            }
        })
    }, []);

    return (
        <div className="d-flex flex-column gap-4">
            {(props.section.title || props.section.subtitle || props.section.paragraph.length > 0) && <div>
                {props.section.title && <div className="sp-form-text-subtitle">
                    {props.section.title}
                </div>}

                {props.section.subtitle && <div className="sp-form-text-paragraph">
                    {props.section.subtitle}
                </div>}

                {props.section.paragraph.map((paragraph) => <div className="sp-form-text-paragraph">
                    {paragraph}
                </div>)}
            </div>}

            {props.section.fields.length > 0 && <div className="sp-form-fields">
                {props.section.fields.map((cmsField) =>
                    <CmsFormField id={props.id} field={cmsField} formStyles={props.formStyles}
                                onValueEmitted={setSubmitBtnActive}/>
                )}
            </div>}

            {props.section.submitButtonText && <button type="submit" disabled={!submitBtnActive}
                                                       className={submitBtnActive ? "sp-form-button-submit" : "sp-form-button-submit disabled"}>
                {props.section.submitButtonText}
            </button>}
        </div>
    );
}

interface CmsFormProps {

    /**
     * Unique ID for this form.
     */
    readonly id: string

    /**
     * Details on how this form should be rendered and what is should do.
     */
    readonly form: CmsFormSchema

    /**
     * Defines an optional widget to be included in the signup form.
     */
    readonly widget?: { readonly content: JSX.Element; readonly position: "ABOVE" | "BELOW" }

    /**
     * Callback function will submit the form with information captured from the DOM.
     *
     * @param formData Anonymous object containing key/value pairs for the captured form data.
     */
    readonly submit: (formData: FormData) => void

}

/**
 * Function will render a dynamic, CMS-controlled form to the DOM.
 */
const CmsForm = (props: CmsFormProps): JSX.Element => {

    const mainFormSections = props.form.sections.filter((formSection) => !formSection.seperated)
    const separatedFormSections = props.form.sections.filter((formSection) => formSection.seperated)

    return (
        <form id={props.id} className="sp-form" onSubmit={(event: React.FormEvent) => {
            event.preventDefault();
            props.submit(new FormData(document.getElementById(props.id) as HTMLFormElement));
        }}>
            {props.widget && props.widget.position === "ABOVE" ? props.widget.content : null}

            <div
                className={"sp-form-section-wrapper sp-form-white" + (props.form.style.elevated ? " sp-form-elevated" : "") + (props.form.style.padded ? "" : " p-0 pt-4")}>
                {(props.form.title !== null || props.form.subtitle !== null) && <React.Fragment>
                    {props.form.title !== null && <div className="sp-form-text-title">
                        {props.form.title}
                    </div>}

                    {props.form.subtitle && <div className="sp-form-text-subtitle">
                        {props.form.subtitle}
                    </div>}
                </React.Fragment>}

                {mainFormSections.length > 0 && <React.Fragment>
                    {mainFormSections.map((cmsSection) =>
                        <CmsFormSection id={props.id} section={cmsSection} formStyles={props.form.style}/>
                    )}
                </React.Fragment>}
            </div>

            {separatedFormSections.length > 0 && <div
                className={"sp-form-section-wrapper sp-form-white sp-form-elevated" + (props.form.style.padded ? "" : " p-0")}>
                {separatedFormSections.map((cmsSection) =>
                    <CmsFormSection id={props.id} section={cmsSection} formStyles={props.form.style}/>
                )}
            </div>}

            {props.widget && props.widget.position === "BELOW" ? props.widget.content : null}
        </form>
    )
}

export default CmsForm
