

export interface PromotionCardContent {
    readonly contentType: string
    readonly htmlContentString: string
    readonly organizationId: string
    readonly planCode: string
    readonly promoCode: string
    readonly totalAmount: RmwCurrency
    readonly dataAllowance: number
}

interface RmwCurrency {
    readonly value: bigint
    readonly currency: string
}

export enum ContentType {
    PROMO_STANDARD = "PROMO_STANDARD",
    PROMO_EXPANDED = "PROMO_EXPANDED"
}