import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import SuperdrugSimInHandOrderConfirmationPage from "./SuperdrugSimInHandOrderConfirmationPage";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";

interface SimInHandOrderConfirmationPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the SIM-in-hand confirmation page which will show the customer a brief summary of what
 * they have just signed up to.
 */
const SimInHandOrderConfirmationPage = (props: SimInHandOrderConfirmationPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugSimInHandOrderConfirmationPage cmsContent={props.cmsContent}/>
    } else {
        return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
    }

}

export default SimInHandOrderConfirmationPage