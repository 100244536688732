/**
 * Regex expression will match against valid UK based mobile numbers that begin with either 07 or 44.
 */
export const PATTERN_MOBILE_NUMBER: RegExp = /.*(s*(447[0-9]{9})s*)|(s*(07[0-9]{9})s*)/

/**
 * Regex expression will match against a PAC switching code.
 */
export const PATTERN_PAC: RegExp = /^[A-Z]{3}[0-9]{6}$/

/**
 * Regex expression will match against a STAC switching code.
 */
export const PATTERN_STAC: RegExp = /^[0-9]{6}[A-Z]{3}$/

/**
 * Regex expression will match against a numerical string.
 */
export const PATTERN_NUMERIC: RegExp = /^[0-9]+$/

/**
 * Regex expression will match against an alphabetical string.
 */
export const PATTERN_ALPHABETICAL: RegExp = /^[a-zA-Z]+$/

/**
 * Regex expression will match against an alpha-numeric string.
 */
export const PATTERN_ALPHA_NUMERIC: RegExp = /^[a-zA-Z0-9]+$/

/**
 * Regex expression will match against an alpha-numeric string with spaces.
 */
export const PATTERN_ALPHA_NUMERIC_W_SPACE: RegExp = /^[a-zA-Z0-9 ]+$/

/**
 * Regex expression will match against a UK postcode.
 */
export const PATTERN_POSTCODE: RegExp = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/