import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import SuperdrugOrderSummaryCard from "../../Components/Superdrug/Card/SuperdrugOrderSummaryCard";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate} from "react-router-dom";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import CmsStaticHtmlContent from "../../Components/CmsStaticHtmlContent/CmsStaticHtmlContent";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import {isOrderConfirmation, OrderConfirmation} from "../../Model/Sales/OrderConfirmation";
import {readLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {UserDetails} from "../../Model/User/UserDetails";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";

interface SuperdrugSimInHandOrderConfirmationPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific SIM-in-hand confirmation page which will show the customer a brief
 * summary of what they have just signed up to.
 */
const SuperdrugSimInHandOrderConfirmationPage = (props: SuperdrugSimInHandOrderConfirmationPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const [componentData, setComponentData] = React.useState<{
        readonly orderConfirmation: OrderConfirmation
        readonly selectedPlan: SimOnlyPlan
        readonly selectedPlanPriceWithPromo: PlanPrice | null
        readonly userDetails: UserDetails
        readonly paidInStorePlan: SimOnlyPlan
        readonly paidInStorePriceWithPromo: PlanPrice | null
    }>()

    /**
     * Function will render a card with some text explaining that we are getting their SIM card ready.
     */
    const GettingYourSimReadyCard = (): JSX.Element | null => {
        const optGettingYouRSimReadyCard = optHtmlContent(props.cmsContent, "sp-card-getting-your-sim-ready")

        if (optGettingYouRSimReadyCard && componentData) {
            return renderDynamicContent(optGettingYouRSimReadyCard, [
                {key: "EMAIL_ADDRESS", value: componentData.orderConfirmation.email}
            ])
        } else {
            return null
        }
    }

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const orderConfirmation = readLocalStorageItem("order-confirmation", isOrderConfirmation)
        if (!orderConfirmation) {
            // Shouldn't happen, but if it does, send user to self-care instead of showing nothing.
            window.location.replace(applicationContext.selfcareWebappPublicUrl!!)
            return
        }

        const maybePlan = await fetchSimOnlyPlanByCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, orderConfirmation.packageCode)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        let inStorePlan: SimOnlyPlan
        if (applicationContext.appConfig.signupConfiguration.defaultPlan === orderConfirmation.packageCode) {
            inStorePlan = maybePlan
        } else {
            const maybeInStorePlan = await fetchSimOnlyPlanByCode(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!)
            if (isRequestError(maybeInStorePlan)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            inStorePlan = maybeInStorePlan
        }

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, orderConfirmation.packageCode, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        let inStorePriceWithPromo: PlanPrice | null
        if (optPromoCode) {
            if (planPriceWithPromo && applicationContext.appConfig.signupConfiguration.defaultPlan === orderConfirmation.packageCode) {
                inStorePriceWithPromo = planPriceWithPromo
            } else {
                const maybeInStorePlanPrice = await fetchPriceForPlanWithPromo(
                    applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!, optPromoCode)
                if (isRequestError(maybeInStorePlanPrice)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                    return
                }
                inStorePriceWithPromo = maybeInStorePlanPrice
            }
        } else {
            inStorePriceWithPromo = null
        }

        setComponentData({
            selectedPlan: maybePlan,
            selectedPlanPriceWithPromo: planPriceWithPromo,
            userDetails: maybeUser,
            paidInStorePlan: inStorePlan,
            paidInStorePriceWithPromo: inStorePriceWithPromo,
            orderConfirmation: orderConfirmation
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [])

    const optOrderConfirmationPage = optHtmlContent(props.cmsContent, "sp-page-sim-in-hand-order-confirmation")

    const setSelectedPlanPriceWithPromo = (planPrice: PlanPrice | null) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlanPriceWithPromo: planPrice
        }))
    }

    const setSelectedPlan = (simOnlyPlan: SimOnlyPlan) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlan: simOnlyPlan
        }))
    }

    if (!componentData || !optOrderConfirmationPage) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="tertiary">
                {renderDynamicContent(optOrderConfirmationPage, [
                    {key: "GETTING_YOUR_SIM_READY", value: <GettingYourSimReadyCard/>},
                    {
                        key: "ORDER_SUMMARY",
                        value: <SuperdrugOrderSummaryCard
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            selectedPlanPriceWithPromo={componentData.selectedPlanPriceWithPromo}
                            paidInStorePlan={componentData.paidInStorePlan}
                            paidInStorePriceWithPromo={componentData.paidInStorePriceWithPromo}
                            allowEdit={false}
                            simInHand={true}
                            setSelectedPlanPriceWithPromo={setSelectedPlanPriceWithPromo}
                            setSelectedPlan={setSelectedPlan}
                            componentData={componentData}
                        />
                    },
                    {
                        key: "HEALTH_AND_BEAUTY_CARD",
                        value: <CmsStaticHtmlContent
                            cmsContent={props.cmsContent}
                            reference="sp-card-health-and-beauty"
                        />
                    },
                    {key: "DASHBOARD_URL", value: applicationContext.selfcareWebappPublicUrl}
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimInHandOrderConfirmationPage

