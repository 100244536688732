import {isNumber, isOptNumber, isOptString, isString} from "../../Utilities/TypeGuardUtilities";

export interface PlanPrice {

    /**
     * Defines the plan code that this price is for.
     */
    readonly planCode: string

    /**
     * Defines an optional promo code that has been applied to the returned price to pay.
     */
    readonly promoCode: string | null

    /**
     * Defines an optional human-friendly promotion name.
     */
    readonly promoDescription: string | null

    /**
     * Defines the percentage that will be applied with the returned promo.
     */
    readonly promoPercentageDiscount: number | null

    /**
     * Defines the amount that will be taken from the plan price.
     */
    readonly promoFixedDiscount: number | null

    /**
     * Defines what the new price of the package will be.
     */
    readonly promoPriceOverride: number | null

    /**
     * Defines the amount that this plan code has been reduced by.
     */
    readonly promoDiscount: number | null

    /**
     * The total amount that is to be paid by the customer with the selected plan/promo combination.
     */
    readonly priceToPay: number

    /**
     * Human friendly name of addon that this promo applies
     */
    readonly promoAddon: string | null

}

/**
 * Type-guard function will check if the given instance of any is of type PlanPrice.
 */
export const isPlanPrice = (a: any): a is PlanPrice =>
    isString(a, "planCode") &&
    isOptString(a, "promoCode") &&
    isOptString(a, "promoDescription") &&
    isOptNumber(a, "promoPercentageDiscount") &&
    isOptNumber(a, "promoFixedDiscount") &&
    isOptNumber(a, "promoPriceOverride") &&
    isOptNumber(a, "promoDiscount") &&
    isNumber(a, "priceToPay")
