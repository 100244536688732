import * as React from "react"
import {v4 as uuidv4} from 'uuid';

interface CheckboxFormRowProps {

    /**
     * Label for the form field.
     */
    readonly label: string

    /**
     * Current value of the form field.
     */
    readonly value: boolean

    /**
     * Callback function to change the state related to this form field.
     */
    readonly setValue: (s: boolean) => void

}

/**
 * Function will render a form row that will capture a true/false value.
 */
const CheckboxFormRow = (props: CheckboxFormRowProps): JSX.Element => {
    const id = uuidv4()

    return (
        <div className="form-check">
            <input id={id} autoComplete={"true"} className="form-check-input" type="checkbox" value="" checked={props.value}
                   onChange={(e) => props.setValue(e.target.checked)}/>
            <label className="form-check-label" htmlFor={id}>{props.label}</label>
        </div>
    )
}

export default CheckboxFormRow