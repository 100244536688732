export interface CurrencyConfiguration {

    /**
     * Currency code to determine how currency values are rendered to the user.
     */
    readonly currencyCode: string

    /**
     * The number of decimal places that will be used when rendering currency values.
     */
    readonly decimalPlaces: number

}

/**
 * Function will take a value and display it as an appropriate currency string that can be rendered on the
 * front end.
 */
export const currencyPrettyPrint = (value: number, config: CurrencyConfiguration): string => {
    switch (config.currencyCode) {
        case "EUR":
            return "€" + value.toFixed(config.decimalPlaces)
        case "USD":
            return "$" + value.toFixed(config.decimalPlaces)
        default:
            return "£" + value.toFixed(config.decimalPlaces)
    }
}
