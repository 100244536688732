import * as React from "react"
import {ApplicationContext} from "../../ApplicationContext";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import SuperdrugSimInHandPlanPurchasePage from "./SuperdrugSimInHandPlanPurchasePage";
import {CmsContent} from "../../Model/CmsContent/CmsContent";

interface SimInHandPlanPurchasePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the sim-in-hand purchase page which will sign up a user with a physical SIM.
 */
const SimInHandPlanPurchasePage = (props: SimInHandPlanPurchasePageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugSimInHandPlanPurchasePage cmsContent={props.cmsContent}/>
    } else {
        return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
    }
}

export default SimInHandPlanPurchasePage