import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import * as React from "react";
import {ApplicationContext} from "../../ApplicationContext";
import SuperdrugSimOnlyOtherPlansSelectionPage from "./SuperdrugSimOnlyOtherPlansSelectionPage";

interface SimOnlyOtherPlansSelectionPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * List of SIM-only plans that could be selected by the customer.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the SIM-only other plans selection page. This page will allow a customer to select a
 * different plan to the one that has already been selected before proceeding to the signup page.
 */
const SimOnlyOtherPlansSelectionPage = (props: SimOnlyOtherPlansSelectionPageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugSimOnlyOtherPlansSelectionPage
            cmsContent={props.cmsContent}
            simOnlyPlans={props.simOnlyPlans}
        />
    } else {
        return null
    }
}

export default SimOnlyOtherPlansSelectionPage
