import * as React from "react"
import {SimOnlyPlan} from "../../../Model/Sales/SimOnlyPlan";
import {optHtmlContent, renderDynamicContent} from "../../../Utilities/CmsUtilities";
import {currencyPrettyPrint} from "../../../Model/Configuration/CurrencyConfiguration";
import {CmsContent} from "../../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../../ApplicationContext";
import {PlanPrice} from "../../../Model/Sales/PlanPrice";

interface SuperdrugSelectedPlanDetailsCardProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Plan information to be displayed in the card.
     */
    readonly plan: SimOnlyPlan

    /**
     * Information on how much the selected plan costs.
     */
    readonly priceWithPromo: PlanPrice | null

}

/**
 * Function will render the selected plan details card that can be found on pages after the user has authenticated
 * and selected a plan.
 */
const SuperdrugSelectedPlanDetailsCard = (props: SuperdrugSelectedPlanDetailsCardProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    if (props.priceWithPromo) {
        const optSelectedPlanDetailsCard = optHtmlContent(props.cmsContent, "sp-card-selected-plan-details-promo")

        if (optSelectedPlanDetailsCard) {
            let discountString
            if (props.priceWithPromo.promoPercentageDiscount) {
                discountString = `-${props.priceWithPromo.promoPercentageDiscount}%`
            } else if (props.priceWithPromo.promoFixedDiscount) {
                discountString = `-£${props.priceWithPromo.promoFixedDiscount.toFixed(2)}`
            } else if (props.priceWithPromo.promoPriceOverride) {
                discountString = `-£${(props.plan.monthlyPrice - props.priceWithPromo.promoPriceOverride).toFixed(2)}`
            } else {
                discountString = "" // Shouldn't be possible to get here
            }

            return renderDynamicContent(optSelectedPlanDetailsCard, [
                {key: "PLAN_DESCRIPTION", value: props.plan.description},
                {key: "DISCOUNTED_PRICE", value: `£${props.priceWithPromo.priceToPay.toFixed(2)}`},
                {
                    key: "PLAN_PRICE",
                    value: currencyPrettyPrint(props.plan.monthlyPrice, applicationContext.appConfig.currencyConfiguration)
                },
                {key: "PROMO_DESCRIPTION", value: props.priceWithPromo.promoDescription},
                {key: "PROMO_DISCOUNT", value: discountString}
            ])
        } else {
            return null
        }
    } else {
        const optSelectedPlanDetailsCard = optHtmlContent(props.cmsContent, "sp-card-selected-plan-details")

        if (optSelectedPlanDetailsCard) {
            return renderDynamicContent(optSelectedPlanDetailsCard, [
                {key: "PLAN_DESCRIPTION", value: props.plan.description},
                {
                    key: "PLAN_PRICE",
                    value: currencyPrettyPrint(props.plan.monthlyPrice, applicationContext.appConfig.currencyConfiguration)
                }
            ])
        } else {
            return null
        }
    }
}

export default SuperdrugSelectedPlanDetailsCard