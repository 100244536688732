import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";

/**
 * Function will render a generic internal error page. This will typically be displayed if the CMS-controlled
 * error page cannot be retrieved for whatever reason.
 */
const GenericErrorPage = (): JSX.Element =>
    <div style={{textAlign: "center"}}>
        <h3 style={{fontSize: "2em", marginTop: "2rem"}}>Internal Server Error</h3>

        <h1 style={{fontSize: "5em", marginTop: "2rem", marginBottom: "2rem"}}>500</h1>

        <p style={{fontSize: "1.5em", lineHeight: ".5em"}}>
            The server encountered an internal error or misconfiguration
        </p>
        <p style={{fontSize: "1.5em", lineHeight: ".5em"}}>
            and was unable to complete your request.
        </p>
    </div>

interface InternalErrorPageProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the internal error page. This page indicates something has gone wrong, and we are unable to
 * recover.
 */
const InternalErrorPage = (props: InternalErrorPageProps): JSX.Element => {

    const optSimOnlyPlansPane = optHtmlContent(props.cmsContent, "sp-page-internal-error")

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (optSimOnlyPlansPane) {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="secondary">
                {renderDynamicContent(optSimOnlyPlansPane, [])}
            </HeaderFooterWrapper>
        )
    } else { // Fallback to generic error page.
        return <GenericErrorPage/>
    }

}

export default InternalErrorPage