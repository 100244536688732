import {CmsFormFieldErrorMap} from "../Model/CmsContent/CmsFormSchema";

/**
 * Utility function will toggle an error message under the given form field.
 *
 * @param formFieldErrorMap Array of LCS-specific -> Reseller-specified error message mappings.
 * @param formId ID of the form that the target input belongs to.
 * @param fieldName Name of the field we are targeting.
 * @param error Error that will be displayed. When undefined, the function will clear any errors that may be rendered.
 */
export const toggleInputError = (
    formFieldErrorMap: CmsFormFieldErrorMap[],
    formId: string,
    fieldName: string,
    error: string | undefined
): void => {
    const optWrapperElement = document.getElementById(`${formId}:${fieldName}-error-wrapper`)
    const optTextElement = document.getElementById(`${formId}:${fieldName}-error`)

    if (optWrapperElement && optTextElement) {
        if (error !== undefined) {
            const maybeErrorMessageOverride =
                formFieldErrorMap.find((map) => map.original === error)

            optTextElement.innerText = maybeErrorMessageOverride?.override ?? error
            optWrapperElement.style.display = "flex"
        } else {
            optWrapperElement.style.display = "none"
            optTextElement.innerText = ""
        }
    }
}

/**
 * Utility function will apply an error message under the given form field if it is provided. When undefined,
 * the form field will not be changed regardless of its current state.
 *
 * @param formFieldErrorMap Array of LCS-specific -> Reseller-specified error message mappings.
 * @param formId ID of the form that the target input belongs to.
 * @param fieldName Name of the field we are targeting.
 * @param error Error that will be displayed. When undefined, the function will do nothing.
 */
export const applyInputError = (
    formFieldErrorMap: CmsFormFieldErrorMap[],
    formId: string,
    fieldName: string,
    error: string | undefined
): void => {
    const optWrapperElement = document.getElementById(`${formId}:${fieldName}-error-wrapper`)
    const optTextElement = document.getElementById(`${formId}:${fieldName}-error`)

    if (optWrapperElement && optTextElement) {
        if (error !== undefined) {
            const maybeErrorMessageOverride =
                formFieldErrorMap.find((map) => map.original === error)

            optTextElement.innerText = maybeErrorMessageOverride?.override ?? error
            optWrapperElement.style.display = "flex"
        }
    }
}
