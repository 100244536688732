import axios from "axios"
import {isRequestError, RequestError} from "../../Model/RequestError";
import {OAuthToken} from "../../Model/Authentication/OAuthToken";
import {acceptHeader, executeWithErrorResponseHandling, jsonContentTypeHeader} from "../ApiUtilities";
import {saveLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {joinUrl} from "../../Utilities/UriUtilities";
import {ExchangeAuthorizationCode} from "../../Model/Authentication/ExchangeAuthorizationCode";
import {RefreshAccessToken} from "../../Model/Authentication/RefreshAccessToken";

/**
 * Function will attempt to exchange the given authorization code for an access token in the Hybris
 * authentication service.
 *
 * @param salesPortalDomain Base endpoint for the Sales Portal backend.
 * @param request Request body to be used when exchanging the authorization code for an access token.
 */
export const exchangeHybrisAuthorizationCode = async (
    salesPortalDomain: string,
    request: ExchangeAuthorizationCode
): Promise<RequestError | OAuthToken> => {
    const endpoint = joinUrl(salesPortalDomain, "/hybris/exchange-token")
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    const response = await executeWithErrorResponseHandling<OAuthToken>(() =>
        axios.post(endpoint, request, {headers: headers}))

    if (isRequestError(response)) {
        console.error("Failed to communicate with external system", response as RequestError)
        return JSON.parse(JSON.stringify(response)) // Force new object reference.
    } else {
        saveLocalStorageItem("access_token", response.data.accessToken)
        saveLocalStorageItem("refresh_token", response.data.refreshToken)
        saveLocalStorageItem("expires_at", response.data.expiresAt)
        saveLocalStorageItem("id_token", response.data.accessToken)
        saveLocalStorageItem("id_token_expires_at", response.data.expiresAt)
        return response.data
    }
}

/**
 * Function will attempt to refresh the authenticated users access token when necessary.
 *
 * @param salesPortalDomain Base endpoint for the Sales Portal backend.
 * @param request Request body to be used when refreshing the access token.
 */
export const refreshAccessToken = async (
    salesPortalDomain: string,
    request: RefreshAccessToken
): Promise<RequestError | OAuthToken | null> => {
    const endpoint = joinUrl(salesPortalDomain, "/hybris/refresh-token")
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    const response = await executeWithErrorResponseHandling<OAuthToken>(() =>
        axios.post(endpoint, request, {headers: headers}))

    if (isRequestError(response)) {
        console.error("Failed to communicate with external system", response as RequestError)
        return JSON.parse(JSON.stringify(response)) // Force new object reference.
    } else {
        saveLocalStorageItem("access_token", response.data.accessToken)
        saveLocalStorageItem("refresh_token", response.data.refreshToken)
        saveLocalStorageItem("expires_at", response.data.expiresAt)
        saveLocalStorageItem("id_token", response.data.accessToken)
        saveLocalStorageItem("id_token_expires_at", response.data.expiresAt)
        return response.data
    }
}

/**
 * Function will attempt to revoke the given access token.
 *
 * @param salesPortalDomain Base endpoint for the Sales Portal backend.
 * @param request Token to be revoked.
 */
export const revokeAccessToken = async (
    salesPortalDomain: string,
    request: OAuthToken
): Promise<null> => {
    const endpoint = joinUrl(salesPortalDomain, "/hybris/revoke-access")
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    try {
        await axios.post(endpoint, request, {headers: headers})
        return null
    } catch (e) {
        return null
    }
}

/**
 * Function will attempt to revoke the given access token.
 *
 * @param salesPortalDomain Base endpoint for the Sales Portal backend.
 * @param request Token to be revoked.
 */
export const revokeRefreshToken = async (
    salesPortalDomain: string,
    request: OAuthToken
): Promise<null> => {
    const endpoint = joinUrl(salesPortalDomain, "/hybris/revoke-refresh")
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    try {
        await axios.post(endpoint, request, {headers: headers})
        return null
    } catch (e) {
        return null
    }
}
