import {isString} from "../../Utilities/TypeGuardUtilities";

export interface CmsCDN {

    /**
     * Link to the CDN resource
     */
    readonly href: string

    /**
     * Checksum to verify the CDN resource
     */
    readonly integrity: string

    /**
     * Cross-origin policy to be applied to the CDN resource
     */
    readonly crossOrigin: string

}

/**
 * Type-guard function will check if the given instance of any is of type CmsCDN.
 */
export const isCmsCDN = (a: any): a is CmsCDN =>
    isString(a, "href") &&
    isString(a, "integrity") &&
    isString(a, "crossOrigin")
