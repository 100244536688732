import {isBoolean, isNumber, isOptType, isString, isType} from "../../Utilities/TypeGuardUtilities";

export interface UserDetails {
    /**
     * The current age of the authenticated user.
     */
    readonly age: number

    /**
     * The users email address
     */
    readonly email: string

    /**
     * The authenticated users first name.
     */
    readonly forename: string

    /**
     * The authenticated users last name.
     */
    readonly surname: string

    /**
     * The authenticated users date of birth.
     */
    readonly dateOfBirth: string

    /**
     * The users current address.
     */
    readonly address: UserAddress

    /**
     * Hybris-specific details on the user.
     */
    readonly hybris: HybrisSpecificUserDetails | null

}

/**
 * Type-guard function will check if the given instance of any is of type UserDetails.
 */
export const isUserDetails = (a: any): a is UserDetails =>
    isNumber(a, "age") &&
    isString(a, "email") &&
    isString(a, "forename") &&
    isString(a, "surname") &&
    isString(a, "dateOfBirth") &&
    isType(a, "address", isUserAddress) &&
    isOptType(a, "hybris", isHybrisSpecificUserDetails)

interface HybrisSpecificUserDetails {

    /**
     * When true, the authenticated user has an active loyalty card.
     */
    readonly loyalty: boolean

    /**
     * THe authenticated users current loyalty card number.
     */
    readonly cardNumber: string

    /**
     * Unique customer ID as defined in Hybris
     */
    readonly customerId: string

}

/**
 * Type-guard function will check if the given instance of any is of type HybrisSpecificUserDetails.
 */
export const isHybrisSpecificUserDetails = (a: any): a is HybrisSpecificUserDetails =>
    isBoolean(a, "loyalty") &&
    isString(a, "cardNumber") &&
    isString(a, "customerId")

interface UserAddress {

    /**
     * The house number for the authenticated users current address.
     */
    readonly streetNumber: string

    /**
     * The building name for the authenticated users current address.
     */
    readonly building: string

    /**
     * The first line of the authenticated users current address.
     */
    readonly line1: string

    /**
     * The second line of the authenticated users current address.
     */
    readonly line2: string

    /**
     * The city of the authenticated users current address.
     */
    readonly town: string

    /**
     * The postcode of the authenticated users current address.
     */
    readonly postCode: string
}

/**
 * Type-guard function will check if the given instance of any is of type HybrisAddress.
 */
export const isUserAddress = (a: any): a is UserAddress =>
    isString(a, "streetNumber") &&
    isString(a, "building") &&
    isString(a, "line1") &&
    isString(a, "line2") &&
    isString(a, "town") &&
    isString(a, "postCode")
