import * as React from "react";
import {InlinedState} from "../../../Types/InlinedState";
import {ApplicationContext} from "../../../ApplicationContext";
import {Address} from "../../../Model/Sales/Address";
import {searchPostcode} from "../../../Api/Sales/PostcodeSearchApi";
import {isRequestError} from "../../../Model/RequestError";
import TextFormRow from "../../../Components/Form/TextFormRow";
import SelectFormRow from "../../../Components/Form/SelectFormRow";
import LoadingBackdrop from "../../../Components/LoadingBackdrop/LoadingBackdrop";

interface AddressInformationCaptureProps {

    /**
     * First line of the customers delivery address.
     */
    readonly addressLine1: InlinedState<string | undefined>

    /**
     * Validation error for the address line 1 field.
     */
    readonly addressLine1Error: string | undefined

    /**
     * Second line of the customers delivery address
     */
    readonly addressLine2: InlinedState<string | undefined>

    /**
     * Validation error for the address line 2 field.
     */
    readonly addressLine2Error: string | undefined

    /**
     * Third line of the customers delivery address
     */
    readonly addressLine3: InlinedState<string | undefined>

    /**
     * Validation error for the address line 3 field.
     */
    readonly addressLine3Error: string | undefined

    /**
     * City for the customers delivery address
     */
    readonly city: InlinedState<string | undefined>

    /**
     * Validation error for the city field.
     */
    readonly cityError: string | undefined

    /**
     * County for the customers delivery address.
     */
    readonly county: InlinedState<string | undefined>

    /**
     * Validation error for the county field.
     */
    readonly countyError: string | undefined

    /**
     * Postcode for the customers delivery address.
     */
    readonly postcode: InlinedState<string | undefined>

    /**
     * Validation error for the postcode field.
     */
    readonly postcodeError: string | undefined

}

/**
 * Function will render the address section of the SIM-only plan signup form.
 */
const AddressInformationCapture = (props: AddressInformationCaptureProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const [loading, setLoading] = React.useState(false)
    const [postcodeSearch, setPostcodeSearch] = React.useState<string>()
    const [postcodeSearchError, setPostcodeSearchError] = React.useState<string>()
    const [foundAddresses, setFoundAddresses] = React.useState<Address[]>([])
    const [postcodeCheckerCount, setPostcodeCheckerCount] = React.useState<number>(0)

    const signupConfiguration = applicationContext.appConfig.signupConfiguration

    /**
     * Function will handle the address selection event by updating the state of the component.
     */
    const handleAddressSelection = (selected: Address) => {
        props.addressLine1.setValue(selected.line1)
        props.addressLine2.setValue(selected.line2 ?? undefined)
        props.addressLine3.setValue(selected.line3 ?? undefined)
        props.city.setValue(selected.town)
        props.county.setValue(selected.county)
        props.postcode.setValue(selected.postcode1 + selected.postcode2)
    }

    /**
     * Function will search for addresses that belong to the entered postcode. Once found, an additional multi-select
     * will be rendered that the user can use to select their address.
     */
    const handlePostcodeSearch = (e: React.FormEvent) => {
        e.preventDefault()

        const isPostcodeValid = postcodeSearch && postcodeSearch.length > 0
        setPostcodeSearchError(isPostcodeValid ? undefined : "A valid postcode must be provided")

        if (isPostcodeValid) {
            setLoading(true)

            searchPostcode(
                applicationContext.salesPortalApiDomain,
                applicationContext.reseller,
                postcodeSearch
            ).then((maybeAddresses) => {
                if (isRequestError(maybeAddresses)) {
                    setPostcodeSearchError("An address for that postcode could not be found.")
                } else {
                    setFoundAddresses(maybeAddresses)
                }

                setPostcodeCheckerCount(postcodeCheckerCount + 1)
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }

    const canUsePostcodeChecker = signupConfiguration.postcodeCheckerConfig.usesPostcodeChecker
    const withinUsageBounds = signupConfiguration.postcodeCheckerConfig.postcodeCheckerLimit === null ||
        signupConfiguration.postcodeCheckerConfig.postcodeCheckerLimit >= postcodeCheckerCount

    return (
        <div className="d-flex flex-column gap-2">
            <span className="sp-subtitle sp-text-primary text-start m-0">
                Delivery Address
            </span>

            {canUsePostcodeChecker && withinUsageBounds && <React.Fragment>
                <div className="d-flex flex-grid-row align-items-center gap-4">
                    <div className="flex-fill">
                        <TextFormRow
                            label="Postcode Search"
                            type="text"
                            value={postcodeSearch}
                            error={postcodeSearchError}
                            setValue={setPostcodeSearch}
                        />
                    </div>

                    <div>
                        <button className="sp-button" onClick={handlePostcodeSearch}>
                            Find Address
                        </button>
                    </div>
                </div>
            </React.Fragment>}

            {foundAddresses.length > 0 && <React.Fragment>
                <SelectFormRow
                    label="Select your address"
                    options={foundAddresses}
                    optionToString={(address) => address.addressSummary}
                    onSelect={handleAddressSelection}
                />

                <hr/>
            </React.Fragment>}

            <TextFormRow
                label="Address Line 1"
                type="text"
                maxLength={45}
                value={props.addressLine1.value}
                error={props.addressLine1Error}
                setValue={props.addressLine1.setValue}
            />
            <TextFormRow
                label="Address Line 2"
                type="text"
                maxLength={45}
                value={props.addressLine2.value}
                error={props.addressLine2Error}
                setValue={props.addressLine2.setValue}
            />
            <TextFormRow
                label="Address Line 3"
                type="text"
                maxLength={45}
                value={props.addressLine3.value}
                error={props.addressLine3Error}
                setValue={props.addressLine3.setValue}
            />
            <TextFormRow
                label="City"
                type="text"
                maxLength={30}
                value={props.city.value}
                error={props.cityError}
                setValue={props.city.setValue}
            />
            <TextFormRow
                label="County"
                type="text"
                maxLength={30}
                value={props.county.value}
                error={props.countyError}
                setValue={props.county.setValue}
            />
            <TextFormRow
                label="Postcode"
                type="text"
                maxLength={7}
                value={props.postcode.value}
                error={props.postcodeError}
                setValue={(postcode) => props.postcode.setValue(postcode?.replace(/[^A-Z0-9]/ig, ""))}
            />

            <LoadingBackdrop isLoading={loading}/>
        </div>
    )

}

export default AddressInformationCapture