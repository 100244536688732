import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {ApplicationContext} from "../../ApplicationContext";
import StandardNavigationBar from "./StandardNavigationBar";
import SuperdrugNavigationBar from "./SuperdrugNavigationBar";

interface NavigationBarProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

    /**
     * When set, this component will look for an alternative navbar. If found, it will be displayed over the standard
     * navbar. When undefined, the primary navbar will be rendered.
     */
    readonly variant?: "primary" | "secondary" | "tertiary"

}

/**
 * Function will render the applications navigation bar.
 */
const NavigationBar = (props: NavigationBarProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    if (applicationContext.reseller === "superdrug") {
        return <SuperdrugNavigationBar cmsContent={props.cmsContent} variant={props.variant ?? "primary"}/>
    } else {
        return <StandardNavigationBar cmsContent={props.cmsContent}/>
    }

}

export default NavigationBar