import axios from "axios"
import {CreateOrder} from "../../Model/Sales/CreateOrder";
import {OrderSummary} from "../../Model/Sales/OrderSummary";
import {isRequestError, RequestError} from "../../Model/RequestError";
import {acceptHeader, jsonContentTypeHeader, executeWithErrorResponseHandling} from "../ApiUtilities";
import {joinUrl} from "../../Utilities/UriUtilities";
import {Reseller} from "../../Types/Reseller";

/**
 * Function is responsible for constructing a new order.
 */
export const createOrder = async (
    reseller: Reseller,
    domain: string,
    createOrder: CreateOrder
): Promise<OrderSummary | RequestError> => {
    const endpoint = joinUrl(domain, `/${reseller}/order/create`)
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    const response = await executeWithErrorResponseHandling<OrderSummary>(() =>
        axios.post(endpoint, createOrder, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}
