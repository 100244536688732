import {CmsContent} from "../../Model/CmsContent/CmsContent";
import CmsStaticHtmlContent from "../CmsStaticHtmlContent/CmsStaticHtmlContent";

interface StandardNavigationBarProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the standard navbar with content from the CMS.
 */
const StandardNavigationBar = (props: StandardNavigationBarProps): JSX.Element => <CmsStaticHtmlContent
    cmsContent={props.cmsContent}
    reference="sp-navbar"
/>

export default StandardNavigationBar