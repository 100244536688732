import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import * as React from "react";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import CmsIframe from "../../Components/CmsIframe/CmsIframe";

interface TermsAndConditionsPageProps {

    /**
     * Array of all Sales Portal content currently stored in the CMS.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the coverage checker page which will let prospective customer check how good the
 * network is in specific areas.
 */
const TermsAndConditionsPage = (props: TermsAndConditionsPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const optTermsAndConditionsPage = optHtmlContent(props.cmsContent, "sp-page-terms-and-conditions")

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (optTermsAndConditionsPage) {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="primary">
                {renderDynamicContent(optTermsAndConditionsPage, [])}
            </HeaderFooterWrapper>
        )
    } else { // Fallback to generic error page.
        return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
    }

}

export default TermsAndConditionsPage
