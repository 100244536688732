/**
 * Function is responsible for logging mapping failures between raw JSON
 * and their expected structures.
 */
const withMismatchLogging = (matches: boolean, object: any, propertyName: string, expectedType: string): boolean => {
    if (!matches && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
        console.debug("[" + propertyName + "] of type [" + expectedType + "] was expected in:", object)
    }
    return matches
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type string.
 */
export const isString = (object: any, propertyName: string): object is string => {
    const matches = propertyName in object && typeof object[propertyName] === "string"
    return withMismatchLogging(matches, object, propertyName, "string")
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type string | null.
 */
export const isOptString = (object: any, propertyName: string): object is string | null => {
    const matches = propertyName in object && (object[propertyName] === null || typeof object[propertyName] === "string")
    return withMismatchLogging(matches, object, propertyName, "string | null")
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type string[].
 */
export const isStringArray = (object: any, propertyName: string): object is string[] => {
    const matches = propertyName in object && isArrayOf(object[propertyName], (a): a is string => typeof a === "string")
    return withMismatchLogging(matches, object, propertyName, "string[]")
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type number.
 */
export const isNumber = (object: any, propertyName: string): object is number => {
    const matches = propertyName in object && typeof object[propertyName] === "number"
    return withMismatchLogging(matches, object, propertyName, "number")
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type string | null.
 */
export const isOptNumber = (object: any, propertyName: string): object is number | null => {
    const matches = propertyName in object && (object[propertyName] === null || typeof object[propertyName] === "number")
    return withMismatchLogging(matches, object, propertyName, "number | null")
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type boolean.
 */
export const isBoolean = (object: any, propertyName: string): object is boolean => {
    const matches = propertyName in object && typeof object[propertyName] === "boolean"
    return withMismatchLogging(matches, object, propertyName, "boolean")
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type boolean | null.
 */
export const isOptBoolean = (object: any, propertyName: string): object is boolean | null => {
    const matches = propertyName in object && (object[propertyName] === null || typeof object[propertyName] === "boolean")
    return withMismatchLogging(matches, object, propertyName, "boolean | null")
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type A.
 */
export const isType = <A, >(object: any, propertyName: string, isA: (a: any) => a is A): object is A => {
    const matches = propertyName in object && isA(object[propertyName])
    return withMismatchLogging(matches, object, propertyName, isA.toString())
}

/**
 * Utility function is responsible for asserting the given property
 * exists on the given object and is of type A | null.
 */
export const isOptType = <A, >(object: any, propertyName: string, isA: (a: any) => a is A): object is A | null => {
    const matches = propertyName in object && (object[propertyName] === null || isA(object[propertyName]))
    return withMismatchLogging(matches, object, propertyName, isA.toString())
}

/**
 * Utility function is responsible for asserting the given instance of any
 * is of type A[].
 */
export const isArrayOf = <A, >(a: any, isA: (a: any) => a is A): a is A[] =>
    (a !== null && a !== undefined && Array.isArray(a)) && (a.length === 0 || (a as any[]).filter(isA).length === (a as any[]).length)

/**
 * Utility function is responsible for providing a shorthand type check for
 * (a): a is T[] => isArrayOf(a, isT)
 */
export const isArrayOfT = <A, >(object: any, propertyName: string, isA: (a: any) => a is A): object is A[] => {
    const matches = propertyName in object && isArrayOf(object[propertyName], isA)
    return withMismatchLogging(matches, object, propertyName, isA.toString())
}