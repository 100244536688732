import {isBoolean, isString} from "../../Utilities/TypeGuardUtilities";

export interface SimDetails {

    /**
     * ICCID associated with this SIM.
     */
    readonly iccid: string

    /**
     * When true, this SIM can be used in the SIM-in-hand journey.
     */
    readonly usable: boolean

}

/**
 * Type-guard function will check if the given instance of any is of type SimDetails.
 */
export const isSimDetails = (a: any): a is SimDetails =>
    isString(a, "iccid") &&
    isBoolean(a, "usable")
