const KNOWN_RESELLERS = ["demo", "pingu", "ecotalk", "superdrug"] as const

/**
 * Type defines a reseller. A reseller is used to determine how components will behave
 * and how components are shown to the user. For example, components rendered with the
 * demo Reseller will not look the same as those rendered with ecotalk.
 */
export type Reseller = typeof KNOWN_RESELLERS[number]

/**
 * Type-guard function is responsible for asserting the given instance of any
 * is of type Reseller.
 */
export const isReseller = (a: any): a is Reseller => KNOWN_RESELLERS.includes(a)
