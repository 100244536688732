import axios from "axios"
import {CreateInterestedCustomer} from "../../Model/Sales/InterestedCustomer";
import {isRequestError, RequestError} from "../../Model/RequestError";
import {acceptHeader, jsonContentTypeHeader, executeWithErrorResponseHandling} from "../ApiUtilities";
import {joinUrl} from "../../Utilities/UriUtilities";

/**
 * Function is responsible for creating a new interested customer. When successful,
 * this function will return null.
 */
export const createInterestedCustomer = async (
    domain: string,
    interestedCustomer: CreateInterestedCustomer
): Promise<null | RequestError> => {
    const endpoint = joinUrl(domain, "/interested-customer/create")
    const headers = {...acceptHeader(), ...jsonContentTypeHeader()}

    const response = await executeWithErrorResponseHandling<null>(() =>
        axios.post(endpoint, interestedCustomer, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return null
    }
}