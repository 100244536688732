import * as React from "react"
import {SimOnlyPlan, simOnlyPlanDataLimit, simOnlyPlanMonthlyCost} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";

interface StandardSimOnlyPlanDetailsPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * The SIM-only plan that has been selected by the customer.
     */
    readonly simOnlyPlan: SimOnlyPlan

    /**
     * Array of all other SIM-only plans a customer could select.
     */
    readonly otherSimOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the standard SIM-only details page.
 */
const StandardSimOnlyPlanDetailsPage = (props: StandardSimOnlyPlanDetailsPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)

    const optHowSimOnlyPlansWorkPane = optHtmlContent(props.cmsContent, "sp-pane-how-sim-only-plans-work")
    const optSwitchingToUsPane = optHtmlContent(props.cmsContent, "sp-pane-switching-to-us")
    const optFaqsPane = optHtmlContent(props.cmsContent, "sp-pane-faq")

    /**
     * Component will dynamically render the SIM-only details pane by first converting the given SimOnlyPlan into HTML
     * that follows the "sp-card-sim-only-plan-full-no-data" template. Once converted, this HTML will be injected
     * into the "sp-pane-sim-only-details" dynamic component to create the pane.
     */
    const SelectedPlanDetails = (): JSX.Element | null => {
        const optSimOnlyPlanDetailsPane = optHtmlContent(props.cmsContent, "sp-pane-sim-only-details")
        const optSimOnlyDetailsCardNoData = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-full-no-data")

        if (props.simOnlyPlan && optSimOnlyPlanDetailsPane && optSimOnlyDetailsCardNoData) {
            const simOnlyPlanDetailsCard = renderDynamicContent(optSimOnlyDetailsCardNoData, [
                {key: "MONTHLY_PRICE", value: simOnlyPlanMonthlyCost(props.simOnlyPlan).toString()},
                {
                    key: "SIGNUP_LINK",
                    value: SALES_PORTAL_ROUTES.SimOnly.Purchase(applicationContext.urlContext, props.simOnlyPlan.fauxPackageCode)
                }
            ])

            return renderDynamicContent(optSimOnlyPlanDetailsPane, [
                {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(props.simOnlyPlan)},
                {key: "SIM_ONLY_PLAN", value: simOnlyPlanDetailsCard}
            ])
        } else {
            return null
        }
    }

    /**
     * Component will dynamically render the other SIM-only plans pane by first converting the given list of
     * SimOnlyPlans into HTML that follows the "sp-card-sim-only-plan-limited" template. Once converted, this HTML
     * will be injected into the "sp-pane-other-sim-only-plans" dynamic component to create the pane.
     */
    const OtherSimOnlyPlans = (): JSX.Element | null => {
        const optOtherSimOnlyPlansPane = optHtmlContent(props.cmsContent, "sp-pane-other-sim-only-plans")
        const optLimitedSimOnlyPlanCard = optHtmlContent(props.cmsContent, "sp-card-sim-only-plan-limited")

        if (optOtherSimOnlyPlansPane && optLimitedSimOnlyPlanCard) {
            const limitedSimOnlyPlanCards = props.otherSimOnlyPlans.map((simOnlyPlan) =>
                renderDynamicContent(optLimitedSimOnlyPlanCard, [
                    {key: "DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                    {key: "MONTHLY_PRICE", value: simOnlyPlanMonthlyCost(simOnlyPlan).toString()},
                    {
                        key: "VIEW_PLAN_LINK",
                        value: SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.fauxPackageCode)
                    }
                ])
            )

            return renderDynamicContent(optOtherSimOnlyPlansPane, [
                {key: "SIM_ONLY_PLANS", value: limitedSimOnlyPlanCards}
            ])
        } else {
            return null
        }
    }

    return (
        <HeaderFooterWrapper cmsContent={props.cmsContent}>
            <SelectedPlanDetails/>

            <OtherSimOnlyPlans/>

            {optHowSimOnlyPlansWorkPane && renderDynamicContent(optHowSimOnlyPlansWorkPane)}

            {optSwitchingToUsPane && renderDynamicContent(optSwitchingToUsPane)}

            {optFaqsPane && renderDynamicContent(optFaqsPane)}
        </HeaderFooterWrapper>
    )

}

export default StandardSimOnlyPlanDetailsPage