import * as React from "react"
import {SimOnlyPlan, simOnlyPlanDataLimit, simOnlyPlanMonthlyCost} from "../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {ApplicationContext} from "../../ApplicationContext";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {Navigate} from "react-router-dom";
import {CmsPlanCardMappingSchema} from "../../Model/CmsContent/CmsPlanCardMappingSchema";
import {ContentType, PromotionCardContent} from "../../Model/Sales/PromotionCardContent";
import {fetchAvailablePromotionCardContent} from "../../Api/Sales/PromotionsApi";

interface SuperdrugSimOnlyPlansProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * Array of all SIM-only plans provided by the reseller.
     */
    readonly simOnlyPlans: SimOnlyPlan[]

}

/**
 * Function will render the Superdrug-specific SIM-only plans page.
 */
const SuperdrugSimOnlyPlans = (props: SuperdrugSimOnlyPlansProps): JSX.Element => {

    const [promotionCardContentExpanded , setPromotionCardContentExpanded ] = React.useState<PromotionCardContent[]>([])
    const [promotionCardContentLoading, setPromotionCardContentLoading] = React.useState<boolean>(true)

    const applicationContext = React.useContext(ApplicationContext)

    const setupPromotionCardContentExpanded = async () => {
        const promotionCardContent = await fetchAvailablePromotionCardContent(
            applicationContext.salesPortalApiDomain,
            applicationContext.reseller,
            ContentType.PROMO_EXPANDED
        )
        setPromotionCardContentExpanded(promotionCardContent)
        setPromotionCardContentLoading(false)
    }

    React.useEffect(() => {
        setupPromotionCardContentExpanded()
    }, [])
    /**
     * Component will dynamically render the SIM-only plans pane by first converting the given list of SimOnlyPlans
     * into HTML that follows the "sp-card-sim-only-plan-full" template. Once converted, this HTML will be injected
     * into the "sp-pane-sim-only-plans" dynamic component to create the pane.
     */
    const SimOnlyPlans = (): JSX.Element | null => {
        const optPlanToCardMapping = props.cmsContent.find((content) => content.reference === "sales-portal-plan-card-mapping")

        if (optPlanToCardMapping && !promotionCardContentLoading) {
            const cardMappingOptions = optPlanToCardMapping.jsonContent as CmsPlanCardMappingSchema

            const limitedSimOnlyPlanCards = props.simOnlyPlans.map((simOnlyPlan, index) => {
                const optCardMapping = cardMappingOptions.ourPlansCardMapping
                    .find((planMapping) => planMapping.planCode === simOnlyPlan.packageCode || planMapping.default)
                const optCardContent = optCardMapping ? optHtmlContent(props.cmsContent, optCardMapping.cardReference) : undefined

                const optPromoCardContent = promotionCardContentExpanded[0]

                if (optCardMapping && optCardContent) {
                    if (optPromoCardContent && optCardMapping.planCode === optPromoCardContent.planCode) {
                        promotionCardContentExpanded.shift()
                        let listContent;
                        if (optCardMapping.planCode === "SD_Unlimited") {
                            listContent = [
                                `
                                <li class="sp-card-text-paragraph sp-text-bold">
                                   No UK data caps, no speed restrictions
                                </li>
                                `,
                                `
                                <li class="sp-card-text-paragraph sp-text-bold">
                                   Unlimited data, so no rollover needed!
                                </li>
                                `
                            ]
                        } else {
                            listContent =[
                                `
                                <li class="sp-card-text-paragraph">
                                   No speed restrictions
                                </li>
                                `,
                                `
                                <li class="sp-card-text-paragraph">
                                   Unused data rolls over to the next month
                                </li>
                                `
                            ]
                        }

                        const dataAllowance = optPromoCardContent.dataAllowance ? `${optPromoCardContent.dataAllowance / 1048576} GB` : null
                        if (dataAllowance && dataAllowance > simOnlyPlanDataLimit(simOnlyPlan)) {
                            return renderDynamicContent(optPromoCardContent.htmlContentString, [
                                {key: "UNCHANGED_DATA_ALLOWANCE", value: ""},
                                {
                                    key: "OLD_DATA_ALLOWANCE",
                                    value: optCardMapping.overridePlanDataAllowance || simOnlyPlanDataLimit(simOnlyPlan)
                                },
                                {
                                    key: "DATA_ALLOWANCE", value: dataAllowance
                                        || simOnlyPlanDataLimit(simOnlyPlan)
                                },
                                {
                                    key: "MONTHLY_PRICE",
                                    value: optPromoCardContent.totalAmount.value.toString() || simOnlyPlanMonthlyCost(simOnlyPlan).toString()
                                },
                                {
                                    key: "VIEW_PLAN_LINK",
                                    value: SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.fauxPackageCode)
                                },
                                {key: "PLAN_CODE", value: simOnlyPlan.packageCode},
                                {key: "PLAN_COLOUR", value: "blue"},
                                {key: "LIST_ITEM_1", value: listContent[0]},
                                {key: "LIST_ITEM_2", value: listContent[1]},
                                {key: "LIST_ITEM_3", value: `
                                <li class="sp-card-text-paragraph sp-text-bold">
                                   <b>&pound;${optPromoCardContent.totalAmount.value.toString()
                                    || simOnlyPlanMonthlyCost(simOnlyPlan).toString()}  
                                            in Health and Beautycard points when you start using your SIM</b>
                                </li>
                                `}
                            ])
                        }
                        return renderDynamicContent(optPromoCardContent.htmlContentString, [
                            {key: "UNCHANGED_DATA_ALLOWANCE", value: simOnlyPlanDataLimit(simOnlyPlan)},
                            {key: "OLD_DATA_ALLOWANCE", value: ""},
                            {key: "DATA_ALLOWANCE", value: ""},
                            {
                                key: "MONTHLY_PRICE",
                                value: optPromoCardContent.totalAmount.value.toString() || simOnlyPlanMonthlyCost(simOnlyPlan).toString()
                            },
                            {
                                key: "VIEW_PLAN_LINK",
                                value: SALES_PORTAL_ROUTES.SimOnly.Details(applicationContext.urlContext, simOnlyPlan.fauxPackageCode)
                            },
                            {key: "PLAN_CODE", value: simOnlyPlan.packageCode},
                            {key: "PLAN_COLOUR", value: "blue"},
                            {key: "LIST_ITEM_1", value: listContent[0]},
                            {key: "LIST_ITEM_2", value: listContent[1]},
                            {key: "LIST_ITEM_3", value: `
                                <li class="sp-card-text-paragraph sp-text-bold">
                                   <b>&pound;${optPromoCardContent.totalAmount.value.toString()
                                || simOnlyPlanMonthlyCost(simOnlyPlan).toString()}  
                                            in Health and Beautycard points when you start using your SIM</b>
                                </li>
                                `}
                        ])
                    } else {
                        return renderDynamicContent(optCardContent, [
                            {
                                key: "DATA_ALLOWANCE",
                                value: optCardMapping.overridePlanDataAllowance || simOnlyPlanDataLimit(simOnlyPlan)
                            },
                            {
                                key: "MONTHLY_PRICE",
                                value: optCardMapping.overridePlanCost || simOnlyPlanMonthlyCost(simOnlyPlan).toString()
                            },
                            {key: "PLAN_CODE", value: simOnlyPlan.packageCode},
                            {key: "PLAN_COLOUR", value: optCardMapping.planColour},
                            {key: "LINK_SUFFIX", value: optCardMapping.linkSuffix || ""},
                            {key: "CARD_CLASS", value: optCardMapping.cardClass || ""},
                            {key: "TITLE_SUFFIX", value: optCardMapping.cardSubtitle || "data"},
                            {key: "FOOTER", value: optCardMapping.cardFooter || ""}
                        ])
                    }

                } else {
                    return null
                }
            })

            const filteredSimOnlyPlanCards = limitedSimOnlyPlanCards.flatMap((opt) => opt ? [opt] : [])

            return <React.Fragment>{filteredSimOnlyPlanCards}</React.Fragment>
        } else {
            return null
        }
    }

    const optOurPlansPage = optHtmlContent(props.cmsContent, "sp-page-our-plans")

    if (!optOurPlansPage) {
        return <Navigate to={SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext)}/>
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent}>
                {renderDynamicContent(optOurPlansPage, [
                    {key: "SIM_ONLY_PLANS", value: <SimOnlyPlans/>}
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimOnlyPlans