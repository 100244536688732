import * as React from "react"
import {SimOnlyPlan} from "../../../Model/Sales/SimOnlyPlan";
import {CmsContent} from "../../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../../Utilities/CmsUtilities";
import {SALES_PORTAL_ROUTES} from "../../../Routes/SalesPortalRoutes";
import {currencyPrettyPrint} from "../../../Model/Configuration/CurrencyConfiguration";
import {ApplicationContext} from "../../../ApplicationContext";
import {PlanPrice} from "../../../Model/Sales/PlanPrice";
import {UserDetails} from "../../../Model/User/UserDetails";
import PromoCodeComponent from "./PromoCodeComponent";

interface OrderSummaryCardProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * The selected plan that we will be displaying information on.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Optional information on the plan that is purchase in-store. This will only be present for the SIH journey.
     */
    readonly paidInStorePlan: SimOnlyPlan | undefined

    /**
     * When true, this card will allow the user to go back and edit the card.
     */
    readonly allowEdit: boolean

    /**
     * When true, the edit button will link to the SIH plan selection page.
     */
    readonly simInHand: boolean

    readonly setSelectedPlanPriceWithPromo: (planPrice: PlanPrice | null) => void
    readonly setSelectedPlan: (simOnlyPlan: SimOnlyPlan) => void

    readonly componentData: {
        readonly selectedPlan: SimOnlyPlan;
        readonly selectedPlanPriceWithPromo: PlanPrice | null;
        readonly userDetails: UserDetails;
    };

}

/**
 * Function will render an order summary card.
 */
const OrderSummaryCard = (props: OrderSummaryCardProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    let optOrderSummaryCard: string | null
    let editLink: string | null
    let totalPrice: number
    if (props.simInHand && props.paidInStorePlan) {
        optOrderSummaryCard = props.allowEdit ?
          optHtmlContent(props.cmsContent, "sp-card-order-summary-sim-in-hand") :
          optHtmlContent(props.cmsContent, "sp-card-order-summary-sim-in-hand-no-edit")
        editLink = SALES_PORTAL_ROUTES.SimInHand.PlanSelection(applicationContext.urlContext, props.selectedPlan.packageCode)
        totalPrice = props.selectedPlan.monthlyPrice - props.paidInStorePlan.monthlyPrice
    } else {
        optOrderSummaryCard = props.allowEdit ?
            optHtmlContent(props.cmsContent, "sp-card-order-summary") :
            optHtmlContent(props.cmsContent, "sp-card-order-summary-no-edit")
        editLink = SALES_PORTAL_ROUTES.SimOnly.Alternative(applicationContext.urlContext, props.selectedPlan.packageCode)
        totalPrice = props.selectedPlan.monthlyPrice
    }

    if (props.selectedPlan && optOrderSummaryCard) {
        return renderDynamicContent(optOrderSummaryCard, [
            {key: "EDIT_PLAN_LINK", value: editLink},
            {key: "PLAN_DESCRIPTION", value: props.selectedPlan.description},
            {
                key: "PLAN_PRICE",
                value: currencyPrettyPrint(props.selectedPlan.monthlyPrice, applicationContext.appConfig.currencyConfiguration)
            },
            {
                key: "PAID_IN_STORE",
                value: props.paidInStorePlan ? currencyPrettyPrint(props.paidInStorePlan.monthlyPrice, applicationContext.appConfig.currencyConfiguration) : ""
            },
            {
                key: "TOTAL_PRICE",
                value: currencyPrettyPrint(totalPrice, applicationContext.appConfig.currencyConfiguration)
            },
            {
                key: "PROMO_CODE_COMPONENT",
                value: <PromoCodeComponent componentData={props.componentData}
                                           setSelectedPlanPriceWithPromo={props.setSelectedPlanPriceWithPromo}
                                           setSelectedPlan={props.setSelectedPlan}/>
            }
        ])
    } else {
        return null
    }
}

interface OrderSummaryCardWithPromoProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * The selected plan that we will be displaying information on.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Price information for the selected plan.
     */
    readonly selectedPlanPriceWithPromo: PlanPrice

    /**
     * Optional information on the plan that is purchase in-store. This will only be present for the SIH journey.
     */
    readonly paidInStorePlan: SimOnlyPlan | undefined

    /**
     * Price information on the plan that was purchased in store.
     */
    readonly paidInStorePriceWithPromo: PlanPrice | null

    /**
     * When true, this card will allow the user to go back and edit the card.
     */
    readonly allowEdit: boolean

    /**
     * When true, the edit button will link to the SIH plan selection page.
     */
    readonly simInHand: boolean

    readonly setSelectedPlanPriceWithPromo: (planPrice: PlanPrice | null) => void
    readonly setSelectedPlan: (simOnlyPlan: SimOnlyPlan) => void

    readonly componentData: {
        readonly selectedPlan: SimOnlyPlan;
        readonly selectedPlanPriceWithPromo: PlanPrice | null;
        readonly userDetails: UserDetails;
    };

}

/**
 * Function will render an order summary card that includes an applied promotion.
 */
const OrderSummaryCardWithPromo = (props: OrderSummaryCardWithPromoProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)

    let optOrderSummaryCard: string | null
    let editLink: string | null
    let totalPrice: number
    if (props.simInHand && props.paidInStorePlan) {
        optOrderSummaryCard = props.allowEdit ?
          optHtmlContent(props.cmsContent, "sp-card-order-summary-sim-in-hand-promo") :
          optHtmlContent(props.cmsContent, "sp-card-order-summary-sim-in-hand-promo-no-edit")
        editLink = SALES_PORTAL_ROUTES.SimInHand.PlanSelection(applicationContext.urlContext, props.selectedPlan.packageCode)
        if (props.selectedPlanPriceWithPromo) {
            totalPrice = props.selectedPlanPriceWithPromo.priceToPay - (props.paidInStorePriceWithPromo?.priceToPay || 0.0);
            if (props.paidInStorePriceWithPromo === null){
                totalPrice = totalPrice - props.paidInStorePlan.monthlyPrice
                if (totalPrice < 0){
                    totalPrice = 0.0
                }
            }
        } else {
            totalPrice = props.selectedPlan.monthlyPrice - props.paidInStorePlan.monthlyPrice
        }
    } else {
        optOrderSummaryCard = props.allowEdit ?
            optHtmlContent(props.cmsContent, "sp-card-order-summary-promo") :
            optHtmlContent(props.cmsContent, "sp-card-order-summary-promo-no-edit")
        editLink = SALES_PORTAL_ROUTES.SimOnly.Alternative(applicationContext.urlContext, props.selectedPlan.packageCode)
        if (props.selectedPlanPriceWithPromo) {
            totalPrice = props.selectedPlanPriceWithPromo.priceToPay
        } else {
            totalPrice = props.selectedPlan.monthlyPrice
        }
    }

    if (props.selectedPlan && optOrderSummaryCard) {
        let discountString
        if (props.selectedPlanPriceWithPromo.promoPercentageDiscount) {
            discountString = `-${props.selectedPlanPriceWithPromo.promoPercentageDiscount}%`
        } else if (props.selectedPlanPriceWithPromo.promoFixedDiscount) {
            discountString = `-£${props.selectedPlanPriceWithPromo.promoFixedDiscount.toFixed(2)}`
        } else if (props.selectedPlanPriceWithPromo.promoPriceOverride) {
            discountString = `-£${(props.selectedPlan.monthlyPrice - props.selectedPlanPriceWithPromo.promoPriceOverride).toFixed(2)}`
        } else if (props.selectedPlanPriceWithPromo.promoAddon){
            discountString = `Extra ${props.selectedPlanPriceWithPromo.promoAddon}`
        } else {
            discountString = "" // Shouldn't be possible to get here
        }

        const planPrice = props.simInHand && props.selectedPlanPriceWithPromo?.priceToPay ?
            props.selectedPlanPriceWithPromo?.priceToPay :
            props.selectedPlan?.monthlyPrice
        const paidInStorePrice = props.paidInStorePriceWithPromo?.priceToPay ?? props.paidInStorePlan?.monthlyPrice

        return renderDynamicContent(optOrderSummaryCard, [
            {key: "EDIT_PLAN_LINK", value: editLink},
            {key: "PLAN_DESCRIPTION", value: props.selectedPlan.description},
            {
                key: "PLAN_PRICE",
                value: currencyPrettyPrint(planPrice, applicationContext.appConfig.currencyConfiguration)
            },
            {
                key: "PAID_IN_STORE",
                value: paidInStorePrice ? currencyPrettyPrint(paidInStorePrice, applicationContext.appConfig.currencyConfiguration) : ""
            },
            {key: "PROMO_DESCRIPTION", value: props.selectedPlanPriceWithPromo.promoDescription},
            {key: "PROMO_DISCOUNT", value: discountString},
            {
                key: "TOTAL_PRICE",
                value: currencyPrettyPrint(totalPrice, applicationContext.appConfig.currencyConfiguration)
            },
            {
                key: "PROMO_CODE_COMPONENT",
                value: <PromoCodeComponent componentData={props.componentData}
                                           setSelectedPlanPriceWithPromo={props.setSelectedPlanPriceWithPromo}
                                           setSelectedPlan={props.setSelectedPlan}/>
            }
        ])
    } else {
        return null
    }
}

interface SuperdrugOrderSummaryCardProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * The selected plan that we will be displaying information on.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Price information for the selected plan.
     */
    readonly selectedPlanPriceWithPromo: PlanPrice | null

    /**
     * Optional information on the plan that is purchase in-store. This will only be present for the SIH journey.
     */
    readonly paidInStorePlan: SimOnlyPlan | undefined

    /**
     * Price information on the plan that was purchased in store.
     */
    readonly paidInStorePriceWithPromo: PlanPrice | null

    /**
     * When true, this card will allow the user to go back and edit the card.
     */
    readonly allowEdit: boolean

    /**
     * When true, the edit button will link to the SIH plan selection page.
     */
    readonly simInHand: boolean

    readonly setSelectedPlanPriceWithPromo: (planPrice: PlanPrice | null) => void
    readonly setSelectedPlan: (simOnlyPlan: SimOnlyPlan) => void

    readonly componentData: {
        readonly selectedPlan: SimOnlyPlan;
        readonly selectedPlanPriceWithPromo: PlanPrice | null;
        readonly userDetails: UserDetails;
    };
}

/**
 * Function will render a card summarising what the customer needs to/has paid during the signup journey.
 */
const SuperdrugOrderSummaryCard = (props: SuperdrugOrderSummaryCardProps): JSX.Element | null => {
    if (props.selectedPlanPriceWithPromo === null) {
        return <OrderSummaryCard {...props}/>
    } else {
        return <OrderSummaryCardWithPromo
            cmsContent={props.cmsContent}
            selectedPlan={props.selectedPlan}
            selectedPlanPriceWithPromo={props.selectedPlanPriceWithPromo}
            paidInStorePlan={props.paidInStorePlan}
            paidInStorePriceWithPromo={props.paidInStorePriceWithPromo}
            allowEdit={props.allowEdit}
            simInHand={props.simInHand}
            componentData={props.componentData}
            setSelectedPlanPriceWithPromo={props.setSelectedPlanPriceWithPromo}
            setSelectedPlan={props.setSelectedPlan}
        />
    }
}

export default SuperdrugOrderSummaryCard
