import {AxiosError, AxiosResponse} from "axios";
import {
    ConflictError,
    ForbiddenRequestError,
    NotFoundRequestError,
    RequestError,
    UnauthorizedRequestError,
    UnknownRequestError,
    ValidationRequestError
} from "../Model/RequestError";

/**
 * Utility function is responsible for creating a header to be used in HTTP requests.
 */
const createHeader = (name: string, value: string): object => ({[name]: value})

/**
 * Utility function is responsible for creating an Accept HTTP header.
 */
export const acceptHeader = (): object => createHeader("Accept", "application/json")

/**
 * Utility function is responsible for creating a Content-Type HTTP header.
 */
export const jsonContentTypeHeader = (): object => createHeader("Content-Type", "application/json")

/**
 * Utility function is responsible for creating a multipart/form-data Content-Type HTTP header.
 */
export const multipartHeader = (): object => createHeader("Content-Type", "multipart/form-data")

/**
 * Utility function will construct a application/x-www-form-urlencoded content-type header.
 */
export const urlFormEncodedHeader = (): object => createHeader("Content-Type", "application/x-www-form-urlencoded")

/**
 * Utility function is responsible for creating an Authorization HTTP header.
 */
export const authenticationHeader = (token: string): object => createHeader("Authorization", "Bearer " + token)

/**
 * Higher order function is responsible for making the given API call and
 * converting any error codes into an appropriate instance of RequestError.
 * If the API fails with a retryable 5xx error, a retry will be attempted.
 */
export const executeWithErrorResponseHandling = async <A, >(
    f: () => Promise<AxiosResponse<A>>,
    retryCount: number = 0
): Promise<AxiosResponse<A> | RequestError> => {
    try {
        const currentWaitTime = retryCount * 500
        await new Promise(resolve => setTimeout(resolve, currentWaitTime));

        return await f()
    } catch (e) {
        const asAxiosError = (e as AxiosError)

        if (asAxiosError.response) {
            if (asAxiosError.response.status.toString().startsWith("5")) {
                if (retryCount <= 4) {
                    switch (asAxiosError.response.status) {
                        case 502:
                            return executeWithErrorResponseHandling(f, retryCount + 1)
                        case 503:
                            return executeWithErrorResponseHandling(f, retryCount + 1)
                        case 504:
                            return executeWithErrorResponseHandling(f, retryCount + 1)
                        default:
                            return UnknownRequestError
                    }
                } else {
                    return UnknownRequestError // Retries have failed, handle the error
                }
            } else if (asAxiosError.response.status.toString().startsWith("4")) {
                switch (asAxiosError.response.status) {
                    case 400:
                        return ValidationRequestError
                    case 401:
                        return UnauthorizedRequestError
                    case 403:
                        return ForbiddenRequestError
                    case 404:
                        return NotFoundRequestError
                    case 405:
                        return UnknownRequestError // Programmer error
                    case 409:
                        return ConflictError
                    default:
                        return UnknownRequestError
                }
            } else {
                return UnknownRequestError
            }
        } else {
            return UnknownRequestError
        }
    }
}
