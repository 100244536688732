import * as React from "react"
import {ApplicationContext} from "../../../ApplicationContext";
import TextFormRow from "../../../Components/Form/TextFormRow";
import {fetchSimDetails} from "../../../Api/Sales/SimApi";
import {isRequestError} from "../../../Model/RequestError";
import {SALES_PORTAL_ROUTES} from "../../../Routes/SalesPortalRoutes";
import {useNavigate} from "react-router-dom";
import LoadingBackdrop from "../../../Components/LoadingBackdrop/LoadingBackdrop";

/**
 * Function will render the SIM-in hand capture form which will let a customer trigger the SIM-in hand journey.
 */
const SimInHandCaptureForm = (): JSX.Element => {

    const navigate = useNavigate()
    const applicationContext = React.useContext(ApplicationContext)

    const [loading, setLoading] = React.useState<boolean>(false)
    const [iccid, setIccid] = React.useState<string>()
    const [iccidError, setIccidError] = React.useState<string>()

    /**
     * Function will validate the ICCID and trigger the SIM-in-hand journey.
     */
    const submitForm = (e: React.FormEvent) => {
        e.preventDefault()

        const isIccidValid = iccid !== undefined && iccid.length >= 18 && iccid.length <= 22
        setIccidError(isIccidValid ? undefined : "A valid SIM number must be provided")

        if (isIccidValid) {
            fetchSimDetails(applicationContext.salesPortalApiDomain, applicationContext.reseller, {iccid: iccid}).then((maybeVerification) => {
                if (isRequestError(maybeVerification)) {
                    setIccidError("Failed to determine the validity of the given SIM number. Please try again later")
                } else if (maybeVerification.usable) {
                    navigate(SALES_PORTAL_ROUTES.SimInHand.PlanSelection(applicationContext.urlContext, maybeVerification.iccid))
                } else {
                    setIccidError("That SIM number either doesn't exist or cannot be used")
                }
                setLoading(false)
            })
        } else {
            window.scrollTo(0, 0)
        }
    }

    return (
        <form onSubmit={submitForm}>
            <div className="d-flex flex-column gap-4">
                <TextFormRow
                    label="SIM Number"
                    type="text"
                    maxLength={22}
                    value={iccid}
                    error={iccidError}
                    setValue={(iccid) => {
                        if ((iccid === undefined || iccid.length === 0) || /^\d+$/.test(iccid)) {
                            setIccid(iccid)
                        }
                    }}
                />

                <button className="sp-button" type="submit">
                    Confirm SIM number
                </button>
            </div>

            <LoadingBackdrop isLoading={loading}/>
        </form>
    )
}

export default SimInHandCaptureForm