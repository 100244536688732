import axios from "axios"
import {isRequestError, RequestError} from "../../Model/RequestError";
import {BraintreeClientToken} from "../../Model/Sales/BraintreeClientToken";
import {acceptHeader, executeWithErrorResponseHandling} from "../ApiUtilities";
import {joinUrl} from "../../Utilities/UriUtilities";

/**
 * Function is responsible for creating a Braintree client token.
 */
export const createBraintreeClientToken = async (domain: string): Promise<BraintreeClientToken | RequestError> => {
    const endpoint = joinUrl(domain, "/payments/braintree/generate-client-token")
    const headers = {...acceptHeader()}

    const response = await executeWithErrorResponseHandling<BraintreeClientToken>(() =>
        axios.post(endpoint, {}, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}