/**
 * Utility function will find an input field with the given ID in the DOM and push the given value to it.
 */
export const setInputValue = (id: string, value: string) => {
    const maybeElement = (document.getElementById(id) as HTMLInputElement)

    if (maybeElement) {
        maybeElement.value = value
    }
}
