import {isNumber, isOptNumber, isString} from "../../Utilities/TypeGuardUtilities";

export interface PlanBolton {

    /**
     * Unit defines what type of bolton this is. This could be Texts, Minutes, Cash, GB, MB etc.
     */
    readonly unit: string

    /**
     * Defines the value of this bolton. When null, this bolton represents an
     * unlimited amount of units.
     */
    readonly value: number | null

    /**
     * The price of this bolton.
     */
    readonly price: number

    /**
     * The unique bolton code to be included in RMW requests.
     */
    readonly code: string

}

/**
 * Type-guard function will check if the given instance of any is of type PlanBolton.
 */
export const isPlanBolton = (a: any): a is PlanBolton =>
    isString(a, "unit") &&
    isOptNumber(a, "value") &&
    isNumber(a, "price") &&
    isString(a, "code")