import * as React from "react"
import dayjs, {Dayjs} from "dayjs";
import {ApplicationContext} from "../../../ApplicationContext";
import LoadingBackdrop from "../../../Components/LoadingBackdrop/LoadingBackdrop";
import CustomerInformationCapture from "./CustomerInformationCapture";
import MarketingPreferencesCapture from "./MarketingPreferencesCapture";
import AddressInformationCapture from "./AddressInformationCapture";
import PortInformationCapture from "./PortInformationCapture";
import {createInterestedCustomer} from "../../../Api/Sales/InterestedCustomerApi";
import {SimOnlyPlan, simOnlyPlanMonthlyCost} from "../../../Model/Sales/SimOnlyPlan";
import PaymentDetailsCapture from "./PaymentDetailsCapture";
import {createBraintreeClientToken} from "../../../Api/Sales/BraintreeApi";
import {isRequestError} from "../../../Model/RequestError";
import dropin, {cardPaymentMethodPayload, Dropin} from "braintree-web-drop-in";
import {fetchPromo} from "../../../Api/Sales/PromotionsApi";
import {createOrder} from "../../../Api/Sales/OrderApi";
import {CreateAddress} from "../../../Model/Sales/CreateOrder";
import {useLocation, useNavigate} from "react-router-dom";
import SimOnlyOrderConfirmation from "./SimOnlyOrderConfirmation";
import {CmsContent} from "../../../Model/CmsContent/CmsContent";
import {SALES_PORTAL_ROUTES} from "../../../Routes/SalesPortalRoutes";

/**
 * Form page index for the customer details page.
 */
const FORM_PAGE_CUSTOMER_DETAILS: number = 0

/**
 * Form page index for the payment details page.
 */
const FORM_PAGE_PAYMENT_DETAILS: number = 1

interface SimOnlyPlanPurchaseFormProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * The SIM-only plan that has been selected by the customer.
     */
    readonly selectedPlan: SimOnlyPlan

    /**
     * Optional ICCID from the SIM-in-hand journey to be passed onto the purchase plan page.
     */
    readonly iccid?: string

    /**
     * Price of the plan minus any discounts that may have been applied.
     */
    readonly planPriceOverride: number

    /**
     * Callback function can be used to change the price displayed on the accompanying side card.
     */
    readonly setPlanPriceOverride: (price: number) => void

}

/**
 * Function will render the SIM-only signup form. This form will allow new customers to sign up to a SIM-only plan.
 */
const SimOnlyPlanPurchaseForm = (props: SimOnlyPlanPurchaseFormProps): JSX.Element => {

    const navigate = useNavigate()
    const applicationContext = React.useContext(ApplicationContext)

    const signupConfiguration = applicationContext.appConfig.signupConfiguration
    const marketingPrefsConfig = signupConfiguration.marketingPrefsConfig

    const [braintreeInstance, setBraintreeInstance] = React.useState<Dropin>()
    const [formPage, setFormPage] = React.useState(FORM_PAGE_CUSTOMER_DETAILS)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [hasCreatedInterestedCustomer, setHasCreatedInterestedCustomer] = React.useState(false)
    const [hasSubmittedOrder, setHasSubmittedOrder] = React.useState<boolean>(false)

    // Customer information
    const [forename, setForename] = React.useState<string>()
    const [forenameError, setForenameError] = React.useState<string>()
    const [surname, setSurname] = React.useState<string>()
    const [surnameError, setSurnameError] = React.useState<string>()
    const [dateOfBirth, setDateOfBirth] = React.useState<Dayjs>()
    const [dateOfBirthError, setDateOfBirthError] = React.useState<string>()
    const [email, setEmail] = React.useState<string>()
    const [emailError, setEmailError] = React.useState<string>()

    // Marketing preferences
    const [emailMarketing, setEmailMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [smsMarketing, setSmsMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [postMarketing, setPostMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [voiceMarketing, setVoiceMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [automatedVoiceMarketing, setAutomatedVoiceMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [onlineMarketing, setOnlineMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [inAppMarketing, setInAppMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [profiledMarketing, setProfiledMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)
    const [thirdPartyMarketing, setThirdPartyMarketing] = React.useState<boolean>(marketingPrefsConfig.defaultMarketingState)

    // Switching information
    const [portInMsisdn, setPortInMsisdn] = React.useState<string>()
    const [portInMsisdnError, setPortInMsisdnError] = React.useState<string>()
    const [switchingCode, setSwitchingCode] = React.useState<string>()
    const [switchingCodeError, setSwitchingCodeError] = React.useState<string>()
    const [switchingDate, setSwitchingDate] = React.useState<Dayjs>()
    const [switchingDateError, setSwitchingDateError] = React.useState<string>()

    // Address information
    const [addressLine1, setAddressLine1] = React.useState<string>()
    const [addressLine1Error, setAddressLine1Error] = React.useState<string>()
    const [addressLine2, setAddressLine2] = React.useState<string>()
    const [addressLine2Error, setAddressLine2Error] = React.useState<string>()
    const [addressLine3, setAddressLine3] = React.useState<string>()
    const [addressLine3Error, setAddressLine3Error] = React.useState<string>()
    const [city, setCity] = React.useState<string>()
    const [cityError, setCityError] = React.useState<string>()
    const [county, setCounty] = React.useState<string>()
    const [countyError, setCountyError] = React.useState<string>()
    const [postcode, setPostcode] = React.useState<string>()
    const [postcodeError, setPostcodeError] = React.useState<string>()

    // Payments information
    const [promoCode, setPromoCode] = React.useState<string>()
    const [promoCodeError, setPromoCodeError] = React.useState<string>()
    const [bankName, setBankName] = React.useState<string>()
    const [bankNameError, setBankNameError] = React.useState<string>()
    const [accountHolderName, setAccountHolderName] = React.useState<string>()
    const [accountHolderNameError, setAccountHolderNameError] = React.useState<string>()
    const [sortCode, setSortCode] = React.useState<string>()
    const [sortCodeError, setSortCodeError] = React.useState<string>()
    const [accountNumber, setAccountNumber] = React.useState<string>()
    const [accountNumberError, setAccountNumberError] = React.useState<string>()

    /**
     * Function will change the active form page index and scroll to the top of the browser window.
     */
    const changeFormPage = (index: number) => {
        setFormPage(index)
        window.scrollTo(0, 0)
    }

    /**
     * Function will create an interested customer if the email marketing flag has been checked.
     */
    const storeInterestedCustomer = () => {
        if (emailMarketing && !hasCreatedInterestedCustomer) {
            createInterestedCustomer(applicationContext.salesPortalApiDomain, {
                firstName: forename!!,
                lastName: surname!!,
                email: email!!,
                dateOfBirth: dateOfBirth!!.format("YYYY-MM-DD"),
                chosenPackage: props.selectedPlan.packageCode,
                packageBoltonCodes: props.selectedPlan.planBolton ? [props.selectedPlan.planBolton.code] : [],
                marketingPreferences: {
                    emailMarketing: emailMarketing,
                    smsMarketing: smsMarketing,
                    postMarketing: postMarketing,
                    phoneMarketing: voiceMarketing,
                    automatedVoiceMarketing: automatedVoiceMarketing,
                    onlineMarketing: onlineMarketing,
                    inAppMarketing: inAppMarketing,
                    profiledMarketing: profiledMarketing,
                    thirdPartyMarketing: thirdPartyMarketing
                }
            }).then(() => setHasCreatedInterestedCustomer(true))
        } // else email marketing disabled, or we've already created one for this prospect customer.
    }

    /**
     * Function will verify the validity of the provided switching details.
     */
    const verifyPortDetails = (): Promise<boolean> => {
        // TODO Call the new portDetails API
        return Promise.resolve(true)
    }

    /**
     * Function will validate the provided promotion exists and reduce the displayed price of the plan if necessary.
     */
    const applyPromotion = () => {
        if (signupConfiguration.paymentsConfig.usesPromotions) {
            if (promoCode === undefined || promoCode.length === 0) {
                setPromoCodeError("A promotion code was not provided")
            } else {
                setLoading(true)
                fetchPromo(
                    applicationContext.reseller,
                    applicationContext.salesPortalApiDomain,
                    promoCode
                ).then((maybePromo) => {
                    if (isRequestError(maybePromo)) {
                        setPromoCodeError("The given promotion was not valid")
                    } else {
                        setPromoCodeError(undefined)
                        if (maybePromo.packagePriceOverride !== null) {
                            props.setPlanPriceOverride(maybePromo.packagePriceOverride)
                        } else if (maybePromo.percentagePackageDiscount !== null) {
                            const totalPlanCost = simOnlyPlanMonthlyCost(props.selectedPlan)
                            // Promotions may be returned as a whole number, so we need to convert it into a decimal first.
                            const discountAsDecimal = Number.isInteger(maybePromo.percentagePackageDiscount) ?
                                maybePromo.percentagePackageDiscount / 100 : maybePromo.percentagePackageDiscount
                            const newPrice = totalPlanCost - (totalPlanCost * discountAsDecimal)
                            props.setPlanPriceOverride(newPrice)
                        } else { // Must be a fixed discount
                            const totalPlanCost = simOnlyPlanMonthlyCost(props.selectedPlan)
                            const newPrice = totalPlanCost - (maybePromo.fixedPackageDiscount ?? 0)
                            props.setPlanPriceOverride(newPrice)
                        }
                    }
                    setLoading(false)
                })
            }
        }
    }

    /**
     * Function will validate the customer information and navigate the user to the payment details page when valid.
     */
    const navigateToPaymentDetailsFormPage = () => {
        const isForenameValid: boolean = forename !== undefined && forename.length > 0
        setForenameError(isForenameValid ? undefined : "A valid forename must be provided")

        const isSurnameValid: boolean = surname !== undefined && surname.length > 0
        setSurnameError(isSurnameValid ? undefined : "A valid surname must be provided")

        const isDateOfBirthValid: boolean = dateOfBirth !== undefined
        setDateOfBirthError(isDateOfBirthValid ? undefined : "A valid date of birth must be provided")

        // Skip minimum age validation when date of birth field has errors.
        const isDateOfBirthInvalidOrIsOlderThanMinimumAge: boolean = dateOfBirth === undefined ||
            signupConfiguration.minimumSignupAge === null ||
            dateOfBirth.isBefore(dayjs().subtract(signupConfiguration.minimumSignupAge, "year"))
        setDateOfBirthError(isDateOfBirthInvalidOrIsOlderThanMinimumAge ? undefined : `You must be at least ${signupConfiguration.minimumSignupAge} years of age to sign up to this service`)

        const isEmailValid: boolean = email !== undefined && email.length > 0 && /^\S+@\S+\.\S+$/.test(email)
        setEmailError(isEmailValid ? undefined : "A valid email address must be provided")

        const isPortInMsisdnValid: boolean = !signupConfiguration.portTriggerConfig.canTriggerPortOnSignup ||
            portInMsisdn === undefined ||
            portInMsisdn.length === 0 ||
            /.*(s*(447[0-9]{9})s*)|(s*(07[0-9]{9})s*)/.test(portInMsisdn)
        setPortInMsisdnError(isPortInMsisdnValid ? undefined : "A valid UK mobile number must be provided")

        const isSwitchingCodeValid: boolean = !signupConfiguration.portTriggerConfig.canTriggerPortOnSignup ||
            switchingCode === undefined ||
            switchingCode.length === 0 ||
            /(^[A-Z]{3}[0-9]{6}$)|(^[0-9]{6}[A-Z]{3}$)/.test(switchingCode)
        setSwitchingCodeError(isSwitchingCodeValid ? undefined : "A valid switching code must be provided")

        const customerDetailsValid = isForenameValid && isSurnameValid && isDateOfBirthValid &&
            isDateOfBirthInvalidOrIsOlderThanMinimumAge && isEmailValid && isPortInMsisdnValid && isSwitchingCodeValid

        if (customerDetailsValid) {
            if (signupConfiguration.portTriggerConfig.canTriggerPortOnSignup) {
                verifyPortDetails().then((portDetailsValid) => {
                    if (portDetailsValid) {
                        storeInterestedCustomer()
                        changeFormPage(FORM_PAGE_PAYMENT_DETAILS)
                    } else {
                        // TODO Show errors.
                    }
                })
            } else {
                storeInterestedCustomer()
                changeFormPage(FORM_PAGE_PAYMENT_DETAILS)
            }
        } else {
            window.scrollTo(0, 0)
        }
    }

    /**
     * Function will validate and submit the form which will sign up a customer.
     */
    const submitForm = () => {
        const isAddressLine1Valid = addressLine1 !== undefined && addressLine1.length > 0
        setAddressLine1Error(isAddressLine1Valid ? undefined : "The first line of your address must be provided")

        const isCityValid = city !== undefined && city.length > 0
        setCityError(isCityValid ? undefined : "A city must be provided")

        const isCountyValid = county !== undefined && county.length > 0
        setCountyError(isCountyValid ? undefined : "A county must be provided")

        const isPostcodeValid = postcode !== undefined && postcode.length > 0 &&
            /^[A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}$/.test(postcode)
        setPostcodeError(isPostcodeValid ? undefined : "A valid postcode must be provided")

        const isAddressValid = isAddressLine1Valid && isCityValid && isCountyValid && isPostcodeValid

        let isPaymentDetailsValid
        if (signupConfiguration.paymentsConfig.paymentProvider === "lifecycle") {
            const isBankNameValid = bankName !== undefined && bankName.length > 0
            setBankNameError(isBankNameValid ? undefined : "A bank name must be provided")

            const isAccountHolderNameValid = accountHolderName !== undefined && accountHolderName.length > 0
            setAccountHolderNameError(isAccountHolderNameValid ? undefined : "An account holder name must be provided")

            const isSortCodeValid = sortCode !== undefined && sortCode.length > 0 &&
                /^(?!0{6}|00-00-00)(?:\d{6}|\d\d-\d\d-\d\d)$/.test(sortCode)
            setSortCodeError(isSortCodeValid ? undefined : "A valid sort code must be provided")

            const isAccountNumberValid = accountNumber !== undefined && accountNumber.length > 0 &&
                /^(\d){8}$/.test(accountNumber)
            setAccountNumberError(isAccountNumberValid ? undefined : "A valid account number must be provided")

            isPaymentDetailsValid = !(!isBankNameValid || !isAccountHolderNameValid || !isSortCodeValid || !isAccountNumberValid)
        } else { // Using a payment provider so skip direct debit validation
            isPaymentDetailsValid = true
        }

        if (isAddressValid && isPaymentDetailsValid) {
            if (signupConfiguration.paymentsConfig.paymentProvider === "braintree") {
                if (braintreeInstance) {
                    braintreeInstance.requestPaymentMethod((error, payload) => {
                        if (error) {
                            // TODO Do something with this error
                            setLoading(false)
                        } else {
                            triggerSignup(payload.nonce,
                                payload.deviceData || null,
                                (payload as cardPaymentMethodPayload)?.threeDSecureInfo?.threeDSecureAuthenticationId || ''
                            )
                        }
                    })
                } else {
                    // TODO Do something with this error
                    setLoading(false) // shouldn't get here
                }
            } else {
                triggerSignup(null, null, null)
            }
        } else {
            window.scrollTo(0, 0)
        }
    }

    /**
     * Function will sign up the customer to the selected plan.
     */
    const triggerSignup = (paymentNonce: string | null,
                           paymentDeviceData: string | null,
                           paymentThreeDSecureAuthenticationId: string | null) => {
        const splitPostcodeArray = postcode!!
            .replace(/\s/g, "")
            .match(/^([A-Za-z]{1,2}\d{1,2}[A-Za-z]?)\s*(\d[A-Za-z]{2})$/);
        splitPostcodeArray!!.shift()

        const billingAddress: CreateAddress = {
            houseNumber: null,
            houseName: null,
            line1: addressLine1!!,
            line2: addressLine2 ?? null,
            line3: addressLine3 ?? null,
            city: city!!,
            county: county!!,
            postcode1: splitPostcodeArray!![0],
            postcode2: splitPostcodeArray!![1]
        }

        createOrder(applicationContext.reseller, applicationContext.salesPortalApiDomain, {
            chosenPackageCode: props.selectedPlan.packageCode,
            packageBoltonCodes: props.selectedPlan.planBolton ? [props.selectedPlan.planBolton.code] : [],
            customer: {
                title: "Mr", // Mandatory field in MultiOrder
                firstName: forename!!,
                lastName: surname!!,
                dateOfBirth: dateOfBirth!!.format("YYYY-MM-DD"),
                email: email!!,
                externalCustomerRef: null
            },
            homeAddress: billingAddress,
            directDebitDetails: signupConfiguration.paymentsConfig.paymentProvider === "lifecycle" ? {
                nameOfAccountHolder: accountHolderName!!,
                nameOfBank: bankName!!,
                accountNumber: accountNumber!!,
                sortCode: sortCode!!,
                billingAddress: billingAddress
            } : null,
            braintreePaymentNonce: paymentNonce,
            braintreePaymentThreeDSecureAuthenticationId: paymentThreeDSecureAuthenticationId,
            braintreePaymentDeviceData: paymentDeviceData,
            marketingPreferences: {
                emailMarketing: emailMarketing,
                smsMarketing: smsMarketing,
                postMarketing: postMarketing,
                phoneMarketing: voiceMarketing,
                automatedVoiceMarketing: automatedVoiceMarketing,
                onlineMarketing: onlineMarketing,
                inAppMarketing: inAppMarketing,
                profiledMarketing: profiledMarketing,
                thirdPartyMarketing: thirdPartyMarketing
            },
            iccid: props.iccid ?? null,
            promoCode: promoCode ?? null,
            loyaltyCard: null,
            portInNumber: null,
            switchingCode: null
        }).then((maybeOrderSummary) => {
            if (isRequestError(maybeOrderSummary)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            } else {
                setHasSubmittedOrder(true)
            }
            setLoading(false)
        })

        setHasSubmittedOrder(true)
    }

    React.useEffect(() => {
        const paymentDetailsConfig = applicationContext.appConfig.signupConfiguration.paymentsConfig

        if (paymentDetailsConfig.paymentProvider === "braintree") {
            createBraintreeClientToken(applicationContext.salesPortalApiDomain).then((maybeClientToken) => {
                if (isRequestError(maybeClientToken)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                } else {
                    /**
                     * Function will setup and configure the Braintree dropin plugin for this session.
                     */
                    const createBraintreeDropin = () => {
                        const braintreeConfig = {
                            authorization: maybeClientToken.clientToken,
                            container: "#braintree-drop-in-div",
                            threeDSecure: true
                        }

                        dropin.create(braintreeConfig, (error, instance) => {
                            if (error) {
                                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                            } else {
                                setBraintreeInstance(instance)
                            }
                        })
                    }

                    if (braintreeInstance) {
                        braintreeInstance.teardown().then(() => createBraintreeDropin())
                    } else {
                        createBraintreeDropin()
                    }
                }
            })
        }
    }, [])

    if (hasSubmittedOrder) {
        return (
            <SimOnlyOrderConfirmation
                cmsContent={props.cmsContent}
                monthlyPrice={props.planPriceOverride}
                simOnlyPlan={props.selectedPlan}
                line1={addressLine1!!}
                line2={addressLine2 ?? null}
                line3={addressLine3 ?? null}
                city={city!!}
                postcode={postcode!!}
            />
        )
    } else {
        return (
            <form onSubmit={submitForm}>
                <div style={{
                    display: formPage === FORM_PAGE_CUSTOMER_DETAILS ? "flex" : "none",
                    flexDirection: "column",
                    marginBottom: "5%",
                }}>
                    <CustomerInformationCapture
                        forename={{value: forename, setValue: setForename}}
                        forenameError={forenameError}
                        surname={{value: surname, setValue: setSurname}}
                        surnameError={surnameError}
                        dateOfBirth={{value: dateOfBirth, setValue: setDateOfBirth}}
                        dateOfBirthError={dateOfBirthError}
                        email={{value: email, setValue: setEmail}}
                        emailError={emailError}
                    />

                    {signupConfiguration.portTriggerConfig.canTriggerPortOnSignup && <div>
                        <hr className="mt-4"/>

                        <PortInformationCapture
                            portInMsisdn={{value: portInMsisdn, setValue: setPortInMsisdn}}
                            portInMsisdnError={portInMsisdnError}
                            switchingCode={{value: switchingCode, setValue: setSwitchingCode}}
                            switchingCodeError={switchingCodeError}
                            switchingDate={{value: switchingDate, setValue: setSwitchingDate}}
                            switchingDateError={switchingDateError}
                        />
                    </div>}

                    {signupConfiguration.marketingPrefsConfig.capturesMarketingPreferences && <div>
                        <hr className="mt-4"/>

                        <MarketingPreferencesCapture
                            emailMarketing={{value: emailMarketing, setValue: setEmailMarketing}}
                            smsMarketing={{value: smsMarketing, setValue: setSmsMarketing}}
                            postMarketing={{value: postMarketing, setValue: setPostMarketing}}
                            voiceMarketing={{value: voiceMarketing, setValue: setVoiceMarketing}}
                            automatedVoiceMarketing={{
                                value: automatedVoiceMarketing,
                                setValue: setAutomatedVoiceMarketing
                            }}
                            onlineMarketing={{value: onlineMarketing, setValue: setOnlineMarketing}}
                            inAppMarketing={{value: inAppMarketing, setValue: setInAppMarketing}}
                            profiledMarketing={{value: profiledMarketing, setValue: setProfiledMarketing}}
                            thirdPartyMarketing={{value: thirdPartyMarketing, setValue: setThirdPartyMarketing}}
                        />
                    </div>}

                    <hr/>

                    <button className="sp-button" type="button" onClick={navigateToPaymentDetailsFormPage}>
                        Next
                    </button>
                </div>

                <div style={{
                    display: formPage === FORM_PAGE_PAYMENT_DETAILS ? "flex" : "none",
                    flexDirection: "column",
                    marginBottom: "5%",
                }}>
                    <AddressInformationCapture
                        addressLine1={{value: addressLine1, setValue: setAddressLine1}}
                        addressLine1Error={addressLine1Error}
                        addressLine2={{value: addressLine2, setValue: setAddressLine2}}
                        addressLine2Error={addressLine2Error}
                        addressLine3={{value: addressLine3, setValue: setAddressLine3}}
                        addressLine3Error={addressLine3Error}
                        city={{value: city, setValue: setCity}}
                        cityError={cityError}
                        county={{value: county, setValue: setCounty}}
                        countyError={countyError}
                        postcode={{value: postcode, setValue: setPostcode}}
                        postcodeError={postcodeError}
                    />

                    <hr/>

                    <PaymentDetailsCapture
                        promoCode={{value: promoCode, setValue: setPromoCode}}
                        promoCodeError={promoCodeError}
                        bankName={{value: bankName, setValue: setBankName}}
                        bankNameError={bankNameError}
                        accountHolderName={{value: accountHolderName, setValue: setAccountHolderName}}
                        accountHolderNameError={accountHolderNameError}
                        sortCode={{value: sortCode, setValue: setSortCode}}
                        sortCodeError={sortCodeError}
                        accountNumber={{value: accountNumber, setValue: setAccountNumber}}
                        accountNumberError={accountNumberError}
                        applyPromotion={applyPromotion}
                    />

                    <hr/>

                    <div className="d-flex flex-grid-row gap-4">
                        <button className="flex-fill sp-button" type="button"
                                onClick={() => changeFormPage(formPage - 1)}>
                            Previous
                        </button>

                        <button className="flex-fill sp-button" type="button" onClick={submitForm}>
                            Signup
                        </button>
                    </div>
                </div>

                <LoadingBackdrop isLoading={loading}/>
            </form>
        )
    }

}

export default SimOnlyPlanPurchaseForm;