import * as React from "react"
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import {ApplicationContext} from "../../ApplicationContext";
import {useNavigate} from "react-router-dom";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {fetchSimOnlyPlanByCode} from "../../Api/Sales/SimOnlyPlanApi";
import {isRequestError} from "../../Model/RequestError";
import SuperdrugOrderSummaryCard from "../../Components/Superdrug/Card/SuperdrugOrderSummaryCard";
import {readLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {isOrderConfirmation, OrderConfirmation} from "../../Model/Sales/OrderConfirmation";
import {UserDetails} from "../../Model/User/UserDetails";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";

interface SuperdrugSimOnlyOrderConfirmationPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific order confirmation page. This page will show the customer information
 * on a recently placed order.
 */
const SuperdrugSimOnlyOrderConfirmationPage = (
    props: SuperdrugSimOnlyOrderConfirmationPageProps
): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    const [componentData, setComponentData] = React.useState<{
        readonly orderConfirmation: OrderConfirmation
        readonly selectedPlan: SimOnlyPlan
        readonly selectedPlanPriceWithPromo: PlanPrice | null
        readonly userDetails: UserDetails
    }>()

    /**
     * Function will render a card with some text explaining that we are getting their SIM card ready.
     */
    const GettingYourSimReadyCard = (): JSX.Element | null => {
        const optGettingYouRSimReadyCard = optHtmlContent(props.cmsContent, "sp-card-getting-your-sim-ready")

        if (optGettingYouRSimReadyCard && componentData) {
            return renderDynamicContent(optGettingYouRSimReadyCard, [
                {key: "EMAIL_ADDRESS", value: componentData.orderConfirmation.email}
            ])
        } else {
            return null
        }
    }

    /**
     * Function will render a card with information on where the SIM is being shipped to.
     */
    const SimDeliveryCard = (): JSX.Element | null => {
        const optSimDeliveryCard = optHtmlContent(props.cmsContent, "sp-card-delivery-info")

        if (optSimDeliveryCard && componentData) {
            return renderDynamicContent(optSimDeliveryCard, [
                {
                    key: "HOUSE_NUMBER",
                    value: componentData.orderConfirmation.houseNumber ?? componentData.orderConfirmation.building
                },
                {key: "ADDRESS_LINE_1", value: componentData.orderConfirmation.line1},
                {key: "ADDRESS_LINE_2", value: componentData.orderConfirmation.line2},
                {key: "CITY", value: componentData.orderConfirmation.city},
                {key: "POSTCODE", value: componentData.orderConfirmation.postcode}
            ])
        } else {
            return null
        }
    }

    /**
     * Function will render a card that will show some basic help information that the customer may find useful.
     */
    const MoreHelpCard = (): JSX.Element | null => {
        const optMoreHelpCard = optHtmlContent(props.cmsContent, "sp-card-need-more-help")

        if (optMoreHelpCard) {
            return renderDynamicContent(optMoreHelpCard, [
                {key: "DASHBOARD_URL", value: applicationContext.selfcareWebappPublicUrl}
            ])
        } else {
            return null
        }
    }

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const orderConfirmation = readLocalStorageItem("order-confirmation", isOrderConfirmation)
        if (!orderConfirmation) {
            // Shouldn't happen, but if it does, send user to self-care instead of showing nothing.
            window.location.replace(applicationContext.selfcareWebappPublicUrl!!)
            return
        }

        const maybePlan = await fetchSimOnlyPlanByCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, orderConfirmation.packageCode)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, orderConfirmation.packageCode, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice
        } else {
            planPriceWithPromo = null
        }

        setComponentData({
            selectedPlan: maybePlan,
            selectedPlanPriceWithPromo: planPriceWithPromo,
            userDetails: maybeUser,
            orderConfirmation: orderConfirmation
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [])

    const optOrderConfirmationPage = optHtmlContent(props.cmsContent, "sp-page-websale-order-confirmation")

    const setSelectedPlanPriceWithPromo = (planPrice: PlanPrice | null) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlanPriceWithPromo: planPrice
        }))
    }

    const setSelectedPlan = (simOnlyPlan: SimOnlyPlan) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlan: simOnlyPlan
        }))
    }
    if (!componentData || !optOrderConfirmationPage) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="secondary">
                {renderDynamicContent(optOrderConfirmationPage, [
                    {key: "GETTING_YOUR_SIM_READY", value: <GettingYourSimReadyCard/>},
                    {
                        key: "ORDER_SUMMARY",
                        value: <SuperdrugOrderSummaryCard
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            selectedPlanPriceWithPromo={componentData.selectedPlanPriceWithPromo}
                            paidInStorePlan={undefined}
                            paidInStorePriceWithPromo={null}
                            allowEdit={false}
                            simInHand={false}
                            setSelectedPlanPriceWithPromo={setSelectedPlanPriceWithPromo}
                            setSelectedPlan={setSelectedPlan}
                            componentData={componentData}
                        />
                    },
                    {key: "SIM_DELIVERY", value: <SimDeliveryCard/>},
                    {key: "MORE_HELP", value: <MoreHelpCard/>},
                    {key: "DASHBOARD_URL", value: applicationContext.selfcareWebappPublicUrl}
                ])}
            </HeaderFooterWrapper>
        )
    }

}

export default SuperdrugSimOnlyOrderConfirmationPage