import * as React from "react";
import {Dayjs} from "dayjs";
import {InlinedState} from "../../../Types/InlinedState";
import TextFormRow from "../../../Components/Form/TextFormRow";
import DateFormRow from "../../../Components/Form/DateFormRow";

interface CustomerInformationCaptureProps {

    /**
     * Forename of the new customer.
     */
    readonly forename: InlinedState<string | undefined>

    /**
     * Validation error for the forename field.
     */
    readonly forenameError: string | undefined

    /**
     * Surname of the new customer.
     */
    readonly surname: InlinedState<string | undefined>

    /**
     * Validation error for the surname field.
     */
    readonly surnameError: string | undefined

    /**
     * Date of birth for the new customer.
     */
    readonly dateOfBirth: InlinedState<Dayjs | undefined>

    /**
     * Validation error for the date of birth field.
     */
    readonly dateOfBirthError: string | undefined

    /**
     * Email address for the new customer.
     */
    readonly email: InlinedState<string | undefined>

    /**
     * Validation error for the email field.
     */
    readonly emailError: string | undefined

}

/**
 * Function will render the customer information section of the SIM-only plan signup form.
 */
const CustomerInformationCapture = (props: CustomerInformationCaptureProps): JSX.Element =>
    <div className="d-flex flex-column gap-2">
        <span className="sp-subtitle sp-text-primary text-start m-0">
            Customer Information
        </span>

        <TextFormRow
            label="Forename"
            type="text"
            value={props.forename.value}
            error={props.forenameError}
            setValue={props.forename.setValue}
        />
        <TextFormRow
            label="Surname"
            type="text"
            value={props.surname.value}
            error={props.surnameError}
            setValue={props.surname.setValue}
        />
        <DateFormRow
            label="Date of Birth"
            date={props.dateOfBirth.value}
            error={props.dateOfBirthError}
            setDate={props.dateOfBirth.setValue}
        />
        <TextFormRow
            label="Email"
            type="email"
            value={props.email.value}
            error={props.emailError}
            setValue={props.email.setValue}
        />
    </div>

export default CustomerInformationCapture