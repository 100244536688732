import {CmsContent} from "../../Model/CmsContent/CmsContent";
import * as React from "react";
import {ApplicationContext} from "../../ApplicationContext";
import {Navigate} from "react-router-dom";
import {SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import SuperdrugAgreementPlanPreviewPage from "./SuperdrugAgreementPlanPreviewPage";

export interface AgreementPlanPreviewPageProps {

  /**
   * The contract type the plan agreement is addressing
   */
  readonly contractType: string

}

/**
 * Function will render the sim-in-hand purchase page which will sign up a user with a physical SIM.
 */
const AgreementPlanPreviewPage = (props: AgreementPlanPreviewPageProps): JSX.Element => {

  const applicationContext = React.useContext(ApplicationContext)

  if (applicationContext.reseller === "superdrug") {
    return <SuperdrugAgreementPlanPreviewPage contractType={props.contractType}/>
  } else {
    return <Navigate to={SALES_PORTAL_ROUTES.NotFound(applicationContext.urlContext)}/>
  }
}

export default AgreementPlanPreviewPage