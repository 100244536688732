/**
 * Utility function will join two url parts together to make an absolute url.
 *
 * @param domain First part of the url such as https://test.com/
 * @param path Second part of the url such as /foo/bar/baz
 */
export const joinUrl = (domain: string, path: string): string => {
    const formattedDomain = domain.endsWith("/") ? domain.substring(0, domain.length - 1) : domain
    const formattedPath = path.startsWith("/") ? path : `/${path}`

    return formattedDomain + formattedPath
}
